import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const CustomPrevArrow = ({ onClick }) => (
  <button className="custom-prev-arrow" onClick={onClick} style={{opacity: 0}}>
    <IoIosArrowBack />
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button className="custom-next-arrow" onClick={onClick} style={{opacity: 0}}>
    <IoIosArrowForward />
  </button>
);

const BannerSlider = ({ images }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <Slider {...settings} className="home-slider home-banner-slider">
      {images.map((image, index) => (
        <div key={index}>
          {screenWidth > 1024
            ? <img style={{ width: '100vw', height: 'auto' }} src={image} alt={`Banner ${index + 1}`} />
            : <img style={{ width: '100vw', height: 'auto', marginTop: '4vw' }} src={image} alt={`Banner ${index + 1}`} />}
        </div>
      ))}
    </Slider>
  );
};

export default BannerSlider;

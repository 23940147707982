
import { SuperHOC } from "../../HOC";
import React, { Component } from 'react';
import _styles from '../../_styles.css';
import { CiSearch } from "react-icons/ci";
import { GoTriangleRight } from "react-icons/go";
import { MdKeyboardArrowRight, MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

import BannerSlider from '../../component/common/bannerSlider';

class ChangePlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planDetailsList: {
                "685": {
                    productName: "WiFi PLUS LITE",
                    sideProductName: "PLUS LITE",
                    SIM: "SIM ONLY",
                    image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim only.svg`,
                    price: "",
                    additionalPriceInfo: "",
                    infoList: [
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_internet.svg`, infoText: "250GB Internet" },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_no contract.svg`, infoText: "No Contract", bold: true },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_data add-ons.svg`, infoText: "Eligible for data add-ons" },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_plugnplay.svg`, infoText: "Plug & Play" },
                    ]
                },
                "687": {
                    productName: "WiFi PLUS FREEDOM",
                    sideProductName: "PLUS FREEDOM",
                    SIM: "SIM + 4G MiFi",
                    image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim 4G mifi.svg`,
                    price: "",
                    additionalPriceInfo: "+ RM110 4G MiFi device (RRP: RM189)",
                    infoList: [
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_internet.svg`, infoText: "250GB Internet" },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_no contract.svg`, infoText: "No Contract", bold: true },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_data add-ons.svg`, infoText: "Eligible for data add-ons" },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_plugnplay.svg`, infoText: "Plug & Play" },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_portable.svg`, infoText: "Portable & pocket-friendly" },
                    ]
                },
                "686": {
                    productName: "WiFi PREMIUM LITE",
                    sideProductName: "PREMIUM LITE",
                    SIM: "SIM ONLY",
                    image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim only.svg`,
                    price: "",
                    additionalPriceInfo: "",
                    infoList: [
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_internet.svg`, infoText: "Unlimited* Internet" },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_no contract.svg`, infoText: "No Contract", bold: true },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_data add-ons.svg`, infoText: "Eligible for data add-ons" },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_plugnplay.svg`, infoText: "Plug & Play" },
                    ]
                },
                "688": {
                    productName: "WiFi PREMIUM FREEDOM",
                    sideProductName: "PREMIUM FREEDOM",
                    SIM: "SIM + 4G ROUTER",
                    image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim 4G router.svg`,
                    price: "",
                    additionalPriceInfo: "+ RM130 4G router (RRP: RM220)",
                    infoList: [
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_internet.svg`, infoText: "250GB Internet" },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_no contract.svg`, infoText: "No Contract", bold: true },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_data add-ons.svg`, infoText: "Eligible for data add-ons" },
                        { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_plugnplay.svg`, infoText: "Plug & Play" },
                    ]
                },

            },
            planList: [],
            selectedPlanType: "PLUS",
            screenWidth: window.innerWidth,
            FAQList: [
                {
                    "question": "What is XOX WiFi about?",
                    "answer": "XOX WiFi is the plan for our subscriber to enjoy high usage data with high speed internet with affordable price.	\nThe details of the plan are as stated below"
                },
                {
                    "question": "Who is eligible to subscribe to XOX WiFi Plan?",
                    "answer": "To register for the XOX WiFi Plan, the subscriber must be 16 years of age or older. Malaysian require NRIC and for foreigner require passport for verification purpose."
                },
                {
                    "question": "How much is the XOX WiFi Plan?",
                    "answer": "The price of XOX WiFi Plan is RM 55 for Plus, and RM 85 for Premium. "
                },
                {
                    "question": "How can I purchase the XOX WiFi Plan?",
                    "answer": "a)	XOX and ONEXOX Official Dealers\nb)	XOX Online Store"
                },
                {
                    "question": "What is the FUP for XOX WiFi Plan? ",
                    "answer": "a) Total FUP for XOX WiFi Plus is 250GB.\nfirst 100GB: high speed internet,\n150GB: 3Mpbs.\nb) Total FUP for XOX WiFi Premium is 500GB\nfirst 100GB: high speed internet, \n400GB: 3Mbps."
                },
                {
                    "question": "If I need more data, can I purchase add on?",
                    "answer": "YES! You may purchase the following data add-ons for the XOX WiFi Plan via our online portal."
                },
                {
                    "question": "Can I share data, talk time and SMS to other XOX subscribers?",
                    "answer": "Unfortunately, no. Due to this plan has no talk time and SMS, and the data are not sharable to other subscribers."
                },
                {
                    "question": "Can I buy Season Pass Packages or Happy Hour Promotion under XOX WiFi Plan?",
                    "answer": "Unfortunately, no. This plan is not joint with other promotion that on-going. "
                },
                {
                    "question": "Is there any limit for the hotspot usage? ",
                    "answer": "No, there’s no limit for the hotspot usage for XOX WiFi Plan. And this plan is meant for hotspot usage. So, just feel free to use the service that we provide."
                },
                {
                    "question": "How many devices can be connected to the hotspot feature? ",
                    "answer": "You can hotspot to as many devices as you want, we have no limitations on the plan but also depends on the hardware that supported up to how many devices that supported. "
                },
                {
                    "question": "Can I do port-in (MNP) from my favourite number to XOX WiFi Plan?",
                    "answer": "Unfortunately, no. Because the purpose of the numbers that we gave for new number is just for profile verification and billing purposes.  This plan does not come with Call and SMS features. "
                },
                {
                    "question": "May I change to ONEXOX Prepaid, WAWA Prepaid, or any BLACK plans? ",
                    "answer": "Unfortunately, the change of plan is not available at the moment now. "
                },
                {
                    "question": "May I change from ONEXOX Prepaid, WAWA Prepaid, or any BLACK plans?",
                    "answer": "Unfortunately, the change of plan is not available at the moment now"
                },
                {
                    "question": "What if the MiFi or router is not working? ",
                    "answer": "You may proceed to our nearest XOX Retail Store for after sales service."
                },
                {
                    "question": "What happens if I didn’t activate the SIM card that registered from online? ",
                    "answer": "Your SIM card will expire automatically, and your Service account will be terminated accordingly if you didn’t activate the SIM card within thirty (30) days."
                },
                {
                    "question": "Does the mentioned plan can use this service whilst roaming overseas? ",
                    "answer": "Unfortunately, no. This plan is only available in Malaysia, International roaming may occur Charges or No Service. "
                },
                {
                    "question": "Where can I check the internet balance?",
                    "answer": "You may check the internet balance at online portal. "
                },
            ],
            selectedFAQ: -1
        };
    }

    handleResize = () => {
        this.setState({
            screenWidth: window.innerWidth,
        });
    };

    componentDidMount() {
        this.props.apiManager.makeCall('mobileWifi/getProductPlanList', { platform: 'OL' }, null, (res) => {
            if (res.success) {
                if (Array.isArray(res.data.non_devices) && Array.isArray(res.data.devices)) {
                    res.data.devices = res.data.devices.filter(item => item.stock > 0);
                    let combineList = res.data.non_devices.concat(res.data.devices);
                    this.setState({ planList: combineList });
                }
            }
        });

        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    renderPlanCard = () => {
        const { screenWidth } = this.state;
        return (
            this.state.planList.map((item, index) => {
                let itemDetails = this.state.planDetailsList[item.idproduct];

                if (itemDetails.productName.includes(this.state.selectedPlanType))
                    return (
                        <div style={{ position: 'relative', margin: '50px', backgroundColor: 'white', height: screenWidth > 600 ? '650px' : '550px', width: '350px', maxWidth: '90%', borderRadius: '15px' }}>
                            <div className='verticalText collosalText38 boldText' style={{ fontSize: '38px', opacity: 0.08, color: this.state.selectedPlanType == 'PLUS' ? '#2DB2B2' : '#7979F5' }}>{itemDetails.sideProductName}</div>
                            <div className='row' style={{ padding: '10px', justifyContent: 'center', backgroundColor: this.state.selectedPlanType == 'PLUS' ? '#2DB2B2' : '#7979F5', borderRadius: '10px 10px 0px 0px' }}>
                                <img style={{ width: '22px', height: '22px', backgroundColor: 'white', borderRadius: '20px', padding: '3px', marginRight: '10px' }} src={this.state.selectedPlanType == 'PLUS' ? `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/wifi plus_star.svg` : `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/wifi premium_purple diamond.svg`}></img>
                                <div className='whiteText largeText boldText'>{itemDetails.productName}</div>
                            </div>
                            <div className='collosalText boldText' style={{ marginTop: '40px' }}>{itemDetails.SIM}</div>
                            <img style={{ width: '50px', height: '50px', margin: '20px 0' }} src={itemDetails.image}></img>
                            <div className='collosalText32 boldText blueText'><span className='collosalText boldText'>RM</span>{(typeof item.amount == 'string' && item.amount.slice(-3) == ".00") ? item.amount.slice(0, -3) : item.amount}<span className='mediumText boldText'> / mth</span></div>
                            <div className='smallText' style={{ margin: '10px 10px 30px 10px' }}>{itemDetails.additionalPriceInfo ? itemDetails.additionalPriceInfo : <br />}</div>
                            <button onClick={() => { window.location.href = '/xoxwifi/plan/' + btoa(item.idproduct); }} className='whiteText clickable largeText semiBoldText mobileHeight40' style={{ borderRadius: '30px', border: '0px', backgroundColor: this.state.selectedPlanType == 'PLUS' ? '#2DB2B2' : '#7979F5', maxWidth: '60%', width: '220px', height: '50px', marginBottom: '30px' }}>Subscribe</button>
                            {
                                itemDetails.infoList.map((info) => {
                                    return (
                                        <div className='row' style={{ ...styles.planSubInfoRow }}>
                                            <img style={{ ...styles.subInfoIcon }} src={info.infoIcon}></img>
                                            <div className='smallText'>{info.infoText}</div>
                                        </div>
                                    )
                                })
                            }


                        </div>
                    )
            }
            )
        )
    }

    render() {
        const { screenWidth } = this.state;
        return (
            <div className='montserrat innerContent lightpurpleBG' style={{ paddingBottom: '0px' }}>
                <div style={{ backgroundColor: '#F8F7F4', width: '100%', justifyContent: 'center', textAlign: 'center' }}>

                    <div style={styles.choosePlanDiv}>
                        <div className='collosalText40 boldText'>Change Plan</div>
                        <br />
                        <div className='smallText grayText'>Explore our range of plans designed to meet your unique needs. Stay connected without breaking the bank.</div>
                        <br /><br />
                        <div className='row mobileRowToColumn mobileTransBG' style={{ borderRadius: '24px', justifyContent: 'center', backgroundColor: 'white', width: 'fit-content', margin: 'auto' }}>
                            <button className='largeText' style={this.state.selectedPlanType == "PLUS" ? styles.activeBtn : styles.inactiveBtn} onClick={() => { this.setState({ selectedPlanType: "PLUS" }); }}>WIFI PLUS</button>
                            <button className='largeText' style={this.state.selectedPlanType == "PREMIUM" ? styles.activeBtn : styles.inactiveBtn} onClick={() => { this.setState({ selectedPlanType: "PREMIUM" }); }}>WIFI PREMIUM</button>
                        </div>
                        <div className='row' style={{ justifyContent: 'center', marginBottom: '120px' }}>
                            {this.renderPlanCard()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const choosePlanBgImage = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Choose%20your%20plan.png`;
const connectYouBgImage = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Connecting%20XOX%20MOWIFI.png`;
const checkBalImage = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Check%20balance.png`;

const styles = {
    planSubInfoRow: {
        padding: '10px 10%'
    },
    subInfoIcon: {
        width: '15px',
        height: '15px',
        marginRight: '10px'
    },
    activeBtn: {
        borderRadius: '24px',
        width: '186px',
        height: '48px',
        backgroundColor: '#372EA8',
        color: 'white',
        borderWidth: '0px',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        boxShadow: '0 4px 8px rgba(0, 220, 0, 0.1)',
    },
    inactiveBtn: {
        cursor: 'pointer',
        borderRadius: '24px',
        width: '186px',
        height: '48px',
        backgroundColor: 'white',
        color: 'black',
        borderWidth: '0px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
    },
    choosePlanDiv: {
        backgroundImage: `url(${choosePlanBgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        padding: '80px 0px'
    },
    checkYourBalDiv: {
        // backgroundImage: `url(${checkBalImage})`,
        background: 'transparent linear-gradient(299deg, rgba(85, 77, 216,0.7) 0%, rgba(8, 0 ,185 ,0.7) 100%) 0% 0% no-repeat padding-box',
        width: '90%',
        maxWidth: '1220px',
        margin: 'auto',
        padding: '70px 0',
        borderRadius: '50px',
        marginTop: '130px',
    },
    connectDiv: {
        backgroundImage: `url(${connectYouBgImage})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    btnShape: {
        alignSelf: 'center',
        borderRadius: '30px',
        width: '250px',
        maxWidth: '75%',
        border: 0,
    },
    flavorInfo: {
        width: '267px',
        marginBottom: '20px',
        marginTop: '20px'
    }
};
export default SuperHOC(ChangePlan)
import React, { useState, useEffect, useRef } from 'react';
import _styles from '../../_styles.css';
import RedCross from '../../animation/red cross.json'
import { SuperHOC, RouteWithParam } from "../../HOC";
import Lottie from 'react-lottie';

function OrderConfirmation(props) {
    const [orderId, setOrderId] = useState('');
    const [isNewReg, setIsNewReg] = useState(false);
    const [email, setEmail] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [windowPopUp, setWindowPopUp] = useState(0);

    useEffect(() => {
        var status = new URLSearchParams(window.location.search);
        status = status.get('paymentStatus');
        setPaymentStatus(status);
        var params = atob(props.params.param)
        try {
            params = JSON.parse(params);
        } catch (e) {
            window.location = '/';
            return false;
        }

        setOrderId(params.orderId);
        setEmail(params.email);
        setIsNewReg(params.isNewReg ? true : false);

        if (status != 1) setWindowPopUp(1);
        if (status == 2 && params.cancelOrder) {
            var remark = 'User did not complete payment';
            props.apiManager.makeCall('mobileWifi/cancelPreorder', { cancelPreOrderId: params.cancelOrder, remark: remark }, null, (res) => {
            });
        }
    }, []);


    const renderFail = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: RedCross,
            renderer: 'svg'
        }
        return (
            <div className='whiteBG' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', width: '400px', maxWidth: '90%', borderRadius: '8px' }}>

                <Lottie
                    options={defaultOptions}
                    style={{ width: '150px' }}
                />
                <div className='boldText collosalText' style={{ fontSize: '24px', margin: '0 0 19px 0' }}>Payment Unsuccessful</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px', width: '80%', margin: 'auto' }}>We are not able to process your payment. Please try again.</div>

                <button type='button' onClick={() => { window.location = '/xoxwifi' }} className='clickable whiteText redBG redHoverBG semiBoldText largeText mobileHeight50' style={{ fontSize: '18px', margin: '52px auto 10px auto', ...styles.btnShape }}>Try Again</button>
            </div>
        )
    }

    return (
        paymentStatus == 1 ? (
            isNewReg ?
            <div className={'montserrat innerContent noPaddingTop'} style={{ paddingBottom: '0px', backgroundColor: '#F8F8F8' }}>
                <div className='boxPadding navigationRow' style={{ ...styles.bg2, paddingTop: '15vh', paddingBottom: '15vh', textAlign: 'center', justifyContent: 'center' }}>
                    <div className='boldText blueText collosalText40' style={{ marginBottom: '17px' }}>Congratulations!</div>
                    <div className='smallText' style={{ marginBottom: '40px', lineHeight:1.5 }}>You are one step away to start your XOX WiFi journey.<br/><a href="https://onexox.my/Black-App" target="_blank" className='blueText semiBoldText' style={{textDecoration:'none'}}>Download BLACK app for your eKYC activation.</a> </div>
                    <div className='smallText mobileNoMargin' style={{ margin: '0 20%', lineHeight:1.5 }}>An email will be sent to <span className='blueText semiBoldText'>{email}</span> with your order confirmation and receipt. If the email hasn’t arrived after a few minutes, please check your spam folder.</div>
                </div>
                <div style={{position:'relative'}}>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/downloadBanner/download_banner@2x.png`} style={{height:'auto', width:'100%'}}/>
                    <div style={{position:'absolute', left:'60%', top:'25%', width:'40%'}}>
                        <div className='extraBoldText' style={{fontSize:'2vw',marginBottom:'3%'}}>Scan & Download Now!</div>
                        <div className='row' style={{justifyContent:'space-between', width:'56.25%'}}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/downloadBanner/qrcode@2x.png`} style={{height:'auto', width:'48%'}}/>
                            <div  style={{width:'48%'}}>
                                <a href='https://onelink.to/4y46p2' target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/images/downloadBanner/applestore@2x.png`} style={{height:'auto', width:'100%', marginBottom:'6%'}}/></a>
                                <a href='https://onelink.to/4y46p2' target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/images/downloadBanner/googleplay@2x.png`} style={{height:'auto', width:'100%', marginTop:'6%'}}/></a>
                            </div>
                        </div>
                    </div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/downloadBanner/blackapp@2x.png`} style={{height:'auto', width:'8%', position:'absolute',left:'15%',bottom:'15%'}}/>
                </div>
            </div>
            :
            <div className='montserrat innerContent' style={{ paddingBottom: '140px', backgroundColor: '#F8F8F8', ...styles.bg }}>
                <div className='boxPadding' style={{ marginTop: '220px', paddingBottom: '170px', textAlign: 'center', justifyContent: 'center' }}>
                    <div className='boldText blueText collosalText48' style={{ marginBottom: '17px' }}>THANK YOU!</div>
                    <div className='smallText' style={{ marginBottom: '20px' }}>Your order <span className='blueText'>#{orderId}</span> has been placed!</div>
                    <div className='smallText mobileNoMargin' style={{ margin: '0 20%', lineHeight:1.5 }}>An email will be sent to <span className='blueText semiBoldText'>{email}</span> with your order confirmation details. If the email hasn’t arrived after a few minutes, please check your spam folder.</div>
                    <button onClick={() => { window.location.href = '/xoxwifi'; }} type='button' className='blueBG blueHoverBG noBorder clickable whiteText largeText mobileHeight50' style={{ height: '60px', width: '286px', borderRadius: '30px', maxWidth: '70%', marginTop: '70px' }}>Okay</button>
                </div>
            </div>
        )
            :
            <div className={'montserrat innerContent noPaddingTop'} style={{ paddingBottom: '140px', backgroundColor: '#F8F8F8', ...styles.bg }}>
                <div className='popupWindowBG' style={{ height: '100vh' }}>
                    {windowPopUp == 1 ? renderFail() : null}
                </div>
                <div className='boxPadding navigationRow' style={{ marginTop: '220px', paddingBottom: '170px', textAlign: 'center', justifyContent: 'center' }}>
                </div>
            </div>

    )

}

const backgroundImageURL = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Order%20confirmation.png`;
const styles = {
    bg: {
        backgroundImage: `url(${backgroundImageURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        padding: '0 0 0 0'
    },
    bg2: {
        backgroundImage: `url(${backgroundImageURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    btnShape: {
        alignSelf: 'center',
        borderRadius: '30px',
        height: '60px',
        width: '53.19%',
        minWidth: '200px',
        border: 0,
    },

};
export default RouteWithParam(SuperHOC(OrderConfirmation))
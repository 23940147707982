import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import _styles from '../../_styles.css';
import Loader from 'react-loader-spinner'
import Lottie from 'react-lottie';
import GreenTick from '../../animation/green tick.json'
import RedCross from '../../animation/red cross.json'
import Alert from '../../animation/alert.json'
import { IoShieldCheckmark } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import Cookies from 'js-cookie';
import { SuperHOC } from "../../HOC";

function ManageSubscription(props) {

    const tacInputRefs = useRef([]);
    const [token, setToken] = useState();
    const [mobileNumberValue, setMobileNumberValue] = useState('');
    const [windowPopUp, setWindowPopUp] = useState(0);//0- off 1 - OTP 2- Add card 3- unbind 4- cancel subscroption 5- success 6- fail 7-change email 8- session expired
    const [tacValues, setTacValues] = useState(['', '', '', '', '', '']);
    const [timerRunning, setTimerRunning] = useState(false);
    const [email, setEmail] = useState(false);
    const [autoDebitInfo, setAutoDebitInfo] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [paymentHistoryList, setPaymentHistoryList] = useState([]);
    const [userInfo, setUserInfo] = useState({ show: false });

    const parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        var token = Cookies.get('token');
        setToken(token);
        var decodedToken;
        try {
            decodedToken = parseJwt(token);
        } catch (error) {
            decodedToken = null
        }

        if (!(decodedToken && decodedToken.msisdn)) {
            setWindowPopUp(8);
        } else {
            props.apiManager.makeCall('mobileWifi/validateMSISDN', { msisdn: decodedToken.msisdn }, null, (res) => {
                if (res && res.is_success && res.data) {
                    var responseData = res.data;
                    if (responseData.plan == "WIFI_PREMIUM") responseData.plan = "WiFi PREMIUM FREEDOM";
                    if (responseData.plan == "WIFI_PLUS") responseData.plan = "WiFi PLUS FREEDOM";
                    if (responseData.plan == "Wifi Plus") responseData.plan = "WiFi PLUS LITE";
                    if (responseData.plan == "Wifi Premium") responseData.plan = "WiFi PREMIUM LITE";

                    if (responseData.dataBal && responseData.dataBal.includes('MB')) {
                        responseData.dataBal.replace('MB', '');
                        responseData.dataBal = (parseInt((responseData.dataBal.replace('MB', '')) / 1024)) + 'GB';
                    }

                    responseData.dueDate = formatDate(responseData.dueDate);

                    responseData.amountDue = ('RM ' + (parseFloat(responseData.amountDue)).toFixed(2));

                    responseData.msisdn = '+6' + responseData.msisdn.slice(0, 3) + '-' + responseData.msisdn.slice(3, 6) + ' ' + responseData.msisdn.slice(6)

                    responseData.status = responseData.status == 'activated' ? 'Active' : responseData.status;

                    responseData.show = true;

                    props.apiManager.makeCall('mobileWifi/checkPaymentHistory', {}, token, (res) => {
                        if (res.message && res.data && (res.message == 1020 || res.message == 1021)) {
                            setPaymentHistoryList(res.data);
                        } else if (res.message && res.message == 401) {
                            setWindowPopUp(8);
                        } else {
                            setWindowPopUp(10);
                        }
                        setUserInfo(responseData);
                        setMobileNumberValue(responseData.msisdn);
                    });

                    props.apiManager.makeCall('mobileWifi/autoDebitInfo', {}, token, (res) => {
                        if (res.message && res.data && res.message == 1040 && res.data.AutoDebitSubscriberInfoResult) {
                            setAutoDebitInfo(res.data.AutoDebitSubscriberInfoResult);
                        }
                    });
                } else {
                    setWindowPopUp(9)
                }
            });
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        let intervalId;

        if (timerRunning) {
            intervalId = setInterval(() => {
                setSecondsLeft((prevTime) => {
                    if (prevTime - 1 < 0) stopTimer();
                    return prevTime - 1
                });
            }, 1000);
        } else {
            clearInterval(intervalId);
        }

        return () => {
            clearInterval(intervalId);
        }
    }, [timerRunning])


    const formatDate = (inputDate) => {
        // Parse the input date string
        const dateObj = new Date(inputDate);

        // Format the date
        const day = dateObj.getDate();
        const monthIndex = dateObj.getMonth();
        const year = dateObj.getFullYear();

        // Array of month names
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Format the date string
        const formattedDate = `${day} ${months[monthIndex]} ${year}`;

        return formattedDate;
    }

    const requestOtp = () => {
        setOtpErrorMessage('');
        var decodedToken = parseJwt(token);

        if (decodedToken.msisdn) {
            setIsLoading2(true)
            setWindowPopUp(1);
            props.apiManager.makeCall('mobileWifi/requestTac', { msisdn: decodedToken.msisdn }, null, (res) => {
                if (res.success) {
                    startTimer();
                } else {
                    if (res.message && res.message == 1002) {
                        setOtpErrorMessage('Invalid MSISDN.');
                    } else if (res.message && res.message == 6107) {
                        setOtpErrorMessage('OTP exceeded. Retry in 10 minutes.');
                        setSecondsLeft(-1)
                    } else {
                        setOtpErrorMessage('Failed to send OTP, please retry.');
                        setSecondsLeft(-1)
                    }

                }

                setIsLoading2(false)
            });
        }
    }

    const verifyOtp = () => {
        if (secondsLeft <= 0) return false;

        var decodedToken = parseJwt(token);

        setIsLoading2(true);
        var combinedTAC = tacValues[0] + tacValues[1] + tacValues[2] + tacValues[3] + tacValues[4] + tacValues[5];
        props.apiManager.makeCall('mobileWifi/verifyTac', { msisdn: decodedToken.msisdn, otp: combinedTAC }, null, (res) => {
            if (res.success) {
                Cookies.set('token', res.token, { expires: 1 / 48 });
                disableAutoDebit();
            } else {
                if (res.message && res.message == 7039) {
                    setOtpErrorMessage('Invalid OTP');
                }
                if (res.message && res.message == 6113) {
                    setOtpErrorMessage('OTP Expired');
                } else {
                    setOtpErrorMessage('Invalid OTP');
                }
            }

            setIsLoading2(false);
        });
    }

    const startTimer = () => {
        setSecondsLeft(120);
        setTimerRunning(true);
    };

    const stopTimer = () => {
        setTacValues(['', '', '', '', '', '']);
        setTimerRunning(false);
        setSecondsLeft(-1)
        return 0;
    };

    const bindCard = () => {

        var token = Cookies.get('token');

        if (!token) setWindowPopUp(8);
        props.apiManager.makeCall('payment/createOrderMobileWifiMSISDN', { actionKey: 302 }, token, (res) => {
            if (res.message && res.message == 1051 && res.url) {
                var redirect = res.url + '?callback_url=' + window.location;
                window.location = redirect;
            } else if (res.message && res.message == 401) {
                setWindowPopUp(8);
            } else {
                setWindowPopUp(6);
            }

        });
    }

    const convertDateFormat = (dateString) => {
        // Parse the input date string
        const dateObj = new Date(dateString);

        // Get day, month, and year
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();

        // Format the date string as 'DD/MM/YYYY'
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    }

    const handleOtpChange = (index, value) => {
        const newOtpValues = [...tacValues];
        newOtpValues[index] = value;
        setTacValues(newOtpValues)

        if (value !== '' && index < 5) {
            tacInputRefs[index + 1].focus();
        }
    };

    const disableAutoDebit = () => {
        var token = Cookies.get('token');

        props.apiManager.makeCall('mobileWifi/unbindAutoDebit', {}, token, (res) => {
            if (res.message && res.message == 1030) {
                setWindowPopUp(5);
            } else if (res.message && res.message == 401) {
                setWindowPopUp(8);
            } else {
                setWindowPopUp(11);
            }
        });
    }

    const renderPaymentHistory = () => {
        return (
            paymentHistoryList.map((item, index) => {
                if (index == paymentHistoryList.length - 1)
                    return (
                        <tr className='tableLastRow'>
                            <td>{convertDateFormat(item.consumption_date)}</td>
                            <td style={{whiteSpace:'nowrap'}}>{(item.msisdn && item.msisdn.length > 4) ? ('+' + item.msisdn.slice(0,4) + '-' + item.msisdn.slice(4)) : item.msisdn}</td>
                            <td>{item.remarks}</td>
                            <td>RM{item.amount}</td>
                            <td>
                                <div className='row greenText' style={{ alignItems: 'center' }}>
                                    <div style={{ padding: 'auto', borderRadius: '20px', backgroundColor: '#0CA750', width: '8px', height: '8px', marginRight: '2px' }}></div>
                                    &nbsp;Paid
                                </div>
                            </td>
                            {/* <td>{item.refNo}</td> */}
                        </tr>
                    );

                else
                    return (
                        <tr className='tableRow'>
                            <td>{convertDateFormat(item.consumption_date)}</td>
                            <td style={{whiteSpace:'nowrap'}}>{(item.msisdn && item.msisdn.length > 4) ? ('+' + item.msisdn.slice(0,4) + '-' + item.msisdn.slice(4)) : item.msisdn}</td>
                            <td>{item.remarks}</td>
                            <td>RM{item.amount}</td>
                            <td>
                                <div className='row greenText' style={{ alignItems: 'center' }}>
                                    <div style={{ padding: 'auto', borderRadius: '20px', backgroundColor: '#0CA750', width: '8px', height: '8px', marginRight: '2px' }}></div>
                                    &nbsp;Paid
                                </div>
                            </td>
                            {/* <td>{item.refNo}</td> */}
                        </tr>
                    );

            })
        )
    }

    const renderPaymentHistoryMobile = () => {
        return (
            paymentHistoryList.map((item, index) => {
                return (
                    <table style={{ width: '100%', textAlign: 'left' }}>
                        <tr>
                            <td className='smallText12 semiBoldText' style={{ paddingRight: '10px' }}>{(item.msisdn && item.msisdn.length > 4) ? (item.msisdn.slice(0,4) + '-' + item.msisdn.slice(4)) : item.msisdn}</td>
                            <td className='smallText semiBoldText' style={{ textAlign: 'right' }}>RM{item.amount}</td>
                        </tr>
                        <tr>
                            <td className='smallText12 lightgrayText' style={{ paddingRight: '10px' }}>{convertDateFormat(item.consumption_date)}</td>
                            <td className='smallText12 greenText row' style={{ textAlign: 'right', justifyContent: 'right' }}>
                                <div style={{ borderRadius: '20px', backgroundColor: '#0CA750', width: '8px', height: '8px', marginRight: '2px' }}></div>
                                &nbsp;Paid
                            </td>
                        </tr>
                        <tr>
                            <td className='smallText' style={{ paddingRight: '10px' }}>{item.remarks}</td>
                            {/* <td className='smallText12 lightgrayText' style={{ paddingRight: '10px' }}>Ref. No. {item.refNo}</td> */}
                        </tr>
                        {
                            index != paymentHistoryList.length - 1 ?
                                <tr>
                                    <td colSpan={2} style={{ color: '#12161F', opacity: 0.2 }}><hr /></td>
                                </tr>
                                :
                                null
                        }
                    </table>
                )
            })
        )
    }

    const renderChangeEmail = () => {
        {
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: Alert,
                renderer: 'svg'
            }
            return (
                <div className='whiteBG mobileMediumPadding' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '12.5px 50px 30px 50px', maxWidth: '90%', borderRadius: '8px' }}>
                    <Lottie
                        options={defaultOptions}
                        style={{ width: '150px' }}
                    />
                    <div className='semiBoldText collosalText' style={{ fontSize: '24px', marginBottom: '15px' }}>Change Email Address</div>
                    <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>To help keep your account safe, we want to make sure it’s really you.<br />Please call our customer service at 1300888010 for assistance.</div>

                    <button type='button' onClick={() => { setWindowPopUp(1) }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: 'auto', marginTop: '50px', marginBottom:'20px', ...styles.btnShape }}>Okay</button>
                </div>
            )
        }
    }

    const renderOTPVerification = () => {
        {
            return (
                <div className='whiteBG horizontalMinPadding20_800px verticalMinPadding20_800px' style={{ margin: '0 0', position: 'relative', textAlign: 'center', padding: '3%', maxWidth: '90%', borderRadius: '8px' }}>
                    <div onClick={() => { setWindowPopUp(0) }} className='closeBtn lightgrayText collosalText clickable'>
                        <img style={{ paddingTop: '5px' }} size={25} color='#6E6D7A' src={`${process.env.PUBLIC_URL}/assets/images/images_icons/icon/close.svg`}></img>
                    </div>
                    <div className='semiBoldText collosalText' style={{ marginBottom: '20px' }}>Security Verification</div>
                    <div className='lightgrayText smallText'>We’ve sent a verification code by email to</div>
                    <br/>
                    <div className='semiBoldText largeText' style={{ marginBottom: '11px' }}>{userInfo.email}</div>
                    <div className='lightgrayText smallText'>Please enter the 6-digit verification code below.</div>
                    <div className='row' style={{ justifyContent: 'center', marginTop: '40px' }}>
                        {tacValues.map((value, index) => (
                            <input
                                className='tacInput collosalText30 semiBoldText'
                                key={index}
                                ref={(el) => (tacInputRefs[index] = el)}
                                type="text"
                                disabled={secondsLeft > 0 ? false : true}
                                maxLength={1}
                                value={tacValues[index]}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (/^\d*$/.test(newValue) || newValue === '') {
                                        handleOtpChange(index, newValue);
                                    }
                                }}

                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace' && tacValues[index] === '') {
                                        e.preventDefault();
                                        if (index > 0 && tacInputRefs[index - 1]) {
                                            tacInputRefs[index - 1].focus();
                                        }
                                    }
                                }}
                            />
                        ))}
                    </div>
                    <div className='lightgrayText smallText' style={{ textAlign: 'right', marginTop: '17px', marginBottom: '30px' }}>
                        <div className='row' style={{ justifyContent: 'right', height: '25px' }}>

                            <div className='blackText smallText'> {(secondsLeft >= 0 || (otpErrorMessage != '' && otpErrorMessage != 'Invalid OTP')) ? otpErrorMessage : 'Didn\'t receive code?'}</div>
                            {secondsLeft >= 0 ?
                                <span style={{ marginLeft: '5px' }}>Code expires in <span className='redText'>0{Math.trunc(secondsLeft / 60)}:{secondsLeft % 60 < 10 ? ('0' + secondsLeft % 60) : secondsLeft % 60}</span></span>
                                :
                                <div style={{ marginLeft: '5px' }} className='redText redHoverText semiBoldText clickable' onClick={() => { requestOtp() }}>
                                    Resend OTP
                                </div>
                            }
                        </div>
                            {isLoading2 ? <Loader type="TailSpin" style={{marginTop:'3px'}} width={25} height={25} /> : <div style={{height:'28px'}}/>}



                    </div>
                    <button type='button' 
                    onClick={() => { verifyOtp() }} 
                    disabled={(secondsLeft > 0 && !tacValues.includes('')) ? false : true}
                    className={'whiteText blueBG semiBoldText largeText mobileHeight50 ' + ((secondsLeft > 0 && !tacValues.includes('')) ? 'blueHoverBG clickable' : '')} 
                    style={{ margin: 'auto', marginBottom: '30px', ...styles.btnShape, backgroundColor: (secondsLeft > 0 && !tacValues.includes('')) ? '#372EA8' : 'lightgray' }}
                    >Verify</button>
                    <div className='clickable blueText blueHoverText smallText semiBoldText' onClick={() => setWindowPopUp(2)} style={{ width: 'fit-content', margin: 'auto' }}>Change Email</div>
                </div>
            )
        }
    }

    const renderBind = () => {
        return (
            <div className='whiteBG mobileMediumPadding' style={{ margin: '0 5px', overflowY: 'auto', maxHeight: '70%', position: 'relative', textAlign: 'center', padding: '50px', maxWidth: '80%', borderRadius: '8px' }}>
                <div onClick={() => { setWindowPopUp(0) }} className='closeBtn lightgrayText collosalText clickable'>
                        <img style={{ paddingTop: '5px' }} size={25} color='#6E6D7A' src={`${process.env.PUBLIC_URL}/assets/images/images_icons/icon/close.svg`}></img>
                    </div>
                <div className='boldText collosalText' style={{ marginTop: '15px', fontSize: '24px' }}>Add Card</div>
                <div className='smallText13 lightgrayText' style={{ textAlign: 'left', lineHeight: '20px', marginTop: '35px', marginBottom: '40px', maxWidth: '430px' }}>By proceeding, I acknowledge that I have read, understood and agreed to the website’s <a href={`${process.env.PUBLIC_URL}/assets/T&C/[T&C] XOX WiFi.pdf`} target='_blank' className='boldText' style={{ ...styles.anchorBtn }}>Terms and Conditions</a> & <a href='https://xox.com.my/privacystatement/details/' target='_blank' className='boldText' style={{ ...styles.anchorBtn }}>Privacy Policy</a>. XOX Media may store your personal info for internal analysis use or related activities.</div>
                <button type='button' onClick={() => { bindCard(); }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: 'auto', ...styles.btnShape }}>Proceed</button>
                <div className='smallText13 row' style={{ opacity: 0.3, justifyContent: 'center', marginTop: '20px', marginBottom: '10px' }}><IoShieldCheckmark size={17} color='#707070' style={{ marginRight: '5px' }} />All transactions are secure and encrypted.</div>
            </div>
        )
    }

    const renderUnBind = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Alert,
            renderer: 'svg'
        }
        return (
            <div className='whiteBG fixedPadding_largeScreen' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', width: '470px', maxWidth: '90%', borderRadius: '8px' }}>
                <Lottie
                    options={defaultOptions}
                    style={{ width: '150px' }}
                />
                <div className='semiBoldText collosalText' style={{ fontSize: '24px', margin: '0px 0 19px 0' }}>Disable Auto-Debit</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>Are you sure you want to disable Auto-Debit?</div>
                <div className='row' style={{ marginTop: '52px', gap: '5px' }}>
                    <button type='button' onClick={() => { setWindowPopUp(0) }} className='clickable whiteText lightgrayBG semiBoldText largeText whiteText mobileHeight50' style={{ margin: '0 auto 10px auto', ...styles.btnShape, maxWidth: '0px' }}>Cancel</button>
                    <button type='button' onClick={() => { requestOtp() }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: '0 auto 10px auto', ...styles.btnShape, maxWidth: '0px' }}>Disable</button>
                </div>
            </div>
        )
    }

    const renderCancelSubscriptiton = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Alert,
            renderer: 'svg'
        }
        return (
            <div className='whiteBG' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', maxWidth: '90%', width: '460px', borderRadius: '8px' }}>
                <Lottie
                    options={defaultOptions}
                    style={{ width: '150px' }}
                />
                <div className='semiBoldText collosalText' style={{ fontSize: '24px', margin: '0 0 19px 0' }}>Cancel Subscription?</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px', maxWidth: '80%', margin: 'auto' }}>We’re sad to see you go!<br />Please call our customer service at 1300888010 for assistance.</div>

                <button type='button' onClick={() => { setWindowPopUp(0) }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: '52px auto 10px auto', ...styles.btnShape }}>Okay</button>
            </div>
        )
    }
    const renderSuccess = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: GreenTick,
            renderer: 'svg'
        }
        return (
            <div className='whiteBG' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', width: '460px', maxWidth: '90%', borderRadius: '8px' }}>
                <Lottie
                    options={defaultOptions}
                    style={{ width: '100px' }}
                />
                <div className='semiBoldText collosalText' style={{ fontSize: '24px', margin: '20px 0 19px 0' }}>Successful</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>Your Auto-Debit has been disabled.</div>

                <button type='button' onClick={() => { window.location = '/xoxwifi/manage_subscription' }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: '52px auto 10px auto', ...styles.btnShape }}>Okay</button>
            </div>
        )
    }
    const renderFail = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Alert,
            renderer: 'svg'
        }
        return (
            <div className='whiteBG' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', width: '400px', maxWidth: '90%', borderRadius: '8px' }}>

                <Lottie
                    options={defaultOptions}
                    style={{ width: '150px' }}
                />
                <div className='semiBoldText collosalText' style={{ fontSize: '24px', margin: '0 0 19px 0' }}>Oops!</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>Failed to proceed. Please Try Again.</div>

                <button type='button' onClick={() => { window.location = '/xoxwifi/manage_subscription' }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: '52px auto 10px auto', ...styles.btnShape }}>Okay</button>
            </div>
        )
    }

    const renderExpired = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Alert,
            renderer: 'svg'
        }
        return (
            <div className='whiteBG' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', width: '400px', maxWidth: '90%', borderRadius: '8px' }}>

                <Lottie
                    options={defaultOptions}
                    style={{ width: '150px' }}
                />
                <div className='semiBoldText collosalText' style={{ fontSize: '24px', margin: '0 0 19px 0' }}>Session Expired</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>Please verify your number again.</div>

                <button type='button' onClick={() => { window.location = '/xoxwifi/checkbalance' }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: '52px auto 10px auto', ...styles.btnShape }}>Back</button>
            </div>
        )
    }

    const renderRefresh = (message) => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Alert,
            renderer: 'svg'
        }
        return (

            <div className='whiteBG fixedPadding_largeScreen' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', width: '470px', maxWidth: '90%', borderRadius: '8px' }}>
                <Lottie
                    options={defaultOptions}
                    style={{ width: '150px' }}
                />
                <div className='semiBoldText collosalText' style={{ fontSize: '24px', margin: '0 0 19px 0' }}>Oops!</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>{message}</div>
                <div className='row' style={{ marginTop: '52px', gap: '5px' }}>
                    <button type='button' onClick={() => { setWindowPopUp(0) }} className='clickable whiteText lightgrayBG semiBoldText largeText whiteText mobileHeight50' style={{ margin: '0 auto 10px auto', ...styles.btnShape, maxWidth: '0px' }}>Cancel</button>
                    <button type='button' onClick={() => { window.location = '/xoxwifi/manage_subscription' }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: '0 auto 10px auto', ...styles.btnShape, maxWidth: '0px' }}>Try Again</button>
                </div>
            </div>

        )
    }

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };
    return (
        <div className={'montserrat innerContent noPaddingTop'} style={screenWidth > 600 ? { ...styles.bg } : { ...styles.mbg }}>
            <div className={windowPopUp == 0 ? 'noneDisplay' : 'popupWindowBG'}>
                {windowPopUp == 1 ? renderOTPVerification() : null}
                {windowPopUp == 2 ? renderBind() : null}
                {windowPopUp == 3 ? renderUnBind() : null}
                {windowPopUp == 4 ? renderCancelSubscriptiton() : null}
                {windowPopUp == 5 ? renderSuccess() : null}
                {windowPopUp == 6 ? renderFail() : null}
                {windowPopUp == 7 ? renderChangeEmail() : null}
                {windowPopUp == 8 ? renderExpired() : null}
                {windowPopUp == 9 ? renderRefresh('Failed to get subscriber info.') : null}
                {windowPopUp == 10 ? renderRefresh('Failed to get payment history info.') : null}
                {windowPopUp == 11 ? renderRefresh('Failed to disable Auto Debit.') : null}
            </div>
            <div className='boxPadding navigationRow'>
                <div className='blackText montserrat backBtn row poppins smallText'>
                    <a href={`${process.env.PUBLIC_URL}/`} className='blueHoverText' style={{ color: 'black', width: 'fit-content', textDecoration: 'none' }} >
                        Home
                    </a>

                    <IoIosArrowForward style={{ paddingBottom: '0px' }} />
                    Manage Your Subscription
                </div>
                <div className='collosalText32 boldText' style={{ textAlign: 'center', marginBottom: '30px' }}>Manage Your Subscription</div>
                {
                    userInfo.show ?
                        <div className='row' style={{ justifyContent:'space-between' }}>
                            <div className='lightpurpleBG mediumFullWidth horizontalMaxPadding3percent_1100px verticalMaxPadding3percent_1100px noMinHeight800' style={{ padding: '5%',borderRadius:'10px' , width: screenWidth > 1100 ?  '43%' : '39%', marginBottom: '20px', minHeight: screenWidth > 1000 ? '220px' : '250px' }}>
                                <div className='row'>
                                    <div className='boldText collosalText grayText' style={{ marginBottom: '10px' }}>{userInfo.msisdn}</div>
                                    </div>
                                <div className='row' style={{ justifyContent: screenWidth > 800 ? '' : 'center', marginBottom: '5px' }}>
                                    <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={userInfo.plan.includes('PREMIUM') ? "/assets/images/images_icons/icon/wifi%20premium.svg" : "/assets/images/images_icons/icon/wifi%20plus.svg"} width="50" height="50" />
                                        <div className='collosalText28 boldText' style={{textAlign:'center'}}>{userInfo.plan}</div>
                                        <div className='smallText whiteText semiBoldText' style={{ textAlign: 'center', width: '50px', minWidth: '50px', marginLeft: '10px', padding: '3px 10px', borderRadius: '10px', backgroundColor: (userInfo.status == 'Active' ? '#2BB241' : 'red') }}>{userInfo.status}</div>
                                    </div>
                                </div>
                                <div className='grayText mediumText' style={{ textAlign: screenWidth > 800 ? '' : 'center', marginBottom: '30px' }}>Next payment {userInfo.amountDue} on {userInfo.dueDate}</div>
                                <div className='row' style={{ justifyContent: screenWidth > 800 ? '' : 'center' }}>
                                    {/* <button type='button' onClick={() => { window.location.href = '/change_plan' }} className='clickable blueBG blueHoverBG semiBoldText largeText whiteText mobileHeight40' style={{ border: '0', borderRadius: '30px', width: '224px', height: '50px', margin: '0 5px' }}>Change Plan</button> */}
                                    <button onClick={() => { setWindowPopUp(4) }} className='clickable blueText blueHoverText blueHoverBorder semiBoldText largeText mobileHeight40' style={{ margin: '5px 5px 0 5px', height: '50px', borderRadius: '25px', backgroundColor: 'transparent', border: '1px solid #372EA8', width: '224px' }}>Cancel Subscription</button>
                                </div>
                            </div>
                            {
                                autoDebitInfo ?
                                    <div className='whiteBG mediumFullWidth horizontalMaxPadding3percent_1100px verticalMaxPadding3percent_1100px noMinHeight800' style={{ padding: '5%',borderRadius:'10px' , width: screenWidth > 1100 ?  '43%' : '39%', marginBottom: '20px', minHeight: screenWidth > 1000 ? '220px' : '250px', textAlign: 'left' }}>
                                        <div className='boldText collosalText' style={{ marginBottom: '30px', textAlign: 'left' }}>Payment Method</div>
                                        <div className='row' style={{ justifyContent: screenWidth > 800 ? '' : 'center', alignItems: 'center', gap: '15px' }}>
                                            <img src={"/assets/images/images_icons/icon/icon_autodebit.svg"} width="40" height="40" />
                                            <div className='mediumText grayText' style={{ fontSize: '16px' }}>Credit / Debit Card</div>
                                        </div>
                                        <div className='row' style={{ justifyContent: screenWidth > 800 ? '' : 'center', alignItems: 'center', gap: '15px' }}>
                                            <div style={{ height: '20px', width: '40px' }} />
                                            <div className='mediumText semiBoldText' style={{ fontSize: '16px' }}>Ending in {autoDebitInfo.CardNumber ? autoDebitInfo.CardNumber.slice(-4) : ''}</div>
                                        </div>
                                        <div className='row' style={{ justifyContent: screenWidth > 800 ? '' : 'center' }}>
                                            <button
                                                onClick={() => { setWindowPopUp(3) }}
                                                className='clickable blueText blueHoverText blueHoverBorder semiBoldText largeText mobileHeight40'
                                                style={{ width: '200px', height: '50px', borderRadius: '25px', backgroundColor: 'transparent', border: '1px solid #372EA8', marginTop: '35px' }}
                                            >Cancel Auto Debit</button>
                                        </div>
                                    </div>
                                    :
                                    <div className='whiteBG mediumFullWidth horizontalMaxPadding3percent_1100px verticalMaxPadding3percent_1100px noMinHeight800' style={{ padding: '5%',borderRadius:'10px' , width: screenWidth > 1100 ?  '43%' : '39%', marginBottom: '20px', minHeight: screenWidth > 1000 ? '220px' : '250px', textAlign: 'center' }}>
                                        <div className='boldText collosalText' style={{ marginBottom: '30px', textAlign: 'left' }}>Auto-Debit</div>
                                        <div className='largeText semiBoldText' style={{ marginBottom: '10px' }}>Enable Auto-Debit</div>
                                        <div className='smallText grayText' style={{ marginBottom: '30px' }}>Do you wish to avoid the hassle of paying manually every month? Switch to auto-debit today!</div>
                                        <button onClick={() => { setWindowPopUp(2) }} className='clickable blueText blueHoverText blueHoverBorder semiBoldText largeText mobileHeight40' style={{ width: '160px', height: '50px', borderRadius: '25px', backgroundColor: 'transparent', border: '1px solid #372EA8' }}>Update</button>
                                    </div>
                            }
                            <div className='whiteBG' style={ screenWidth > 1100 ? { borderRadius:'10px', padding: '3%', width: '94%' } : { borderRadius:'10px', padding: '5%', width: '90%' }}>
                                <div className='boldText collosalText' style={{ marginBottom: '30px' }}>Payment History</div>
                                {

                                    (!Array.isArray(paymentHistoryList) || paymentHistoryList.length <= 0) ?

                                        <div className='redText semiBoldText grayText' style={{textAlign:'center'}}>No record found</div>

                                        : screenWidth > 600 ?
                                            <div style={{ overflowX: 'auto' }}>
                                                <table style={{ width: '100%', textAlign: 'left' }}>
                                                    <thead className='semiBoldText largeText' style={{ backgroundColor: '#F7F7F7' }}>
                                                        <tr className='tableRow' style={{ border: '1px solid #6E6D7A' }}>
                                                            <th>Date</th>
                                                            <th>Mobile Number</th>
                                                            <th>Description</th>
                                                            <th>Amount</th>
                                                            <th>Status</th>
                                                            {/* <th>Ref. No</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody className='smallText'>
                                                        {renderPaymentHistory()}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div>
                                                {renderPaymentHistoryMobile()}
                                            </div>
                                }
                            </div>
                        </div>
                        :
                        <div className='boxPadding' style={{ marginTop: '20px', textAlign: 'center', minHeight: '100vh' }}>
                            <Loader type="TailSpin" />
                        </div>
                }
                <div>

                </div>
            </div>
        </div>
    )

}

const backgroundImageURL = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Manage_your_subscription.png`;
const backgroundImageURLMobile = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Manage%20Your%20Subscription%20Bg%20Mobile%20View.png`;
const styles = {
    anchorBtn: {
        color: '#26225E'
    },
    btnShape: {
        alignSelf: 'center',
        borderRadius: '30px',
        height: '60px',
        width: '50%',
        minWidth: '200px',
        border: 0,
    },
    bg: {
        backgroundImage: `url(${backgroundImageURL})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width: '100%',
        paddingBottom: '80px'
    },
    mbg: {
        backgroundImage: `url(${backgroundImageURLMobile})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        width: '100%',
        paddingBottom: '80px'
    }

};
export default SuperHOC(ManageSubscription)
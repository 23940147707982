import React, { useState, useEffect } from 'react';
import _styles from '../../_styles.css';
import { SuperHOC, RouteWithParam } from "../../HOC";
import Loader from 'react-loader-spinner'
import BackButton from '../../component/common/backBtn';
import { useNavigate } from 'react-router-dom';

//zheli reset product id
function Plan(props) {
    const navigate = useNavigate();
    const [planDetailsList, setPlanDetailsList] = useState({
        "685": {
            productName: "WiFi PLUS LITE",
            SIM: "SIM ONLY",
            included: "Plan",
            planDescription: "Our 250GB SIM Only Plan is designed for those who crave the perfect balance of data, flexibility, and ease of use. Enjoy the freedom of no contracts, unlock the potential for additional data with our convenient data add-ons, and experience a hassle-free ‘plug & play’ connectivity with our user-friendly SIM.",
            planType: "Monthly Plan",
            internetAmount: "250GB Internet",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim only.svg`,
            price: "",
            additionalPriceInfo1: "",
            additionalPriceInfo2: "",
            infoList: [
                { infoText: "No Contract" },
                { infoText: "Eligible for data add-ons" },
                { infoText: "Plug & Play" },
            ]
        },
        "687": {
            productName: "WiFi PLUS FREEDOM",
            SIM: "SIM + 4G MiFi",
            included: "Plan + 4G MiFi",
            planDescription: "Our 250GB SIM + 4G MiFi bundle is your gateway to seamless connectivity without the constraints of contracts. Experience the joy of no commitments, unlock the potential for additional data with convenient data add-ons, and enjoy the ease of use with our ‘plug & play’ SIM. What’s more, grab the powerful 4G MiFi for only RM110, a steal from its actual price of RM189!",
            planType: "Monthly Plan",
            internetAmount: "250GB Internet",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim only.svg`,
            price: "",
            additionalPriceInfo1: "110",
            additionalPriceInfo2: "one time",
            infoList: [
                { infoText: "No Contract" },
                { infoText: "Eligible for data add-ons" },
                { infoText: "Plug & Play" },
                { infoText: "Portable & pocket-friendly" },
            ]
        },
        "686": {
            productName: "WiFi PREMIUM LITE",
            SIM: "SIM ONLY",
            included: "Plan",
            planDescription: "Our unlimited SIM Only Plan is crafted for those who demand the best in data, flexibility, and user-friendly experiences. Immerse yourself in a world of limitless possibilities with a generous data allowance, no contractual obligations, and the convenience of data add-ons. Get ready for a seamless ‘plug & play’ experience that puts you in control of your connectivity.",
            planType: "Monthly Plan",
            internetAmount: "Unlimited* Internet",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim only.svg`,
            price: "",
            additionalPriceInfo1: "",
            additionalPriceInfo2: "",
            infoList: [
                { infoText: "No Contract" },
                { infoText: "Eligible for data add-ons" },
                { infoText: "Plug & Play" },
            ]
        },
        "688": {
            productName: "WiFi PREMIUM FREEDOM",
            SIM: "SIM + 4G ROUTER",
            included: "Plan + 4G ROUTER",
            planDescription: "Our unlimited SIM + 4G Router bundle is designed to elevate your connectivity experience. With no contracts tying you down, you’ll enjoy the freedom of substantial data, flexibility with data add-ons, and an easy ‘plug & play’ setup. What’s more, grab the advanced 4G Router for only RM130, a significant discount from its actual price of RM220!",
            planType: "Monthly Plan",
            internetAmount: "Unlimited* Internet",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim only.svg`,
            price: "",
            additionalPriceInfo1: "130",
            additionalPriceInfo2: "one time",
            infoList: [
                { infoText: "No Contract" },
                { infoText: "Eligible for data add-ons" },
                { infoText: "Plug & Play" },
            ]
        },

    });
    const [mobileList, setMobileList] = useState([]);
    const [selectedMobileNumber, setSelectedMobileNumber] = useState('');
    const [selectedPlan, setSelectedPlan] = useState({});
    const [validPlan, setValidPlan] = useState(false);
    const [loading, setLoading] = useState(true);
    const [numberRefreshing, setNumberRefreshing] = useState(false);
    const [refreshError, setRefreshError] = useState(false);
    const [selectedPlanId, setSelectedPlanId] = useState('');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);   

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        props.apiManager.makeCall('mobileWifi/getProductPlanList', { platform: 'OL' }, null, (res) => {
            if (res.success) {
                if (Array.isArray(res.data.non_devices) && Array.isArray(res.data.devices)) {
                    res.data.devices = res.data.devices.filter(item => item.stock > 0);
                    let combineList = res.data.non_devices.concat(res.data.devices);
                    var matchedPlan = combineList.find(item => item.idproduct === atob(props.params.id));
                    if (matchedPlan != undefined) {
                        setSelectedPlan(matchedPlan);
                        setSelectedPlanId(atob(props.params.id));
                        setValidPlan(true);
                    }
                    //
                    props.apiManager.makeCall('numberPull/list', { pkgType: "OP" }, null, (res) => {
                        if (res.success && res.data.List) {
                            setMobileList(res.data.List);
                            setLoading(false);
                        } else {
                            setLoading(false);
                        }
                    });
                }
            } else {
                setLoading(false);
            }
        });
    }

    const refreshNumber = () => {
        setNumberRefreshing(true);
        setSelectedMobileNumber("");
        setRefreshError(false);
        if (!numberRefreshing) {
            props.apiManager.makeCall('numberPull/list', { pkgType: "OP" }, null, (res) => {
                if (res.success && res.data.List) {
                    setMobileList(res.data.List);
                } else {
                    setRefreshError(true);
                }
                setNumberRefreshing(false);
            });
        }
    }

    const renderMobileNo = () => {
        return (
            mobileList.map((item) => {
                var displayText = item.msisdn.length > 6 ? item.msisdn.slice(0, 3) + '-' + item.msisdn.slice(3, 6) + ' ' + item.msisdn.slice(6) : item.msisdn;

                return (
                    <div className={(selectedMobileNumber == item.msisdn ? 'selectedtoggleSelectionDiv' : 'blueHoverBorder blueHoverText') + ' clickable toggleSelectionDiv'}
                        onClick={() => {
                            if (!numberRefreshing) {
                                setSelectedMobileNumber(item.msisdn);
                                setRefreshError(false);
                            }
                        }}>
                        <span className='mediumText semiBoldText'>{displayText}</span>
                    </div>
                )
            })
        )
    }

    let selectedPlanDetails = planDetailsList[selectedPlanId];

    let prodImg = `${process.env.PUBLIC_URL}/assets/tempicon.png`;

    if (selectedPlanDetails != null || selectedPlanDetails != undefined) {
        if (planDetailsList[selectedPlanId].productName == 'WiFi PLUS LITE') {
            prodImg = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/WiFi_Plus_SIM_Only.jpg`;
        } else if (planDetailsList[selectedPlanId].productName == 'WiFi PLUS FREEDOM') {
            prodImg = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/WiFi_Plus_with_Router.jpg`;
        } else if (planDetailsList[selectedPlanId].productName == 'WiFi PREMIUM LITE') {
            prodImg = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/WiFi_Premium_SIM_Only.jpg`;
        } else if (planDetailsList[selectedPlanId].productName == 'WiFi PREMIUM FREEDOM') {
            prodImg = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/WiFi_Premium_with_Router.jpg`;
        }
    } 

    return (
        validPlan ?
            <div className='montserrat innerContent lightpurpleBG2' style={{ paddingBottom: '0' }}>
                <div className='boxPadding'>
                    <BackButton path={'xoxwifi'} />
                    <div className='collosalText32 boldText' style={{ marginBottom: screenWidth > 600 ? '50px' : '20px' }}>Plans for everyone</div>
                    <div className='row' style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <div className="planWidth" style={{ width: '35%', display: 'flex', justifyContent: 'center' }}>
                            <img style={{ borderRadius: '33px', width: '100%', height: '530p', maxWidth: '530px' }} src={prodImg}></img>
                        </div>
                        <div className="planWidth" style={{ width: '60%', minHeight: '530px', display: 'flex', position: 'relative' }}>
                            <div style={{ position: 'relative' }}>
                                <div className='textSpacing collosalText32 boldText redText'>{selectedPlanDetails.productName}</div>
                                <div className='textSpacing collosalText boldText'>{selectedPlanDetails.SIM}</div>
                                <div className='smallText'>{selectedPlanDetails.planType}</div>
                                <br />
                                <hr style={{ opacity: 0.2 }} />
                                { screenWidth > 600 ? '' : <br /> }
                                <div className='collosalText boldText'>{selectedPlanDetails.internetAmount}</div>
                                <br />
                                <div>
                                    {selectedPlanDetails.infoList.map((item) => {
                                        return <div className='smallText'>
                                            <span className='blueText'>
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/images_icons/icon/tick.svg`}></img>
                                            </span>
                                            &nbsp;{item.infoText}<br /><br />
                                        </div>
                                    })
                                    }
                                </div>

                                <hr style={{ opacity: 0.2 }} />
                                <br />
                                <div className='smallText doubleLineHeight' style={{ marginTop: screenWidth > 600 ? '0px' : '20px' }}>{selectedPlanDetails.planDescription}</div>
                                <br />
                                <div>
                                    <div className='largeText boldText'><br /></div>
                                    <div className='collosalText32 boldText blueText'><br /><span className='collosalText'> </span> <span className='mediumText'> </span></div>
                                </div>
                            </div>

                            <div style={{ position: 'absolute', bottom: 0 }}>
                                <div className='largeText boldText'>{selectedPlanDetails.included}</div>
                                {selectedPlanDetails.additionalPriceInfo1 != "" ?
                                    <div className='collosalText32 boldText blueText'>
                                        <span className='collosalText boldText'>RM</span>
                                        {(selectedPlan.amount.slice(-3) == ".00") ? selectedPlan.amount.slice(0, -3) : selectedPlan.amount}
                                        <span className='mediumText boldText'> / mth</span>
                                        <span className='collosalText boldText'> + RM</span>
                                        {selectedPlanDetails.additionalPriceInfo1}
                                        <span className='mediumText boldText'>&nbsp;&nbsp;({selectedPlanDetails.additionalPriceInfo2})</span>
                                    </div>
                                    :
                                    <div className='collosalText32 boldText blueText'>
                                        <span className='collosalText boldText'>RM</span>
                                        {(selectedPlan.amount.slice(-3) == ".00") ? selectedPlan.amount.slice(0, -3) : selectedPlan.amount}
                                        <span className='mediumText boldText'> / mth</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className='boxPadding' style={{ backgroundColor: 'white', paddingTop: '70px', paddingBottom: '60px', display: 'flex', flexDirection: 'column' }}>
                    <div className='textSpacing collosalText32 boldText'>Get Your Preferred Mobile Number</div>
                    {/* <div className='mediumText'>Search up your preferred last 4 digits for your new mobile number.</div> */}
                    <div className='row' style={{ opacity: numberRefreshing ? 0.5 : 1, justifyContent: 'space-between', marginTop: screenWidth > 600 ? '50px' : '10px' }}>
                        {renderMobileNo()}
                    </div>

                    <div className='mediumText' style={{ textAlign: 'center', margin: '60px 0 10px 0' }}>Can’t find your desired number? {screenWidth > 600 ? '' : <br/>}<span className='clickable blueText blueHoverText' onClick={() => { refreshNumber() }}>Click here to refresh</span></div>

                    {refreshError ?
                        <div className='mediumText redText' style={{ textAlign: 'center', margin: '10px 0 0px 0' }}>Error occured. Please try again.</div>
                        :
                        null
                    }

                    <button className={(selectedMobileNumber.length > 5 ? 'clickable' : 'disabledGrayBtn') + ' blueBG blueHoverBG whiteText semiBoldText largeText mobileHeight50'}
                        type="button"
                        onClick={() => {
                            if (selectedMobileNumber.length > 5)
                                navigate('/xoxwifi/checkout', { state: { selectedPlanId: selectedPlanId, selectedMobileNumber: selectedMobileNumber } });
                        }}
                        style={styles.continueBtn}>Continue</button>
                </div>
            </div>
            :
            loading ?
                <div className='montserrat innerContent'>
                    <div className='boxPadding' style={{ marginTop: '20px', textAlign: 'center' }}>
                        <Loader type="TailSpin" />
                    </div>
                </div>
                :
                <div className='montserrat innerContent' style={{ backgroundColor: '#F8F8F8', ...styles.bg }}>
                    <div className='boxPadding' style={{ textAlign: 'center' }}>
                        <div className='collosalText40 boldText' style={{ margin: '10% 0' }}>Plan Not Found</div>
                        <button onClick={() => { window.location.href = '/xoxwifi'; }} className='whiteText clickable largeText semiBoldText mobileHeight40 blueBG' style={{ borderRadius: '30px', border: '0px', maxWidth: '60%', width: '220px', height: '50px', marginBottom: '30px' }}>Back to Home</button>
                    </div>
                </div>

    )
}


const backgroundImageURL = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Order%20confirmation.png`;

const styles = {
    bg: {
        backgroundImage: `url(${backgroundImageURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%'
    },

    continueBtn: {
        border: '0',
        borderRadius: '30px',
        height: '60px',
        width: '80%',
        maxWidth: '320px',
        margin: 'auto',
        marginTop: '60px'
    }
};
export default RouteWithParam(SuperHOC(Plan))
import React from 'react';
import { useParams } from 'react-router-dom';

function RouteWithParam(Component) {
  return function WrappedComponent(props) {
    const params = useParams();
    return <Component {...props} params={params} />;
  };
}

export default RouteWithParam;
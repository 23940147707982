import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import _styles from '../../_styles.css';
import { SuperHOC, RouteWithParam } from "../../HOC";
import Loader from 'react-loader-spinner'
import { IoIosArrowForward } from "react-icons/io";
import Cookies from 'js-cookie';
import { IoSearchOutline } from "react-icons/io5";
import Alert from '../../animation/alert.json'
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';

function CheckBalance(props) {
    const location = useLocation();
    const tacInputRefs = useRef([]);
    const scrollToPlanInfoRef = useRef(null);
    const scrollToContinueBtnRef = useRef(null);
    const scrollToPayBillRef = useRef(null);
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [amountValue, setAmountValue] = useState("0.00");
    const [amountError, setAmountError] = useState("");
    const [mobileNumberValue, setMobileNumberValue] = useState("");//zheli
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
    const [mobileErrorMessage, setMobileErrorMessage] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [windowPopUp, setWindowPopUp] = useState(0);
    const [tacValues, setTacValues] = useState(['', '', '', '', '', '']);
    const [secondsLeft, setSecondsLeft] = useState(0);
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [targetDestination, setTargetDestination] = useState('');
    const [userInfo, setUserInfo] = useState({ show: false });
    const [timerRunning, setTimerRunning] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!(location.state && location.state.mobileNumber)) {
        } else {
            setMobileNumberValue(location.state.mobileNumber);
            validateMobileNumber(location.state.mobileNumber);
            location.state.mobileNumber = null;
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        let intervalId;

        if (timerRunning) {
            intervalId = setInterval(() => {
                setSecondsLeft((prevTime) => {
                    if (prevTime - 1 < 0) stopTimer();
                    return prevTime - 1
                });
            }, 1000);
        } else {
            clearInterval(intervalId);
        }

        return () => {
            clearInterval(intervalId);
        }
    }, [timerRunning])

    const checkEmail = (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (value == '') {
            setEmailError('Email cannot be empty.');
            return false;
        }
        else if (!emailRegex.test(value)) {
            setEmailError('Email format is invalid.');
            return false;
        }
        else {
            setEmailError('');
            return true;
        }
    }

    const scrollToElement = (scrollToRef) => {
        scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleOtpChange = (index, value) => {
        const newOtpValues = [...tacValues];
        newOtpValues[index] = value;
        setTacValues(newOtpValues);

        if (value !== '' && index < 5) {
            tacInputRefs[index + 1].focus();
        }
    };

    const startTimer = () => {
        setSecondsLeft(120);
        setTimerRunning(true);
    };

    const stopTimer = () => {
        setTacValues(['', '', '', '', '', '']);
        setTimerRunning(false);
        setSecondsLeft(-1)
        return 0;
    };


    const handleResize = () => {
        setScreenWidth(window.innerWidth)
    };

    const handleMobileNumberChange = (event) => {
        const value = event.target.value;

        const digitRegex = /^\d+$/;

        if (value != '' && !digitRegex.test(value)) {
            return false;
        }

        setMobileNumberValue(value);
        checkMobileNumber(value);

    }
    const handleAmountChange = (event) => {
        const { key } = event;

        if (!/^\d$/.test(key) && key != 'Backspace') {
            event.preventDefault();
            return false;
        }

        let [integerPart, decimalPart] = amountValue.split('.');

        var combined;
        if (key == 'Backspace') {
            combined = integerPart.slice(0, -1) + '.' + integerPart.slice(-1) + decimalPart.slice(0, -1);
            combined = combined[0] == '.' ? ('0' + combined) : combined;
        }
        else {
            integerPart = integerPart[0] == '0' ? integerPart.slice(1) : integerPart;
            combined = integerPart + (decimalPart.slice(0, 1) + '.' + decimalPart.slice(1) + key)
        }

        if (parseFloat(combined) >= 10000) combined = '9999.99';

        setAmountValue(combined);
        checkAmountValue(combined);
    }

    const checkAmountValue = (value) => {
        if (value == '') {
            setAmountError('Amount cannot be empty.');
            return false;
        } else if (parseFloat(value) <= 0) {
            setAmountError('Amount cannot be 0.');
            return false;
        } else {
            setAmountError('');
            return true;
        }
    }

    const checkSelectedPaymentMethod = () => {
        if (selectedPaymentMethod != 2 && selectedPaymentMethod != 999) {
            setSelectedPaymentMethodError('Please select payment method.');
            return false;
        } else {
            setSelectedPaymentMethodError('');
            return true;
        }
    }

    const handleEmailChange = (event) => {
        var value = event.target.value;

        setEmail(value);
        checkEmail(value);

    }

    const checkMobileNumber = (value) => {
        if (value == '') {
            setMobileErrorMessage('Mobile Number cannot be empty.');
            return false;
        }
        else if (value.length < 9 || value.length > 10) {
            setMobileErrorMessage('Mobile Number is invalid.');
            return false;
        }
        else if (value[0] == '0') {
            setMobileErrorMessage('Mobile Number should not start with 0.');
            return false;
        }
        else {
            setMobileErrorMessage('');
            return true;
        }
    }

    const formatDate = (inputDate) => {
        // Parse the input date string
        const dateObj = new Date(inputDate);

        // Format the date
        const day = dateObj.getDate();
        const monthIndex = dateObj.getMonth();
        const year = dateObj.getFullYear();

        // Array of month names
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Format the date string
        const formattedDate = `${day} ${months[monthIndex]} ${year}`;

        return formattedDate;
    }

    const validateMobileNumber = (defaultMSISDN) => {
        setIsLoading1(true);
        props.apiManager.makeCall('mobileWifi/validateMSISDN', { msisdn: '0' + (defaultMSISDN ? defaultMSISDN : mobileNumberValue) }, null, (res) => {
            if (res && res.is_success === true && res.data) {
                var responseData = res.data;
                if (responseData.plan == "WIFI_PREMIUM") responseData.plan = "WiFi PREMIUM FREEDOM";
                if (responseData.plan == "WIFI_PLUS") responseData.plan = "WiFi PLUS FREEDOM";
                if (responseData.plan == "Wifi Plus") responseData.plan = "WiFi PLUS LITE";
                if (responseData.plan == "Wifi Premium") responseData.plan = "WiFi PREMIUM LITE";

                if (responseData.dataBal && responseData.dataBal.includes('MB')) {
                    responseData.dataBal.replace('MB', '');
                    var parsedBal = parseInt(responseData.dataBal.replace('MB', ''))
                    responseData.dataBal = ((parseFloat(parsedBal / 1024)).toFixed(3)).slice(0, -1) + 'GB';
                }

                responseData.dueDate = formatDate(responseData.dueDate);

                if (parseFloat(responseData.amountDue) > 0) {
                    let [integerPart, decimalPart] = ('' + responseData.amountDue).split('.');

                    if (!decimalPart || decimalPart == "") responseData.amountDue += ".00";
                    else if (decimalPart.length < 2) responseData.amountDue += "0";

                    setAmountValue(responseData.amountDue);
                }

                responseData.amountDue = ('RM ' + (parseFloat(responseData.amountDue)).toFixed(2));

                responseData.originalFormatMsisdn = responseData.msisdn;
                responseData.msisdn = '+6' + responseData.msisdn.slice(0, 3) + '-' + responseData.msisdn.slice(3, 6) + ' ' + responseData.msisdn.slice(6)

                responseData.status = responseData.status == 'activated' ? 'Active' : responseData.status;

                responseData.show = true;

                setUserInfo(responseData);
                scrollToElement(scrollToPlanInfoRef);
                setMobileErrorMessage('');
            } else if (res && res.is_success === false) {
                window.scrollTo(0, 0);
                setUserInfo({ show: false });
                setMobileErrorMessage('Sorry, the mobile number you’ve entered is invalid.');
            } else {
                window.scrollTo(0, 0);
                setUserInfo({ show: false });
                setMobileErrorMessage('Encountered error. Please try again.');
            }
            setIsLoading1(false);
        });
    }

    const renderRefresh = (message) => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Alert,
            renderer: 'svg'
        }
        return (

            <div className='whiteBG fixedPadding_largeScreen' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', width: '470px', maxWidth: '90%', borderRadius: '8px' }}>
                <Lottie
                    options={defaultOptions}
                    style={{ width: '150px' }}
                />
                <div className='semiBoldText collosalText' style={{ fontSize: '24px', margin: '0 0 19px 0' }}>Oops!</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>{message}</div>
                <div className='row' style={{ marginTop: '52px', gap: '5px' }}>
                    <button type='button' onClick={() => { setWindowPopUp(0) }} className='clickable whiteText lightgrayBG semiBoldText largeText whiteText mobileHeight50' style={{ margin: '0 auto 10px auto', ...styles.btnShape, maxWidth: '0px' }}>Cancel</button>
                    <button type='button' onClick={() => { window.location = '/xoxwifi/checkbalance' }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: '0 auto 10px auto', ...styles.btnShape, maxWidth: '0px' }}>Try Again</button>
                </div>
            </div>

        )
    }

    const payBillOrder = () => {
        var noError = true;
        noError = checkAmountValue(amountValue) && noError;
        noError = checkEmail(email) && noError;
        noError = checkSelectedPaymentMethod() && noError;
        if (noError) {
            var payload = {
                actionKey: 300,
                email: email,
                amount: amountValue,
                paymentId: selectedPaymentMethod,
                msisdn: userInfo.originalFormatMsisdn
            }

            setWindowPopUp(5);
            props.apiManager.makeCall('payment/createOrderMobileWifi', payload, null, (res) => {
                if (res && res.message == 1051 && res.url && res.orderId) {
                    var orderInfo = {
                        orderId: res.orderId,
                        email: email
                    }
                    setWindowPopUp(3);
                    var redirect = res.url + '?callback_url=' + window.location.origin + '/xoxwifi/order_confirmation/' + btoa(JSON.stringify(orderInfo));
                    window.location = redirect;
                } else {
                    setWindowPopUp(4);
                }
            });
        } else {
            scrollToElement(scrollToPayBillRef);
        }
    }

    const verifyOtp = () => {
        if (secondsLeft <= 0) return false;

        setIsLoading2(true);
        var combinedTAC = tacValues[0] + tacValues[1] + tacValues[2] + tacValues[3] + tacValues[4] + tacValues[5];
        props.apiManager.makeCall('mobileWifi/verifyTac', { msisdn: userInfo.originalFormatMsisdn, otp: combinedTAC }, null, (res) => {
            if (res.success) {
                Cookies.set('token', res.token, { expires: 1 / 48 });
                window.location.href = targetDestination;
            } else {
                if (res.message && res.message == 7039) {
                    setOtpErrorMessage('Invalid OTP');
                }
                if (res.message && res.message == 6113) {
                    setOtpErrorMessage('OTP Expired');
                } else {
                    setOtpErrorMessage('Invalid OTP');
                }
            }

            setIsLoading2(false);
        });
    }

    const requestOtp = () => {
        setOtpErrorMessage('');
        if (userInfo.originalFormatMsisdn) {
            setIsLoading2(true)
            setWindowPopUp(1);
            props.apiManager.makeCall('mobileWifi/requestTac', { msisdn: userInfo.originalFormatMsisdn }, null, (res) => {
                if (res.success) {
                    startTimer();
                } else {
                    if (res.message && res.message == 1002) {
                        setOtpErrorMessage('Invalid MSISDN.');
                    } else if (res.message && res.message == 6107) {
                        setOtpErrorMessage('OTP exceeded. Retry in 10 minutes.');
                        setSecondsLeft(-1)
                    } else {
                        setOtpErrorMessage('Failed to send OTP, please retry.');
                        setSecondsLeft(-1)
                    }

                }

                setIsLoading2(false)
            });
        }
    }

    const renderOTPVerification = () => {
        {
            return (
                <div className='whiteBG horizontalMinPadding20_800px verticalMinPadding20_800px' style={{ margin: '0 0', position: 'relative', textAlign: 'center', padding: '3%', maxWidth: '90%', borderRadius: '8px' }}>
                    <div onClick={() => { setWindowPopUp(0) }} className='closeBtn lightgrayText collosalText clickable'>
                        <img style={{ paddingTop: '5px' }} size={25} color='#6E6D7A' src={`${process.env.PUBLIC_URL}/assets/images/images_icons/icon/close.svg`}></img>
                    </div>
                    <div className='semiBoldText collosalText' style={{ marginBottom: '20px' }}>Security Verification</div>
                    <div className='lightgrayText smallText'>We’ve sent a verification code by email to</div>
                    <br/>
                    <div className='semiBoldText largeText' style={{ marginBottom: '11px' }}>{userInfo.email}</div>
                    <div className='lightgrayText smallText'>Please enter the 6-digit verification code below.</div>
                    <div className='row' style={{ justifyContent: 'center', marginTop: '40px' }}>
                        {tacValues.map((value, index) => (
                            <input
                                className='tacInput collosalText30 semiBoldText'
                                key={index}
                                ref={(el) => (tacInputRefs[index] = el)}
                                type="text"
                                disabled={secondsLeft > 0 ? false : true}
                                maxLength={1}
                                value={tacValues[index]}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    if (/^\d*$/.test(newValue) || newValue === '') {
                                        handleOtpChange(index, newValue);
                                    }
                                }}

                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace' && tacValues[index] === '') {
                                        e.preventDefault();
                                        if (index > 0 && tacInputRefs[index - 1]) {
                                            tacInputRefs[index - 1].focus();
                                        }
                                    }
                                }}
                            />
                        ))}
                    </div>
                    <div className='lightgrayText smallText' style={{ textAlign: 'right', marginTop: '17px', marginBottom: '30px' }}>
                        <div className='row' style={{ justifyContent: 'right', height: '25px' }}>

                            <div className='blackText smallText'> {(secondsLeft >= 0 || (otpErrorMessage != '' && otpErrorMessage != 'Invalid OTP')) ? otpErrorMessage : 'Didn\'t receive code?'}</div>
                            {secondsLeft >= 0 ?
                                <span style={{ marginLeft: '5px' }}>Code expires in <span className='redText'>0{Math.trunc(secondsLeft / 60)}:{secondsLeft % 60 < 10 ? ('0' + secondsLeft % 60) : secondsLeft % 60}</span></span>
                                :
                                <div style={{ marginLeft: '5px' }} className='redText redHoverText semiBoldText clickable' onClick={() => { requestOtp() }}>
                                    Resend OTP
                                </div>
                            }
                        </div>
                            {isLoading2 ? <Loader type="TailSpin" style={{marginTop:'3px'}} width={25} height={25} /> : <div style={{height:'28px'}}/>}



                    </div>
                    <button type='button' 
                    onClick={() => { verifyOtp() }} 
                    disabled={(secondsLeft > 0 && !tacValues.includes('')) ? false : true}
                    className={'whiteText blueBG semiBoldText largeText mobileHeight50 ' + ((secondsLeft > 0 && !tacValues.includes('')) ? 'blueHoverBG clickable' : '')} 
                    style={{ margin: 'auto', marginBottom: '30px', ...styles.btnShape, backgroundColor: (secondsLeft > 0 && !tacValues.includes('')) ? '#372EA8' : 'lightgray' }}
                    >Verify</button>
                    <div className='clickable blueText blueHoverText smallText semiBoldText' onClick={() => setWindowPopUp(2)} style={{ width: 'fit-content', margin: 'auto' }}>Change Email</div>
                </div>
            )
        }
    }

    const renderChangeEmail = () => {
        {
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: Alert,
                renderer: 'svg'
            }
            return (
                <div className='whiteBG mobileMediumPadding' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '12.5px 50px 30px 50px', maxWidth: '90%', borderRadius: '8px' }}>
                    <Lottie
                        options={defaultOptions}
                        style={{ width: '150px' }}
                    />
                    <div className='semiBoldText collosalText' style={{ fontSize: '24px', marginBottom: '15px' }}>Change Email Address</div>
                    <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>To help keep your account safe, we want to make sure it’s really you.<br />Please call our customer service at 1300888010 for assistance.</div>

                    <button type='button' onClick={() => { setWindowPopUp(1) }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: 'auto', marginTop: '50px', marginBottom:'20px', ...styles.btnShape }}>Okay</button>
                </div>
            )
        }
    }

    const renderPayBill = () => {
        return (
            <div className='whiteBG horizontalMinPadding20_800px verticalMinPadding20_800px popupWindowMaxHeight' style={{ margin: '0 5px', overflowY: 'auto', position: 'relative', textAlign: 'center', padding: '1.5%', maxWidth: '530px', borderRadius: '8px' }}>
                <div onClick={() => { setWindowPopUp(0) }} className='closeBtn lightgrayText collosalText clickable'>
                        <img style={{ paddingTop: '5px' }} size={25} color='#6E6D7A' src={`${process.env.PUBLIC_URL}/assets/images/images_icons/icon/close.svg`}></img>
                    </div>
                <div className='semiBoldText collosalText' style={{ marginTop: '5px', fontSize: '22px' }}>Pay Bill</div>
                <div className='lightpurpleBG' style={{ marginTop: '10px', borderRadius: '10px', textAlign: 'left', padding: '5% 5% 0 5%' }}>
                    <div className='row' style={{ justifyContent: 'space-between' }}>
                        <div ref={scrollToPayBillRef} className='smallText grayText' style={{width:'50%'}}>Pay to</div>
                        <div className='smallText grayText' style={{textAlign:'left',width:'50%'}}>Amount</div>
                    </div>
                    <div className='row' style={{ justifyContent: 'space-between', marginTop: '8px' }}>
                        <div className='largeText boldText' style={{width:'50%'}}>{userInfo.msisdn}</div>
                        <div className='row' style={{ width: '50%', justifyContent: 'space-between' }}>
                            <div className='boldText largeText' style={{ width: '20%' }}>RM</div>
                            <input className={'smallText whiteBG boldText largeText inputField ' + (amountError ? 'redBorderFocus' : '')}
                                onKeyDown={(event) => { handleAmountChange(event); }} value={amountValue} style={{ textAlign: 'right', width: '70%', height: '39px', paddingRight: '3%' }} placeholder='0.00' type='text' />
                        
                        </div>
                    </div>
                    <div className='row' style={{ justifyContent: 'space-between' }}>
                        <div className='smallText grayText'></div>
                        <div className='orangeText smallText' style={{ textAlign: 'right', height: '15px', marginTop: '5px' }}>{amountError}</div>
                   
                    </div>
                    <hr style={{ opacity: '0.3', marginBottom: '10px' }} />
                    <div className='row'>
                        <div className='grayText smallText textSpacing' style={{ marginTop: '0px' }}>Payer Email Address</div>
                        <input className={'smallText whiteBG inputField ' + (emailError ? 'redBorderFocus' : '')} value={email} onChange={(event) => { handleEmailChange(event); }} style={{ width: '95%', padding: '12px 0 12px 5%' }} placeholder='Email Address' type='text' />
                    </div>
                    <div className='orangeText smallText' style={{ height: '20px', marginTop: '10px' }}>{emailError}</div>

                    <div className='grayText smallText textSpacing' style={{ marginTop: '0px' }}>Payment Method</div>
                    <div className='row' style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                        <div style={{ width: '49%' }}>
                            <div
                                onClick={() => { setSelectedPaymentMethod(999); setSelectedPaymentMethodError('') }}
                                className={'clickable whiteBG smallText inputField row ' + (selectedPaymentMethod != 999 ? 'blueHoverBorder blueHoverText' : 'selectedtoggleSelectionDiv')}
                                style={{ width: '98%', height: '45px', justifyContent: 'center', border: '1px solid transparent' }} >
                                Online Banking
                            </div>
                        </div>
                        <div style={{ width: '49%' }}>
                            <div className={'clickable whiteBG smallText inputField row ' + (selectedPaymentMethod != 2 ? 'blueHoverBorder blueHoverText' : 'selectedtoggleSelectionDiv')}
                                onClick={() => { setSelectedPaymentMethod(2); setSelectedPaymentMethodError('') }}
                                style={{ width: '98%', height: '45px', justifyContent: 'center', border: '1px solid transparent' }} >
                                Credit / Debit Card
                            </div>
                        </div>
                    </div>
                    <div className='orangeText smallText' style={{ height: '30px', marginTop: '10px' }}>{selectedPaymentMethodError}</div>
                </div>
                <div className='smallText13 lightgrayText' style={{ textAlign: 'left', marginTop: '10px', maxWidth: '430px' }}>By proceeding, I acknowledge that I have read, understood and agreed to the website’s <a href={`${process.env.PUBLIC_URL}/assets/T&C/[T&C] XOX WiFi.pdf`} target='_blank' className='boldText' style={{ ...styles.anchorBtn }}>Terms and Conditions</a> & <a href='https://xox.com.my/privacystatement/details/' target='_blank' className='boldText' style={{ ...styles.anchorBtn }}>Privacy Policy</a>. XOX Media may store your personal info for internal analysis use or related activities.</div>
                <button ref={scrollToContinueBtnRef} onClick={() => { payBillOrder() }} type='button' className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight40' style={{ margin: 'auto', marginTop: '10px', ...styles.btnShape, height:'50px' }}>Continue</button>
            </div>
        )
    }

    return (
        <div className={'montserrat innerContent noPaddingTop'} style={screenWidth > 1200 ? { paddingBottom: '0', backgroundColor: '#F8F8F8' } : { ...styles.checkBalDivMobile }}>
            <div className={windowPopUp == 0 ? 'noneDisplay' : 'popupWindowBG'}>
                {windowPopUp == 1 ? renderOTPVerification() : null}
                {windowPopUp == 2 ? renderChangeEmail() : null}
                {windowPopUp == 3 ? renderPayBill() : null}
                {windowPopUp == 4 ? renderRefresh('Failed to pay bill. Please try again.') : null}
                {windowPopUp == 5 ? <Loader type="TailSpin" width={50} height={50} /> : null}
            </div>
            <div className='boxPadding navigationRow' style={screenWidth > 1200 ? { ...styles.checkBalDiv } : { paddingBottom: '20px' }}>
                <div className='blackText montserrat backBtn row poppins smallText'>
                    <a href={`${process.env.PUBLIC_URL}/`} className='blueHoverText' style={{ color: 'black', width: 'fit-content', textDecoration: 'none' }} >
                        Home
                    </a>

                    <IoIosArrowForward style={{ paddingBottom: '0px' }} />
                    Check Balance
                </div>
                <div className='collosalText42 boldText' style={{ marginTop: screenWidth > 600 ? '20vh' : '10vh', paddingBottom: screenWidth > 600 ? '18px' : '15px' }}>Check Balance</div>
                <div className='row'>
                    <div className='row mobileWidth100 mobileSmallPaddingLeft mobileSmallPaddingRight' style={{ border: mobileErrorMessage ? '1px solid red' : '', width: '49%', borderRadius: '10px', padding: '0px 0 0 30px', backgroundColor: 'white' }}>
                        <div className='largeText' style={{ border: '1px solid lightgray', borderWidth: '0 1px 0 0', padding: ' 5px 10px 5px 0' }}>+60</div>
                        <input
                            onChange={(event) => {
                                handleMobileNumberChange(event);
                            }}
                            value={mobileNumberValue}
                            type='text' maxLength={10} className='largeText mobileSmallPaddingLeft mobileNoMargin' style={{ flex: '1', height: '60px', outline: 'none', border: '0', borderRadius: '0 10px 10px 0', padding: '0 0 0 15px' }} placeholder='Mobile Number' />
                        <IoSearchOutline
                            onClick={() => {
                                if (checkMobileNumber(mobileNumberValue))
                                    validateMobileNumber()
                            }}
                            style={{ display: screenWidth > 700 ? 'none' : 'block' }}
                            size={screenWidth > 400 ? 30 : (screenWidth / 15.3)} />
                    </div>
                    <div style={{ flex: '1', textAlign: screenWidth > 700 ? 'left' : 'center', display: screenWidth > 700 ? 'block' : 'none' }}>
                        <button
                            onClick={() => {
                                if (checkMobileNumber(mobileNumberValue))
                                    validateMobileNumber()
                            }}
                            type='button' className='clickable mobileWidth90 blueBG blueHoverBG semiBoldText largeText whiteText mobileHeight40' style={{ border: '0', borderRadius: '30px', margin: '20px 10px', height: '60px', width: '180px', maxWidth: screenWidth > 800 ? '260px' : '130px' }}>Go</button>
                    </div>
                </div>
                {
                    isLoading1 ?
                        <Loader type="TailSpin" width={25} height={25} style={{ marginTop: '10px' }} />
                        :
                        <div className='orangeText smallText' style={{ height: '29px', marginTop: '5px' }}>{mobileErrorMessage}</div>
                }

                <div className='collosalText boldText' ref={scrollToPlanInfoRef} style={{ fontSize: screenWidth < 400 ? '20px' : '24px', marginTop: screenWidth > 600 ? '20vh' : '10vh' }}>{userInfo.show ? 'Your Plan Subscription' : <br />}</div>
            </div>
            <div className='boxPadding' style={{ display: userInfo.show ? 'block' : 'none' }}>
                <div className='lightpurpleBG' style={{ padding: '5% 5% 0 5%', width: '90%' }}>
                    <div className={screenWidth > 700 ? 'row' : 'row column textAlignCenter'} style={{ justifyContent: 'space-between', gap: '10px' }}>
                        <div style={{ paddingBottom: '5%' }}>
                            <div className='row' style={screenWidth > 700 ? {} : { justifyContent: 'center' }}>
                                <div className='grayText collosalText boldText' style={{ marginBottom: '10px' }}>{userInfo.msisdn}</div>
                            </div>
                            <div className='' style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={userInfo.plan && userInfo.plan.includes('PREMIUM') ? "/assets/images/images_icons/icon/wifi%20premium.svg" : "/assets/images/images_icons/icon/wifi%20plus.svg"} width="50" height="50" />
                                <div className='collosalText28 boldText' style={{ width: 'fit-content' }}>{userInfo.plan}</div>
                                <div className='smallText whiteText semiBoldText' style={{ ...styles.statusLabel, backgroundColor: (userInfo.status == 'Active' ? '#2BB241' : 'red') }}>{userInfo.status}</div>
                            </div>

                            <div className='grayText largeText' style={{ marginBottom: screenWidth > 700 ? '20px' : '0px' }}><br /></div>
                            <button type='button' onClick={() => {
                                setTargetDestination('/xoxwifi/manage_subscription');
                                requestOtp();
                            }} className='clickable blueBG blueHoverBG semiBoldText largeText whiteText mobileHeight40' style={{ border: '0', borderRadius: '30px', width: '220px', height: '50px' }}>Manage Plan</button>
                        </div>

                        <div style={{ paddingBottom: '5%' }}>
                            <div className='grayText largeText' style={{ marginBottom: '10px' }}>Data Balance</div>
                            <div className='collosalText32 boldText' style={{ marginBottom: '10px' }}>{userInfo.dataBal}</div>
                            <div className='grayText largeText' style={{ marginBottom: '20px' }}>Renews on {userInfo.dueDate}</div>
                            <button
                                onClick={() => {
                                    if (userInfo.originalFormatMsisdn)
                                        navigate('/xoxwifi/addOn', { state: { addOnMobileNumber: userInfo.originalFormatMsisdn } });
                                }}
                                className='blueText blueHoverBorder blueHoverText clickable semiBoldText largeText mobileHeight40' style={{ width: '160px', height: '50px', borderRadius: '25px', backgroundColor: 'transparent', border: '1px solid #372EA8' }}>Buy Add-ons</button>
                        </div>

                        <div style={{ paddingBottom: '5%' }}>
                            <div className='grayText largeText' style={{ marginBottom: '10px' }}>Total Amount Due</div>
                            <div className='collosalText32 boldText' style={{ marginBottom: '10px' }}>{userInfo.amountDue}</div>
                            <div className='grayText largeText' style={{ marginBottom: '15px' }}>Due by {userInfo.dueDate}</div>
                            <div className='row' style={{ justifyContent: screenWidth > 700 ? 'left' : 'center', gap: '10px' }}>
                                <button onClick={() => { setWindowPopUp(3) }} type='button' className='blueBG blueHoverBG clickable semiBoldText largeText whiteText mobileHeight40' style={{ marginTop: '5px', border: '0', borderRadius: '30px', width: '160px', maxWidth: '45%', height: '50px' }}>Pay Now</button>
                                <button
                                    onClick={() => {
                                        setTargetDestination('/xoxwifi/manage_subscription');
                                        requestOtp();
                                    }}
                                    className='clickable blueText blueHoverBorder blueHoverText semiBoldText largeText mobileHeight40' style={{ marginTop: '5px', width: '160px', height: '50px', borderRadius: '25px', backgroundColor: 'transparent', width: '160px', maxWidth: '45%', border: '1px solid #372EA8' }}>View History</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const checkBalImage = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Manage%20Check%20Balance.png`;
const checkBalImageMobile = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/ManageCheckBalanceMobile.png`;
const styles = {
    btnShape: {
        alignSelf: 'center',
        borderRadius: '30px',
        height: '60px',
        width: '50%',
        minWidth: '200px',
        border: 0,
    },
    checkBalDiv: {
        backgroundImage: `url(${checkBalImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingBottom: '20px'
    },
    checkBalDivMobile: {
        backgroundImage: `url(${checkBalImageMobile})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingRight: '0px'
    },
    statusLabel: {
        textAlign: 'center',
        width: '50px',
        minWidth: '50px',
        marginLeft: '10px',
        padding: '3px 10px',
        borderRadius: '10px',
    }

};
export default RouteWithParam(SuperHOC(CheckBalance))
import ErrMsg from "./string-error-code-en";
import CryptoJS from "crypto-js";
import config from "./config";
import { constants } from "../config/constants";
import * as Fingerprint2 from "fingerprintjs2";

const URL = (apiName) => {
    return (
        // 'http://localhost:3000/api/' + apiName
        `${process.env.REACT_APP_MIDDLEWARE_URL}/api/${apiName}`
    );

};
const clientId = "";
const clientSecret ="";
const TIMEOUT = 15000; // ten seconds



export default class ApiManager {
    static GET = "GET";
    token = null;
    parent = null;
    sessionId = null;
    oneTimeFlag = 1;

    constructor(parent) {
        this.parent = parent;
    }

    // this function must call before calling makeCall otherwide its not get token from local if its saved
    async Initialize() {
        // await this.assignToken();
    }

    postServer = (apiName, options) => {
        return Promise.race([
            fetch(URL(apiName), options),
            new Promise((_, reject) =>
                setTimeout(
                    () =>
                        reject({
                            error: -1,
                            message: "Timeout with " + TIMEOUT,
                        }),
                    TIMEOUT
                )
            ),
        ]);
    };

    getParams = (apiName) => {
        let apiUrl = URL(apiName);
        return {
            apiUrl,
        };
    };

    translateResponse = (response) => {
        /**
         * statuses
         *  0 -> success
         *  1 ->
         *  2 -> token need
         */
        let status = 0;
        let res = null;

        if (response.token || response.status != undefined || response.errors) {
            return response;
        }

        return response;
    };

    temperedBody = (body) => {
        let tempBody = {};
        let secretData = this.encode(body);
        tempBody.data = secretData;
        // return tempBody;
        return body;
    };

    createLoginOptions = (body, token) => {
        let tempBody = this.temperedBody(body);
        return {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + token,
            },
            credentials: "include",
            body: JSON.stringify(tempBody),
        };
    };

    createLoginOptionsGet = () => {
        return {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "session-id": this.sessionId,
                Authorization: "Bearer " + this.token,
            },
            credentials: "include",
        };
    };

    // create headers for middleware.
    createMiddlewarePaymentOrder = (body) => {
        let tempBody = this.temperedBody(body);
        return {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "session-id": this.sessionId,
                Authorization: "Bearer " + this.token,
            },
            body: JSON.stringify(tempBody),
        };
    };

    createMiddlewarePaymentOrderGet = () => {

        return {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "session-id": this.sessionId,
                Authorization: "Bearer " + this.token,
            },
        };
    };

    createWithOutLoginOptions = (body) => {
        let tempBody = this.temperedBody(body);
        return {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "session-id": this.sessionId,
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(tempBody),
        };
    };

    createWithOutLoginOptionsGet = () => {
        return {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "session-id": this.sessionId,
                "Content-Type": "application/json",
            },
            credentials: "include",
        };
    };

    // assignToken = async () => {
    //     let token = await Manager.getItem("token", false);
    //     this.token = token;
    // };

    setQueryParams = (apiUrl, body) => {
        let tempValue = apiUrl;
        Object.keys(body).map((value, index) => {
            tempValue += "/?" + value + "=" + body[value];
        });
        return tempValue;
    };

    getFetch = (apiName, options) => {
        return Promise.race([
            fetch(apiName, options),
            // fetch(URL(apiName), options),
            new Promise((_, reject) =>
                setTimeout(
                    () =>
                        reject({
                            error: -1,
                            message: "Timeout with " + TIMEOUT,
                        }),
                    TIMEOUT
                )
            ),
        ]);
    };

    get = (apiName, body, callback) => {
        let options = null;

        this.getFetch(this.setQueryParams(URL(apiName), body), options)
            .then(async (res) => {
                res = await res.json();
                res.apiName = apiName;
                callback(res);
            })
            .catch((e) => {
            });
    };

    post = (apiName, body,token, callback) => {
        let options = null;

        
        let tempBody = this.temperedBody(body);
        options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(tempBody),
        };

        this.postServer(apiName, options)
            .then(async (res) => {
                res = await res.json();
                res.apiName = apiName;

                let response = this.translateResponse(res);

                callback(response);
            })
            .catch((e) => {
                callback(e)
            });
    };

    getIp = async () => {
    };

    getDeviceId = () => {
        return new Promise((resolve, reject) => {
            if (window.requestIdleCallback) {
                requestIdleCallback(() => {
                    Fingerprint2.get((components) => {
                        let values = components.map((com) => {
                            return com.value;
                        });
                        let device_id = Fingerprint2.x64hash128(
                            values.join(""),
                            31
                        );
                        resolve(device_id);
                    });
                });
            } else {
                setTimeout(() => {
                    Fingerprint2.get((components) => {
                        let values = components.map((com) => {
                            return com.value;
                        });
                        let device_id = Fingerprint2.x64hash128(
                            values.join(""),
                            31
                        );
                        resolve(device_id);
                    });
                }, 500);
            }
        });
    };

    getMergedWithTime = (str, milli) => {
        let strMerged = "";
        for (let i = 0; i < milli.length; i++) strMerged += str[i] + milli[i];
        strMerged += str.substr(milli.length);
        return strMerged;
    };

    encode = (body) => {
        let msg = JSON.stringify(body);
        let key = Date.now().toString();
        let decrptedMsg = CryptoJS.AES.encrypt(msg, key).toString();
        let getMergedWithTime = this.getMergedWithTime(decrptedMsg, key);
        return getMergedWithTime;
    };

    async makeCall(apiName, body, token,callback) {
        body.ip = "IP";
        body.mac = "ID";
        this.post(apiName, body, token,(res) => {
            callback(res);
        });
    }
}

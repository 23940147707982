import React, { useState, useCallback, Component } from 'react';
import _styles from '../_styles.css';
import LogoImage from './common/logo';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaFacebookF,FaInstagram,FaTwitter,FaLinkedinIn,FaYoutube } from "react-icons/fa";
import { FaBars } from 'react-icons/fa';
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';

function Footer(props) {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         screenWidth: window.innerWidth,
    //     };
    // }

    // componentDidMount() {
    //     window.addEventListener('resize', this.handleResize);
    // }

    // componentWillUnmount() {
    //     window.removeEventListener('resize', this.handleResize);
    // }

    // handleResize = () => {
    //     this.setState({
    //         screenWidth: window.innerWidth,
    //     });
    // };
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
        
    const [aboutUs, setAboutUs] = useState(false);
    const [products, setProducts] = useState(false);
    const [terms, setTerms] = useState(false);
    const [support, setSupport] = useState(false);

    const toggleAboutUs = useCallback(() => {
        setAboutUs((aboutUs) => !aboutUs);
    }, []);

    const toggleProducts = useCallback(() => {
        setProducts((products) => !products);
    }, []);

    const toggleTerms = useCallback(() => {
        setTerms((terms) => !terms);
    }, []);

    const toggleSupport = useCallback(() => {
        setSupport((support) => !support);
    }, []);

    return(
            screenWidth > 600
                ? (
                    <div className='montserrat smallText' style={{textAlign:'left'}}>
                    <div className='row' style={{backgroundColor:'#F2F2F2',padding:'0px 25px 0px 25px',alignItems:'flex-start'}}>
                        <div className='row container' style={{padding:'50px',justifyContent:'space-between',flex:'1',alignItems:'flex-start'}}>
                            <div className='footerItem'>
                                <div className='footerBoldItem extraBoldText'>About Us</div>
                                <div><a href="https://xox.com.my/">Home</a></div>
                                <div><a href="https://xox.com.my/who-are-we/">Who Are We</a></div>
                                <div><a href="https://xox.com.my/board-of-directors/">Board of Directors</a></div>
                                <div><a href="https://xox.com.my/investor-relations">Investor Relations</a></div>
                                <div><a href="https://xox.com.my/career/">Careers</a></div>
                            </div>
                            <div className='footerItem'>
                                <div className='footerBoldItem extraBoldText'>Products & Services</div>
                                <div><a href="https://xox.com.my/telecommunications/">ONEXOX</a></div>
                                <div><a href="https://xox.com.my/fintech/">XOX Wallet</a></div>
                                <div><a href="https://xox.com.my/fintech/">XOX PayG</a></div>
                                <div><a href="https://xox.com.my/black-market/">BLACK MARKET</a></div>
                                <div><a href="https://xox.com.my/adx/">ADX</a></div>
                                <div><a href="https://xox.com.my/trax/">TraX</a></div>
                                <div className='exceedListing'><a href="https://xox.com.my/xox-city/">XOX CITY</a></div>
                                <div className='exceedListing'><a href="https://xox.com.my/xox-cloud/">XOX Cloud</a></div>
                                <div className='exceedListing'><a href="https://xox.com.my/space-x/">spaceX</a></div>
                                <div className='exceedListing'><a href="https://xox.com.my/nimbus/">Nimbus</a></div>
                                <div className='exceedListing'><a href="https://xox.com.my/sports/">Sports</a></div>
                                <div className='exceedListing footerBoldItem extraBoldText'><a href={`${process.env.PUBLIC_URL}/xoxwifi`}><b>XOX WiFi</b></a></div>
                            </div>
                            <div className='footerItem doubleListing'>
                                <div className='footerBoldItem extraBoldText'><br/></div>
                                <div><a href="https://xox.com.my/xox-city/">XOX CITY</a></div>
                                <div><a href="https://xox.com.my/xox-cloud/">XOX Cloud</a></div>
                                <div><a href="https://xox.com.my/space-x/">spaceX</a></div>
                                <div><a href="https://xox.com.my/nimbus/">Nimbus</a></div>
                                <div><a href="https://xox.com.my/sports/">Sports</a></div>
                                <div className='footerBoldItem extraBoldText'><a href={`${process.env.PUBLIC_URL}/xoxwifi`}><b>XOX WiFi</b></a></div>
                            </div>
                            <div className='footerItem'>
                                <div className='footerBoldItem extraBoldText'>Terms & Policies</div>
                                <div><a href="https://xox.com.my/terms-conditions/">Terms & Conditions</a></div>
                                <div><a href="https://xox.com.my/privacystatement/details/">Privacy Notice</a></div>
                                <div><a href="https://xox.com.my/scam-alert/">Scam Alert</a></div>
                                <div className='footerBoldItem extraBoldText' style={{marginTop:'3em'}}>Support</div>
                                <div><a href="https://xox.com.my/contact-us/">Contact Us</a></div>
                            </div>
                            <div className='footerItem doubleListing'>
                                <br/>
                            </div>
                            <div className='footerItem'>
                                <div className='footerBoldItem extraBoldText'>Connect With Us</div>
                                <div className='row'>
                                    <a style={styles.iconBtn} href="https://www.facebook.com/xoxmalaysia/" target="_blank">
                                        <FaFacebookF/>
                                    </a>
                                    <a style={styles.iconBtn} href="https://www.instagram.com/xoxmalaysia" target="_blank">
                                        <FaInstagram/>
                                    </a>
                                    <a style={styles.iconBtn} href="https://twitter.com/xox_malaysia?lang=en" target="_blank">
                                        <FaTwitter/>
                                    </a>
                                    <a style={styles.iconBtn} href="https://www.linkedin.com/company/xox-bhd/mycompany/" target="_blank">
                                        <FaLinkedinIn/>
                                    </a>
                                    <a style={styles.iconBtn} href="https://www.youtube.com/channel/UChxtgedNAHD-DlKkuGiYFfg" target="_blank">
                                        <FaYoutube/>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{textAlign:'center',padding:'20px 40px',margin:'auto'}}>© 2024 XOX. Com Sdn Bhd 200501027788 (709922-X) XOX MOBILE Sdn Bhd 200801011962 (813250-K) XOX AJL License <br/> Number (AJL 932178). All Rights Reserved.</div>
                    </div>
                ) : (
                    <div className='montserrat smallText' style={{textAlign:'left'}}>
                    <div className='row' style={{backgroundColor:'#F2F2F2',alignItems:'flex-start'}}>
                        <div className='row container' style={{padding:'30px', display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <div class="footerItemBox">
                                <div style={{...styles.footerItem}} onClick={toggleAboutUs}>
                                    <div>About Us</div>
                                    <div>
                                        { aboutUs == false? (
                                                <IoIosArrowDown />
                                            ) : (
                                                <IoIosArrowUp />
                                            ) 
                                        }
                                    </div>
                                </div>
                                <div style={{ ...styles.footerCollapse, height: aboutUs ? "auto" : "0px" }}>
                                    <div><a href="https://xox.com.my/">Home</a></div>
                                    <div><a href="https://xox.com.my/who-are-we/">Who Are We</a></div>
                                    <div><a href="https://xox.com.my/board-of-directors/">Board of Directors</a></div>
                                    <div><a href="https://xox.com.my/investor-relations">Investor Relations</a></div>
                                    <div><a href="https://xox.com.my/career/">Careers</a></div>
                                </div>
                            </div>
                            <div class="footerItemBox">
                                <div style={{...styles.footerItem}} onClick={toggleProducts}>
                                    <div>Products & Services</div>
                                    <div>
                                        { products == false? (
                                                <IoIosArrowDown />
                                            ) : (
                                                <IoIosArrowUp />
                                            ) 
                                        }
                                    </div>
                                </div>
                                <div className="collapse" style={{ ...styles.footerCollapse,height: products ? "auto" : "0px" }}>
                                    <div><a href="https://xox.com.my/telecommunications/">ONEXOX</a></div>
                                    <div><a href="https://xox.com.my/fintech/">XOX Wallet</a></div>
                                    <div><a href="https://xox.com.my/fintech/">XOX PayG</a></div>
                                    <div><a href="https://xox.com.my/black-market/">BLACK MARKET</a></div>
                                    <div><a href="https://xox.com.my/adx/">ADX</a></div>
                                    <div><a href="https://xox.com.my/trax/">TraX</a></div>
                                    <div className='exceedListing'><a href="https://xox.com.my/xox-city/">XOX CITY</a></div>
                                    <div className='exceedListing'><a href="https://xox.com.my/xox-cloud/">XOX Cloud</a></div>
                                    <div className='exceedListing'><a href="https://xox.com.my/space-x/">spaceX</a></div>
                                    <div className='exceedListing'><a href="https://xox.com.my/nimbus/">Nimbus</a></div>
                                    <div className='exceedListing'><a href="https://xox.com.my/sports/">Sports</a></div>
                                    <div className='exceedListing footerBoldItem extraBoldText'><a href={`${process.env.PUBLIC_URL}/xoxwifi`}><b>XOX WiFi</b></a></div>
                                </div>
                            </div>
                            <div class="footerItemBox">
                                <div style={{...styles.footerItem}} onClick={toggleTerms}>
                                    <div>Terms & Policies</div>
                                    <div>
                                        { terms == false? (
                                                <IoIosArrowDown />
                                            ) : (
                                                <IoIosArrowUp />
                                            ) 
                                        }
                                    </div>
                                </div>
                                <div className="collapse" style={{ ...styles.footerCollapse,height: terms ? "auto" : "0px" }}>
                                    <div><a href="https://xox.com.my/terms-conditions/">Terms & Conditions</a></div>
                                    <div><a href="https://xox.com.my/privacystatement/details/">Privacy Notice</a></div>
                                    <div><a href="https://xox.com.my/scam-alert/">Scam Alert</a></div>
                                </div>
                            </div>
                            <div class="footerItemBox">
                                <div  style={{...styles.footerItem}} onClick={toggleSupport}>
                                    <div>Support</div>
                                    <div>
                                        { support == false? (
                                                <IoIosArrowDown />
                                            ) : (
                                                <IoIosArrowUp />
                                            ) 
                                        }
                                    </div>
                                </div>
                                <div className="collapse" style={{ ...styles.footerCollapse,height: support ? "auto" : "0px" }}>
                                    <div><a href="https://xox.com.my/contact-us/">Contact Us</a></div>
                                </div>
                            </div>
                            <div className='footerItem'>
                                <div className='footerBoldItem extraBoldText' style={{textAlign: 'center'}}>Connect With Us</div>
                                <div style={styles.connectUs}>
                                    <a style={styles.iconBtn} href="https://www.facebook.com/xoxmalaysia/" target="_blank">
                                        <FaFacebookF/>
                                    </a>
                                    <a style={styles.iconBtn} href="https://www.instagram.com/xoxmalaysia" target="_blank">
                                        <FaInstagram/>
                                    </a>
                                    <a style={styles.iconBtn} href="https://twitter.com/xox_malaysia?lang=en" target="_blank">
                                        <FaTwitter/>
                                    </a>
                                    <a style={styles.iconBtn} href="https://www.linkedin.com/company/xox-bhd/mycompany/" target="_blank">
                                        <FaLinkedinIn/>
                                    </a>
                                    <a style={styles.iconBtn} href="https://www.youtube.com/channel/UChxtgedNAHD-DlKkuGiYFfg" target="_blank">
                                        <FaYoutube/>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{textAlign:'center',padding:'20px',margin:'auto'}}>© 2024 XOX. Com Sdn Bhd 200501027788 (709922-X) XOX MOBILE Sdn Bhd 200801011962 (813250-K) XOX AJL License <br/> Number (AJL 932178). All Rights Reserved.</div>
                    </div>
                )
        )
}

const styles ={
    footerItem:{
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 800
    },
    footerCollapse: {
        transitionProperty: 'height',
        transitionDuration: '200ms',
        overflow: 'hidden',
        margin: '0.5em'
    },
    iconBtn: {
        color: 'black',
        border: '1px solid black',
        borderRadius: '50%',
        padding: '5px',
        margin: '0 8px 8px 0',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none'
    },
    connectUs: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    }
};

export default Footer

import { SuperHOC } from "../../HOC";
import React, { useState, useEffect, useRef } from 'react';
import _styles from '../../_TeleStyles.css';
import { useNavigate } from 'react-router-dom';
import BannerSlider from '../../component/common/bannerSliderTele';
import { width } from "@mui/system";

function Home(props) {
    const scrollToRef = useRef(null);
    const navigate = useNavigate();

    const whatsaappNowImg = `${process.env.PUBLIC_URL}/assets/images/tele_icon/whatsappNow.png`;
    const hotOffersPic = `${process.env.PUBLIC_URL}/assets/images/tele_icon/hotOffers.png`;
    const pic5G = `${process.env.PUBLIC_URL}/assets/images/tele_icon/5G.svg`;
    const phoneImage1 = `${process.env.PUBLIC_URL}/assets/images/devices/vivo%20v30%20pro.png`;
    const phoneImage2 = `${process.env.PUBLIC_URL}/assets/images/devices/honor%20magic6pro.png`;
    const phoneImage3 = `${process.env.PUBLIC_URL}/assets/images/devices/vivo%20x100%20pro.png`;
    const tickIcon = `${process.env.PUBLIC_URL}/assets/images/tele_icon/tick.svg`;
    const nowIcon = `${process.env.PUBLIC_URL}/assets/images/tele_icon/now.png`;

    const [bannerImageList] = useState([
        {
            "pic": `${process.env.PUBLIC_URL}/assets/images/tele_banner/XOX5G_Phone%20Bundle%20Final.jpg`,
            "link": "https://onexox.my/Xox-5g-Phone-Bundling"
        },
        {
            "pic":  `${process.env.PUBLIC_URL}/assets/images/tele_banner/XOX%205G%20Website%20Banner-10%203.jpg`,
            "link": "https://wa.me/60378842323"
        },
        {
            "pic": `${process.env.PUBLIC_URL}/assets/images/mobileWifi_banner/XOXWiFiBanner1.jpg`,
            "link": "/xoxwifi"
        }
    ]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [hotOfferList] = useState([
        {
            "phoneName": "VIVO V30 Pro 5G",
            "price": "RM 2,399.00",
            "rpp": "RM2,599.00",
            "save": "Save up to RM200",
            "pic": phoneImage1,
            "link": "https://onexox.my/Xox-5g-Phone-Bundling"
        },
        {
            "phoneName": "HONOR Magic6 Pro 5G",
            "price": "RM 4,199.00",
            "rpp": "RM 4,499.00",
            "save": "Save up to RM300",
            "pic": phoneImage2,
            "link": "https://onexox.my/Xox-5g-Phone-Bundling"
        },
        {
            "phoneName": "VIVO X100 Pro 5G",
            "price": "RM 2,399.00",
            "rpp": "RM2,599.00",
            "save": "Save up to RM200",
            "pic": phoneImage3,
            "link": "https://onexox.my/Xox-5g-Phone-Bundling"
        }

    ]);

    const handleResize = () => {
        setScreenWidth(window.innerWidth)
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const scrollToElement = () => {
        scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const renderHotOffer = () => {
        return (
            screenWidth > 1250
                ? (
                    <div style={styles.hotOffersSection}>
                        <img src={hotOffersPic} alt="Hot Offers" style={styles.hotOffersBanner} />
                        <div className='boldText' style={{ fontSize: '32px', ...styles.hotOffersMainTitleMobile, color: '#29295C' }}>So, we heard you don't like contract and any upfront.<br />Lucky for you, we got just the deals.</div>
                        <div style={styles.phoneOffers}>
                            {hotOfferList.map((item) => (
                                <a key={item.phoneName} href={item.link} target="_parent" style={styles.phoneOffer}
                                    onMouseEnter={(e) => 
                                        {
                                            e.currentTarget.style.outline = styles.phoneOfferHover.outline
                                            e.currentTarget.style.bottom = styles.phoneOfferHover.bottom
                                            e.currentTarget.style.transition = styles.phoneOfferHover.transition
                                            e.currentTarget.style.transitionTimingFunction = styles.phoneOfferHover.transitionTimingFunction
                                        }
                                    }
                                    onMouseLeave={(e) => 
                                        {
                                            e.currentTarget.style.outline = '0px #ffffff'
                                            e.currentTarget.style.bottom = '0px'
                                        }
                                    }>
                                    <div style={styles.imageContainer}>
                                        <img src={pic5G} alt="5G" style={styles.fiveGPic} />
                                        <img src={item.pic} alt="Phone" style={styles.phonePic} />
                                    </div>
                                    <div style={styles.offerDetails}>
                                        <h3 style={styles.offerTitle}>{item.phoneName}</h3>
                                        <div style={styles.offerInfo}>
                                            <img src={nowIcon} alt="nowic" style={styles.nowIcon} />
                                            <div className='semiBoldText' style={styles.offerPrice}><strong>{item.price}</strong></div>
                                            <div className='mediumText' style={styles.offerRpp}>RPP <s>{item.rpp}</s></div>
                                            <div className='semiBoldText' style={styles.offerSave}><strong>{item.save}</strong></div>
                                        </div>                                        
                                        <div className='semiBoldText' style={styles.offerFeature}><img src={tickIcon} alt="Tick" style={styles.tickIcon} /> <strong>No Contract</strong> <img src={tickIcon} alt="Tick" style={styles.tickIcon2} /> <strong>No Upfront</strong></div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                )
                : (
                    <div style={styles.hotOffersSection}>
                        <img src={hotOffersPic} alt="Hot Offers" style={styles.hotOffersBannerMobile} />
                        <div className='boldText' style={{ fontSize: 'calc(100vw *  0.03)', ...styles.hotOffersMainTitleMobile, color: '#29295C' }}>So, we heard you don't like contract and any upfront.<br />Lucky for you, we got just the deals.</div>
                        <div style={styles.phoneScrollView}>
                            <div style={screenWidth > 600 ? styles.phoneOffersTab : styles.phoneOffersMobile}>
                                {hotOfferList.map((item) => (

                                    <a key={item.phoneName} href={item.link} target="_parent" style={{ flexBasis: screenWidth > 450 ? '280px' : '200px', ...screenWidth > 600 ? styles.phoneOfferTab : styles.phoneOfferMobile }}>
                                        <div style={screenWidth > 600 ? styles.imageContainerTab : styles.imageContainerMobile}>
                                            <img src={pic5G} alt="5G" style={screenWidth > 700 ? styles.fiveGPicTab : styles.fiveGPicMobile} />
                                            <img src={item.pic} alt="Phone" style={{ width: screenWidth > 450 ? '100px' : '80px', ...screenWidth > 600 ? styles.phonePicTab : styles.phonePicMobile, }} />
                                        </div>
                                        <div style={screenWidth > 600 ? styles.offerDetailsTab : styles.offerDetailsMobile}>
                                            <h3 style={{ fontSize: screenWidth > 550 ? '18px' : '14px', ...screenWidth > 600 ? styles.offerTitleTab : styles.offerTitleMobile }}>{item.phoneName}</h3>
                                            <div style={styles.offerInfo}>
                                                <img src={nowIcon} alt="nowic" style={screenWidth > 700 ? styles.nowIconTab : styles.nowIconMobile} />
                                                <div className='semiBoldText' style={{ fontSize: screenWidth > 550 ? '16px' : '12px', ...screenWidth > 600 ? styles.offerPriceTab : styles.offerPriceMobile }}><strong>{item.price}</strong></div>
                                                <div className='mediumText' style={{ fontSize: screenWidth > 550 ? '15px' : '11px', ...screenWidth > 600 ? styles.offerRppTab : styles.offerRppMobile }}>RPP <s>{item.rpp}</s></div>
                                                <div className='semiBoldText' style={{ fontSize: screenWidth > 550 ? '14px' : '10px', ...screenWidth > 600 ? styles.offerSaveTab : styles.offerSaveMobile }}><strong>{item.save}</strong></div>
                                            </div>
                                            <div className='semiBoldText' style={{ fontSize: screenWidth > 550 ? '15px' : '11px', ...screenWidth > 600 ? styles.offerFeatureTab : styles.offerFeatureMobile }}><img src={tickIcon} alt="Tick" style={{ width: screenWidth > 550 ? '12px' : '9px', ...screenWidth > 700 ? styles.tickIconTab : styles.tickIconMobile }} /> <strong>No Contract</strong> <img src={tickIcon} alt="Tick" style={{ width: screenWidth > 550 ? '12px' : '9px', ...screenWidth > 700 ? styles.tickIcon2Tab : styles.tickIcon2Mobile }} /> <strong>No Upfront</strong></div>
                                        </div>

                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                )
        );
    }

    const renderPromoSection = () => {
        const [bannerImageList] = useState([
            {
                "pic": `${process.env.PUBLIC_URL}/assets/images/tele_banner/WebBannerSmall-01.jpg`,
                "link": "https://www.onexox.my/onefamily-plan"
            },
            {
                "pic": `${process.env.PUBLIC_URL}/assets/images/tele_banner/WebBannerSmall-02.jpg`,
                "link": "https://blackmarket.xox.com.my/product/[Japan]-Travel-eSIM-Unlimited-5G-Data?id=MjYzNmZhZDctYTFmNy00YjM1LTlkN2ItOTU1NDBkYzY0Yzg1"
            },
            {
                "pic": `${process.env.PUBLIC_URL}/assets/images/tele_banner/WebBannerSmall-03.jpg`,
                "link": `${process.env.PUBLIC_URL}/xoxwifi`
            },
            `${process.env.PUBLIC_URL}/assets/images/tele_banner/WebBannerSmall-01.jpg`,
            `${process.env.PUBLIC_URL}/assets/images/tele_banner/WebBannerSmall-02.jpg`,
            `${process.env.PUBLIC_URL}/assets/images/tele_banner/WebBannerSmall-03.jpg`
        ]);

        return (
            screenWidth > 1024
                ? (
                    <div style={styles.promoSectionMain}>
                        <div style={styles.promoSection}>
                            <div style={styles.promoItemLarge}>
                                <a href={bannerImageList[0].link} className='clickable'>
                                    <img src={bannerImageList[0].pic} alt="Promo 1" style={styles.promoImage} />
                                </a>
                            </div>
                            <div style={styles.smallPromoSection}>
                                <div style={styles.promoItemSmall}>
                                    <a href={bannerImageList[1].link} className='clickable'>
                                        <img src={bannerImageList[1].pic} alt="Promo 2" style={styles.promoImageSmall} />
                                    </a>
                                </div>
                                <div style={styles.promoItemSmall2}>
                                    <a href={bannerImageList[2].link} className='clickable'>
                                        <img src={bannerImageList[2].pic} alt="Promo 3" style={styles.promoImageSmall} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={styles.promoSectionMainMobile}>
                        <div style={styles.promoSectionMobile}>
                            <div style={styles.promoItemLargeMobile}>
                                <a href={bannerImageList[0].link} className='clickable'>
                                    <img src={bannerImageList[0].pic} alt="Promo 1" style={styles.promoImage} />
                                </a>
                            </div>
                            <div style={styles.smallPromoSection}>
                                <div style={styles.promoItemSmallMobile}>
                                    <a href={bannerImageList[1].link} className='clickable'>
                                        <img src={bannerImageList[1].pic} alt="Promo 2" style={styles.promoImageSmall} />
                                    </a>
                                </div>
                                <div style={styles.promoItemSmall2Mobile}>
                                    <a href={bannerImageList[2].link} className='clickable'>
                                        <img src={bannerImageList[2].pic} alt="Promo 3" style={styles.promoImageSmall} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
        );
    };

    return (
        <div className='montserrat innerContent' style={{ paddingBottom: '0px', overflow: 'hidden'}}>
            <div style={{ backgroundColor: '#F8F7F4', width: '100%', justifyContent: 'center', textAlign: 'center' }}>

                <BannerSlider images={bannerImageList} />

                {renderHotOffer()}

                {screenWidth > 850 ?
                    <div style={{ ...styles.connectDiv, width: '100%', height: 'auto' }}>
                        <div style={{ textAlign: 'left', width: '50%', padding: '5% 5% 5% 5%', marginLeft: '35vw' }}>
                            <div className='boldText' style={{ fontSize: 'calc(100vw *  0.02)', color: '#29295C' }}>WAIT, YOU DON'T NEED PHONE TOO?<br />WELL SAY NO MORE.</div>
                            <div className='semiBoldText' style={{ fontSize: 'calc(100vw *  0.015)', marginTop: '1vh' }}>Unlimited 5G. Unlimited Hotspot. No Contract. <br /> From <b>RM55/month</b></div>
                            <a href={'https://wa.me/60378842323'} className='clickable'>
                                <img src={whatsaappNowImg} 
                                    onMouseEnter={(e) => 
                                        {
                                            e.currentTarget.style.opacity = styles.hoverWhatsapp.opacity
                                        }
                                    }
                                    onMouseLeave={(e) => 
                                        {
                                            e.currentTarget.style.opacity = 1
                                        }
                                    } 
                                    style={{ margin: 'auto', marginTop: screenWidth > 1200 ? '40px' : '10px', height: screenWidth > 1200 ? '1vw' : '0.5vw', ...styles.btnShape,  ...styles.btnWhatsapp}} 
                                /></a>
                            {/* <button type='button' onClick={() => scrollToElement()} className='clickable greenHoverBG whiteText greenBG semiBoldText largeText mobileHeight40' style={{ margin: 'auto', marginTop: screenWidth > 1200 ? '50px' : '25px', height: screenWidth > 1200 ? '60px' : '45px', ...styles.btnShape }}>
                            <FaWhatsapp style={{ marginRight: '5%', color: 'white', height:'35%', width: 'auto' }} /><span style={{marginBottom: '20px'}} >Whatsapp Us Now</span></button> */}
                        </div>
                    </div>
                    :

                    <div style={{ ...styles.connectDivMobile, width: '100%', height: 'auto' }}>
                        <div style={{ textAlign: 'left', width: '50%', padding: '5% 5% 5% 5%', marginLeft: '25%' }}>
                            <div className='hugeText boldText' style={{ fontSize: '2.8vw', textAlign: 'start', color: '#29295C' }}>
                                WAIT, YOU DON'T NEED PHONE TOO?<br />WELL SAY NO MORE.
                            </div>
                            <div className='smallText12' style={{ fontSize: '2.1vw', marginTop: '5%', textAlign: 'start' }}>
                                Unlimited 5G. Unlimited Hotspot. No Contract. <br /> From <b>RM55/month</b>
                            </div>
                            <a href={'https://wa.me/60378842323'} className='clickable'>
                                <img src={whatsaappNowImg} style={{ ...styles.btnShape, marginTop: screenWidth > 600 ? '25px' : '15px', marginBottom: '5px', height: screenWidth > 600 ? '25px' : '20px', width: 'auto', borderRadius: '50px', padding: '0', background: 'none', border: 'none', cursor: 'pointer' }} />
                            </a>
                        </div>
                    </div>


                }

                {renderPromoSection()}

            </div>
        </div>
    )
}

const connectYouBgImage = `${process.env.PUBLIC_URL}/assets/images/tele_banner/banner2.png`;


const styles = {


    connectDiv: {
        backgroundImage: `url(${connectYouBgImage})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },

    connectDivMobile: {
        backgroundImage: `url(${connectYouBgImage})`,
        marginLeft: '10%',
        backgroundSize: '150% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },

    btnShape: {
        alignSelf: 'center',
        borderRadius: '30px',
        width: '260px',
        height: '50px',
        maxWidth: '75%',
        border: 0,
    },

    btnWhatsapp: {
        borderRadius: '50px', 
        alignSelf: 'flex-start', 
        padding: 0, 
        background: 'none', 
        border: 'none', 
        cursor: 'pointer'
    },

    hoverWhatsapp: {
        opacity: 0.5
    },

    hotOffersSection: {    
        background: 'linear-gradient(to bottom, #EFEFFC, white)', // Gradient background
        padding: "3% 0 3% 0",
        borderRadius: '10px',
        padding: '7vw 0',
    },

    hotOffersBanner: { 
        width: '365px',
        height: '65px'
    },

    phoneOffers: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '30px',
    },

    imageContainer: {
        position: 'relative',
        marginTop: '50px',
        height: 'auto'
    },

    phoneOfferHover: {
        outline: '3px solid #372EA8',
        bottom: '0.7vw',
        transition: '0.2s',
        transitionTimingFunction: 'ease'
    },

    phoneOffer: {
        position: 'relative',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '0px',
        background: 'white',
        borderRadius: '10px',
        padding: '0 3vw 2vw 3vw',
        textDecoration: 'none',
        color: "black",
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
        border: '2px solid transparent',
        transition: 'border-color 0.3s', // Smooth transition for the border color
    },

    phonePic: {
        width: 'auto',
        height: 'auto',
        position: 'relative',
        zIndex: 1
    },

    offerDetails: {    
        marginTop: '30px'     
    },
    
    offerTitle: { 
        fontSize: '28px'
    },

    offerInfo: {
        display: 'grid',
        gridTemplateColumns: '30% 70%'
    },

    offerPrice: {
        textAlign: 'left',
        fontSize: '24px'
    },

    offerRpp: {
        textAlign: 'left',
        marginTop: '10px',
        fontSize: '16px'
    },
    offerSave: {
        textAlign: 'left',
        marginTop: '3px',
        fontSize: '14px',
        color: 'red'
    },
    offerFeature: {
        marginTop: '35px',
        fontSize: '18px',
        gap: '60px'
    },
    fiveGPic: {
        width: '67px',
        height: 'auto',
        position: 'absolute',
        top: '45%',
        transform: 'translateX(-30%)',
        zIndex: 2
    },
    tickIcon: {
        width: '16px',
        height: 'auto',
        marginRight: '5px'
    },

    tickIcon2: {
        width: '16px',
        height: 'auto',
        marginRight: '5px',
        marginLeft: '35px'
    },

    nowIcon: {
        gridRow: '1/4',
        display: 'block',
        marginLeft: 'auto',
        paddingTop: '3%',
        paddingRight: '10%',
        height: '30%'
    },

    hotOffersBannerMobile: {
        width: '30%',
        height: '15%'
    },

    hotOffersMainTitleMobile: {
        marginBottom: '5%',
        marginTop: '2%'
    },

    phoneScrollView: { 
        overflowX: 'scroll',
        padding: '20px',
        clipPath: 'inset(0 0 20px 0)'
    },

    phoneOffersMobile: { 
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        marginBottom: '10px'
    },

    phoneOffersTab: { 
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        gap: '25px',
        marginBottom: '20px'
    },

    phoneOfferMobile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'white',
        padding: '10px',
        borderRadius: '5px',
        textDecoration: 'none',
        color: "black",
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '2px solid transparent',
        transition: 'border-color 0.3s',
    },

    phoneOfferTab: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'white',
        borderRadius: '5px',
        textDecoration: 'none',
        color: "black",
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '2px solid transparent',
        transition: 'border-color 0.3s',
    },

    imageContainerMobile: {
        position: 'relative',
        width: '100%',
        height: 'auto',
    },
    
    imageContainerTab: {
        marginTop: '7vw',
        position: 'relative',
        width: '100%',
        height: 'auto'
    },

    phonePicMobile: {
        marginTop: '20px',
        height: 'auto',
        position: 'relative',
        zIndex: 1
    },

    phonePicTab: {
        width: '20vw',
        height: '25vw',
        position: 'relative',
        zIndex: 1
    },

    offerDetailsMobile: {
        width: '50vw',
        margin: '10px 10px 15px 10px'
    },

    offerDetailsTab: {
        width: '40vw',
        margin: '5vw 5vw 2vw 5vw'
    },

    offerTitleMobile: {
        marginTop: '15px',
        fontSize: 'calc(100vw *  0.04)'
    },

    offerTitleTab: {
        fontSize: 'calc(100vw *  0.027)'
    },

    offerPriceMobile: {
        textAlign: 'left',
        margin: '0',
        fontSize: 'calc(100vw *  0.03)'
    },

    offerPriceTab: {
        textAlign: 'left',
        margin: '0',
        fontSize: 'calc(100vw *  0.025)'
    },

    offerRppMobile: {
        textAlign: 'left',
        marginTop: '3px',
        fontSize: 'calc(100vw *  0.025)'
    },

    offerRppTab: {
        textAlign: 'left',
        marginTop: '1vw',
        fontSize: 'calc(100vw *  0.02)'
    },

    offerSaveMobile: {
        textAlign: 'left',
        color: 'red',
        fontSize: 'calc(100vw *  0.02)'
    },

    offerSaveTab: {
        textAlign: 'left',
        color: 'red',
        fontSize: 'calc(100vw *  0.015)'
    },

    offerFeatureMobile: {
        marginTop: '10%',
        fontSize: 'calc(100vw *  0.03)'
    },

    offerFeatureTab: {
        marginTop: '15%',
        marginBottom: '3vw',
        fontSize: 'calc(100vw *  0.02)'
    },

    fiveGPicMobile: {
        width: '40px',
        height: 'auto',
        position: 'absolute',
        top: '45%',
        transform: 'translateX(-30%)',
        zIndex: 2
    },
    fiveGPicTab: {
        width: '15%',
        height: 'auto',
        position: 'absolute',
        top: '45%',
        transform: 'translateX(-30%)',
        zIndex: 2
    },
    tickIconMobile: {
        height: 'auto',
        marginRight: '3px'
    },
    tickIconTab: {
        width: '16px',
        height: 'auto',
        marginRight: '6px'
    },

    tickIcon2Mobile: {
        height: 'auto',
        marginRight: '3px',
        marginLeft: '5px'
    },
    tickIcon2Tab: {
        width: '16px',
        height: 'auto',
        marginRight: '6px',
        marginLeft: '10px'
    },

    nowIconMobile: {
        gridRow: '1/4',
        display: 'block',
        marginLeft: 'auto',
        paddingTop: '5%',
        paddingRight: '10%',
        height: 'calc(100vw *  0.025)'
    },

    nowIconTab: {
        gridRow: '1/4',
        display: 'block',
        marginLeft: 'auto',
        paddingTop: '5%',
        paddingRight: '10%',
        height: 'calc(100vw *  0.02)'
    },

    promoSection: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '95%',
        margin: 'auto',
        backgroundColor: '#fff',
    },

    promoSectionMobile: {
        justifyContent: 'space-between',
        width: '95%',
        margin: 'auto',
        backgroundColor: '#fff',
    },

    promoSectionMain: {
        backgroundColor: '#fff',
        padding: "3% 3% 3% 3%",
    },

    promoSectionMainMobile: {
        backgroundColor: '#fff',
        padding: "10% 3%",
    },

    promoItemLarge: {
        flexBasis: '54%',
        marginBottom: '20px',
        cursor: 'pointer'
    },

    promoItemLargeMobile: {
        marginBottom: '10px',
        cursor: 'pointer'
    },

    promoItemSmall: {
        flexBasis: '50%',
        marginBottom: '20px',
        cursor: 'pointer'
    },

    promoItemSmallMobile: {
        marginBottom: '10px',
        cursor: 'pointer'
    },

    promoImage: {
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    promoItemSmall2: {
        flexBasis: '50%',
        marginBottom: '17px',
        cursor: 'pointer'
    },
    promoImageSmall: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    smallPromoSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexBasis: '44%',
    },
};
export default SuperHOC(Home)

import BackButton from '../../component/common/backBtn';
import { SuperHOC } from "../../HOC";
import React, { Component } from 'react';
import _styles from '../../_styles.css';
import { MdKeyboardArrowRight, MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { Height } from '@mui/icons-material';

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {

            FAQList: [
                {
                    "question": "What is XOX WiFi about?",
                    "answer": ["XOX WiFi is XOX’s latest plans for Subscribers to enjoy high usage data and high-speed internet with an affordable price.",
                        "The details of the plan are as stated below:"],
                    table: <img className='FaqTableImg' src={`${process.env.PUBLIC_URL}/assets/images/FAQ/FaqTable1.png`}/>
                },
                {
                    "question": "What terms and conditions apply?",
                    "answer": [],
                    Url: <div>Kindly click <a className='boldText blackText noVisitedColor' href={`${process.env.PUBLIC_URL}/assets/T&C/[T&C] XOX WiFi.pdf`} style={{textDecoration:'none', color:'#372EA8' }} target='_blank'>here</a> for the full list of terms and conditions.<br/><br/></div>
                },
                {
                    "question": "Who is eligible to subscribe to the XOX WiFi Plans?",
                    "answer": ["To register for the XOX WiFi Plans, the Subscriber must be eighteen (18) years of age and above with a valid NRIC or passport. Foreigners must possess a valid passport with a remaining validity period of no less than six (6) months."],
                },
                {
                    "question": "How much are the XOX WiFi Plans?",
                    "answer": ["The monthly commitment fee of the XOX WiFi Plans are as stated below:"],
                    table: <img className='FaqTableImg' src={`${process.env.PUBLIC_URL}/assets/images/FAQ/FaqTable2.png`}/>
                },
                {
                    "question": "How can I purchase the XOX WiFi Plans?",
                    "answer": [],                    
                    Url: <div>
                            You may purchase the XOX WiFi Plans via the following platforms: <br/>
                            a.XOX and ONEXOX Official Dealers<br/>
                            b.XOX Online Portal, <a className='boldText blackText noVisitedColor' href='/xoxwifi' style={{textDecoration:'none', color:'#372EA8' }} target='_blank'>https://onlinestore.xox.com.my/xoxwifi</a>
                        </div>
                },
                {
                    "question": "Where can I sign up for the XOX WIFI Plans with contract?",
                    "answer": [],
                    table: <div>
                                You may sign up for the XOX WIFI Plans with contract at participating XOX Authorized Physical Outlets as stated below:
                                <table style={{margin: "0px 10px"}}>
                                    <tr><td>a.</td><td>SPACE Sunway Pyramid</td></tr>
                                    <tr><td>b.</td><td>SPACE Sunway Velocity</td></tr>
                                    <tr><td>c.</td><td>XCC Queensbay</td></tr>
                                    <tr><td>d.</td><td>XCC ICT Komtar</td></tr>
                                    <tr><td>e.</td><td>XCC Kuching</td></tr>
                                    <tr><td>f.</td><td>XPP IOI Putrajaya</td></tr>    
                                    <tr><td>g.</td><td>XPP Ipoh Parade</td></tr>
                                    <tr><td>h.</td><td>XPP Paradigm Mall, Kelana Jaya</td></tr>
                                    <tr><td>i.</td><td>XPP Aeon Bandaraya Melaka</td></tr>
                                    <tr><td>j.</td><td>XPP Giant Kuantan</td></tr>
                                    <tr><td>k.</td><td>XPP Aeon Kota Bharu</td></tr>
                                    <tr><td>l.</td><td>XPP AU2</td></tr>
                                </table>
                            </div>,
                    note: <div style={{margin: '10px 0px',fontStyle: 'italic'}}>
                            Note: Additional outlets will be updated from time to time. The devices are subject to the availability at each outlet.
                        </div>,
                },
                {
                    "question": "If I need more data, can I purchase any data add-on?",
                    "answer": [],
                    Url: <div style={{marginBottom: '10px'}}>
                            YES! You may purchase the following data add-ons for the XOX WiFi Plans via the XOX Online Portal, <a className='boldText blackText noVisitedColor' href='/xoxwifi' style={{textDecoration:'none', color:'#372EA8' }} target='_blank'>https://onlinestore.xox.com.my/xoxwifi</a>
                        </div>,
                    table: <img styles={{width:'70%'}} className='FaqTableImg' src={`${process.env.PUBLIC_URL}/assets/images/FAQ/FaqTable3.png`}/>
                },
                {
                    "question": "Can I share data, talk time, and SMS to other XOX Subscribers?",
                    "answer": ["Unfortunately, no. The XOX WiFi Plans do not come with talk time and SMS. Hence, data, talk time, and SMS are not sharable to other Subscribers."],
                },
                {
                    "question": "Can I buy Season Pass Packages or Happy Hour Promotion under the XOX WiFi Plans?",
                    "answer": ["Unfortunately, no. The ongoing promotions are not available for the XOX WiFi Plans."],
                },
                {
                    "question": "Is there any limit for the hotspot usage? ",
                    "answer": ["No, there’s no limit for the hotspot usage of the XOX WiFi Plans. In fact, the XOX WiFi Plans are highly recommended and suitable for hotspot usage!"],
                },
                {
                    "question": "How many devices can be connected to the hotspot feature?",
                    "answer": ["You can hotspot to as many devices as you want. There are no limitations on the XOX WiFi Plans, but the number of devices supported may depend on the 4G MIFI Device or 4G Router hardware capabilities."],
                },
                {
                    "question": "Can I port-in (MNP) using my favourite number to the XOX WiFi Plans?",
                    "answer": ["Unfortunately, no. Upon registration for the XOX WiFi Plans, you will be allocated a new number for profile verification and billing purposes."],
                },
                {
                    "question": "May I change from the XOX WiFi Plans to other XOX Plans or vice versa?",
                    "answer": ["Unfortunately, no. The change of plan is not available at the moment."],
                },
                {
                    "question": "What if the 4G MIFI Device or 4G Router is not working?",
                    "answer": ["Don’t worry! You may proceed to our nearest XOX Authorized Physical Outlet for After Sales Service."],
                },
                {
                    "question": "Is there a device warranty for 4G MIFI Device or 4G Router?",
                    "answer": ["Good news, yes! There is a device warranty period of twelve (12) months from the date of purchase for both the 4G MIFI Device and 4G Router. The device warranty covers only electrical or mechanical defects of the devices. "],
                },
                {
                    "question": "What happens if I do not activate the SIM Card that was registered online?",
                    "answer": ["Your SIM Card will automatically expire, and your Service account will be terminated accordingly if you do not activate the SIM Card within thirty (30) days."],
                },
                {
                    "question": "Can the XOX WiFi Plans be used while roaming overseas?",
                    "answer": ["Unfortunately, no. The plans are only available in Malaysia. International roaming may incur additional charges or result in no service. "],
                },
                {
                    "question": "Where can I check the internet balance?",
                    "answer": [],
                    Url: <div>
                            You may check the internet balance at the XOX Online Portal, <br/>
                            <a className='boldText blackText noVisitedColor' href='/xoxwifi' style={{textDecoration:'none', color:'#372EA8' }} target='_blank'>https://onlinestore.xox.com.my/xoxwifi</a>
                        </div>
                },
            ],
        };
    }

    componentDidMount=()=>{
        // window.location.href = '/MyCart';
    }

    renderFAQ = () => {
        const { selectedFAQ } = this.state;
        return(
            this.state.FAQList.map((item,index) => {
                return(
                    <div className={selectedFAQ == index ? 'lightpurpleBG3 row clickable' : 'row clickable' } style={{ margin:'auto', maxWidth:'80%', borderRadius:'10px', padding: '20px 30px' }}  onClick={selectedFAQ == index ? ()=>{this.setState({selectedFAQ:-1})}: ()=>{this.setState({selectedFAQ:index})}}>
                        <div style={{width:'80%'}}>
                            <div className='poppins semiBoldText largeText'>{item.question}</div>
                            <div className={'smallText lightgrayText ' + (selectedFAQ === index ? '' : 'hiddenFaq')}>
                                <br/>
                                {item.answer.map((answerItem)=>{
                                    return (
                                        <div>{answerItem}<br/><br/></div>
                                    )
                                })} 

                                {item.Url}

                                {item.table}

                                {item.note}

                            </div>
                        </div>
                        <div style={{alignSelf:'flex-start',width:'20%',textAlign:'right'}}>
                            {selectedFAQ == index 
                            ? 
                            <MdKeyboardArrowUp size={25}/>
                            :
                            <MdKeyboardArrowDown size={25} />
                            }
                        </div>
                    </div>
                )
            })
        )
    }
    render() {
        return (
            <div className='montserrat innerContent' style={{backgroundColor:'#FFFFFF'}}>
                <div className='boxPadding' style={{textAlign:'center', paddingBottom:'0'}}>
                <BackButton path={'xoxwifi'} />
                <div style={styles.choosePlanDiv} ref={ (ref) => this.myRef=ref }>
                        <div className='largeText redText boldText'>FAQ’s</div>
                        <div style={{...styles.faqBg}}>
                            <div className='collosalText40 collosalText22_max340px boldText'>Frequently Asked<br/><span className='blueText'>Questions</span></div>
                            </div>
                            <div className='row' style={{ justifyContent: 'center' }}>

                            <div className='row' style={{width:'90%',margin:'auto',alignItems:'flex-start',justifyContent:'center'}}>
                                <div style={{textAlign:'left',marginBottom:'20px'}}>
                                </div>
                                <div style={{minHeight:'1400px', textAlign:'left',marginTop:'59px'}}>
                                    {this.renderFAQ()}
                                </div>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        )
    }
}

const backgroundImageURL = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Choose%20your%20plan.png`;
const faqBackgroundURL = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/FAQs.png`;
const styles ={
    bg: {
        backgroundImage: `url(${backgroundImageURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%'
    },
    faqBg: {
        backgroundImage: `url(${faqBackgroundURL})`,
        backgroundSize: 'auto 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        padding: '5%'
    }


};
export default FAQ
import React, { useState, useEffect, useRef } from 'react';
import _styles from '../../_styles.css';
import { useLocation } from 'react-router-dom';
import BackButton from '../../component/common/backBtn';
import { GoTriangleDown } from "react-icons/go";
import { SuperHOC, RouteWithParam } from "../../HOC";
import Loader from 'react-loader-spinner'
import Alert from '../../animation/alert.json'
import Lottie from 'react-lottie';
import countryPhoneCode from '../../constant/countryPhoneCode.json';

function Checkout(props) {
    const location = useLocation();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);   

    const [windowPopUp, setWindowPopUp] = useState(0);
    const [allowPay, setAllowPay] = useState(false);
    const [planDetailsList, setPlanDetailsList] = useState({
        "685": {
            productName: "WiFi PLUS LITE",
            SIM: "SIM ONLY",
            included: "Plan",
            planDescription: "Our 250GB SIM Only Plan is designed for those who crave the perfect balance of data, flexibility, and ease of use. Enjoy the freedom of no contracts, unlock the potential for additional data with our convenient data add-ons, and experience a hassle-free ‘plug & play’ connectivity with our user-friendly SIM.",
            planType: "Monthly Plan",
            internetAmount: "250GB",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/WiFi_Plus_SIM_Only.jpg`,
            price: 55,
            price2: 0,
            price2Info: "Device",
            infoList: [
                { infoText: "No Contract" },
                { infoText: "Eligible for data add-ons" },
                { infoText: "Plug & Play" },
            ]
        },
        "687": {
            productName: "WiFi PLUS FREEDOM",
            SIM: "SIM + 4G MiFi",
            included: "Plan + 4G MiFi",
            planDescription: "Our 250GB SIM + 4G MiFi bundle is your gateway to seamless connectivity without the constraints of contracts. Experience the joy of no commitments, unlock the potential for additional data with convenient data add-ons, and enjoy the ease of use with our ‘plug & play’ SIM. What’s more, grab the powerful 4G MiFi for only RM110, a steal from its actual price of RM189!",
            planType: "Monthly Plan",
            internetAmount: "250GB",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/WiFi_Plus_with_Router.jpg`,
            price: 55,
            price2: 110,
            price2Info: "Device",
            infoList: [
                { infoText: "No Contract" },
                { infoText: "Eligible for data add-ons" },
                { infoText: "Plug & Play" },
                { infoText: "Portable & pocket-friendly" },
            ]
        },
        "686": {
            productName: "WiFi PREMIUM LITE",
            SIM: "SIM ONLY",
            included: "Plan",
            planDescription: "Our unlimited SIM Only Plan is crafted for those who demand the best in data, flexibility, and user-friendly experiences. Immerse yourself in a world of limitless possibilities with a generous data allowance, no contractual obligations, and the convenience of data add-ons. Get ready for a seamless ‘plug & play’ experience that puts you in control of your connectivity.",
            planType: "Monthly Plan",
            internetAmount: "Unlimited",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/WiFi_Premium_SIM_Only.jpg`,
            price: 85,
            price2: 0,
            price2Info: "Device",
            infoList: [
                { infoText: "No Contract" },
                { infoText: "Eligible for data add-ons" },
                { infoText: "Plug & Play" },
            ]
        },
        "688": {
            productName: "WiFi PREMIUM FREEDOM",
            SIM: "SIM + 4G ROUTER",
            included: "Plan + 4G ROUTER",
            planDescription: "Our unlimited SIM + 4G Router bundle is designed to elevate your connectivity experience. With no contracts tying you down, you’ll enjoy the freedom of substantial data, flexibility with data add-ons, and an easy ‘plug & play’ setup. What’s more, grab the advanced 4G Router for only RM130, a significant discount from its actual price of RM220!",
            planType: "Monthly Plan",
            internetAmount: "Unlimited",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/WiFi_Premium_with_Router.jpg`,
            price: 85,
            price2: 130,
            price2Info: "Device",
            infoList: [
                { infoText: "No Contract" },
                { infoText: "Eligible for data add-ons" },
                { infoText: "Plug & Play" },
            ]
        },

    });

    const [loading, setLoading] = useState(true);
    const [selectedPlanId, setSelectedPlanId] = useState('');
    const [selectedMobileNumber, setSelectedMobileNumber] = useState('');

    const [dropDownIndex, setDropDownIndex] = useState(0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('0');
    const [mobileNumberValue, setMobileNumberValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [idTypeValue, setIdTypeValue] = useState('');
    const [idNoValue, setIdNoValue] = useState('');
    const [recipientNameValue, setRecipientNameValue] = useState('');
    const [recipientMobileValue, setRecipientMobileValue] = useState('');
    const [addressOneValue, setAddressOneValue] = useState('');
    const [addressTwoValue, setAddressTwoValue] = useState('');
    const [countryValue, setCountryValue] = useState('Malaysia');
    const [stateValue, setStateValue] = useState('');
    const [cityValue, setCityValue] = useState('');
    const [postcodeValue, setPostcodeValue] = useState('');

    const [mobilePrefixValue, setMobilePrefixValue] = useState('60');
    const [recipientMobilePrefixValue, setRecipientMobilePrefixValue] = useState('60');

    const [stateName, setStateName] = useState('');
    const [idTypeName, setIdTypeName] = useState('');

    const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [idTypeError, setIdTypeError] = useState('');
    const [idNoError, setIdNoError] = useState('');
    const [recipientNameError, setRecipientNameError] = useState('');
    const [recipientMobileError, setRecipientMobileError] = useState('');
    const [addressOneError, setAddressOneError] = useState('');
    const [addressTwoError, setAddressTwoError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [stateError, setStateError] = useState('');
    const [cityError, setCityError] = useState('');
    const [postcodeError, setPostcodeError] = useState('');

    const [idTypeOption, setIdTypeOption] = useState([
        { "value": "NRIC", "name": "NRIC" },
        { "value": "Passport", "name": "Passport" }
    ]);

    const [stateOption, setStateOption] = useState([
        { "value": "710", "name": "JOHOR" },
        { "value": "420", "name": "KEDAH" },
        { "value": "910", "name": "KELANTAN" },
        { "value": "320", "name": "KUALA LUMPUR" },
        { "value": "830", "name": "LABUAN" },
        { "value": "620", "name": "MELAKA" },
        { "value": "610", "name": "NEGERI SEMBILAN" },
        { "value": "920", "name": "PAHANG" },
        { "value": "410", "name": "PENANG" },
        { "value": "510", "name": "PERAK" },
        { "value": "430", "name": "PERLIS" },
        { "value": "330", "name": "PUTRAJAYA" },
        { "value": "810", "name": "SABAH" },
        { "value": "820", "name": "SARAWAK" },
        { "value": "310", "name": "SELANGOR" },
        { "value": "930", "name": "TERENGGANU" }
    ]);

    const contactSection = useRef(null);
    const verificationSection = useRef(null);
    const deliverySection = useRef(null);
    const countrySection = useRef(null);
    const paymentSection = useRef(null);


    useEffect(() => {
        try {
            window.scrollTo(0, 0);

            if (!(location.state && location.state.selectedPlanId && location.state.selectedMobileNumber)) {
                window.location.href = '/xoxwifi';
            } else {
                props.apiManager.makeCall('mobileWifi/getProductPlanList', { platform: 'OL' }, null, (res) => {
                    if (res.success) {
                        if (Array.isArray(res.data.non_devices) && Array.isArray(res.data.devices)) {
                            res.data.devices = res.data.devices.filter(item => item.stock > 0);
                            let combineList = res.data.non_devices.concat(res.data.devices);
                            var matchedPlan = combineList.find(item => item.idproduct === location.state.selectedPlanId);
                            if (matchedPlan != undefined) {
                                setSelectedPlanId(location.state.selectedPlanId);
                                setSelectedMobileNumber(location.state.selectedMobileNumber);
                                fetchData();
                            } else {
                                setWindowPopUp(3);
                            }
                        }
                    }
                });

                // location.state.selectedPlanId = null;
                // location.state.selectedMobileNumber = null;
            }
        } catch (e) {
            window.location.href = '/xoxwifi';
        }
    }, [])

    const fetchData = () => {
        props.apiManager.makeCall('state/list', {}, null, (res) => {
            if (res.success) {
                setStateOption(res.data.List);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }

    const validateAllInput = (index) => {
        if (index == -1){
            setAllowPay(false);
            return false;
        }

        var emailCheck = (index == 0);
        var  recipientMobileCheck = (index == 1);
        var  recipientNameCheck = (index == 2);
        var  addressOneCheck = (index == 3);
        var  stateCheck = (index == 4);
        var  cityCheck = (index == 5);
        var  postcodeCheck = (index == 6);
        var  selectedPaymentMethodCheck = (index == 7);
    
        var value = emailValue;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    
        if (value == '') {
            //setEmailError('Email cannot be empty.');
        }
        else if (!emailRegex.test(value)) {
            //setEmailError('Email format is invalid.');
        }
        else {
            //setEmailError('');
            emailCheck = true;
        }
    
        value = recipientMobileValue;
    
        if (value == '') {
            //setRecipientMobileError('Recipient Mobile Number cannot be empty.');
        }
        else if (value.length < 9 || value.length > 10) {
            //setRecipientMobileError('Recipient Mobile Number is invalid.');
        }
        else if (value[0] == '0') {
            //setRecipientMobileError('Recipient Mobile Number should not start with 0.');
        }
        else {
            //setRecipientMobileError('');
            recipientMobileCheck = true;
        }
    
        value = recipientNameValue;
    
        const lettersRegex = /^[a-zA-Z /]*$/;
    
        if (value == '') {
            //setRecipientNameError('Recipient Name cannot be empty.');
        }
        else if (!lettersRegex.test(value)) {
            //setRecipientNameError('Recipient cannot have special character or number.');
        }
        else if (value.length < 6) {
            //setRecipientNameError('Recipient Name cannot less than 6 characters.');
        }
        else {
            //setRecipientNameError("");
            recipientNameCheck = true;
        }
    
        value = addressOneValue;
    
        if (value == '') {
            //setAddressOneError('Address cannot be empty.');
        }
        else if (value.length < 5) {
            //setAddressOneError('Address cannot less than 5 characters.');
        }
        else {
            //setAddressOneError('');
            addressOneCheck = true;
        }
    
        value = stateValue;
    
        if (value == '') {
            //setStateError('Please select State.');
        }
        else {
            //setStateError('');
            stateCheck = true;
        }
    
        value = cityValue;
    
        const lettersRegex2 = /^[a-zA-Z ]+$/;
    
        if (value == '') {
            //setCityError('City cannot be empty.');
        }
        else if (!lettersRegex2.test(value)) {
            //setCityError('City cannot have special character or number.');
        }
        else if (value.length < 5) {
            //setCityError('City cannot less than 5 characters.');
        }
        else {
            //setCityError("");
            cityCheck = true;
        }
    
        value = postcodeValue;
    
        if (value == '') {
            //setPostcodeError('Postcode cannot be empty.');
        }
        else if (value.length != 5) {
            //setPostcodeError('Postcode must be 5 characters.');
        }
        else {
            //setPostcodeError("");
            postcodeCheck = true;
        }
    
        value = selectedPaymentMethod;
    
        if (value != '999' && value != '2') {
            //setSelectedPaymentMethodError('Please select a payment method.');
        }
        
        else {
            //setSelectedPaymentMethodError('');
            selectedPaymentMethodCheck = true;
        }
        
        setAllowPay(
            emailCheck &&
            recipientMobileCheck &&
            recipientNameCheck &&
            addressOneCheck &&
            stateCheck &&
            cityCheck &&
            postcodeCheck &&
            selectedPaymentMethodCheck
        );
    
    }
    const payNow = () => {
        var inputAllValid = true
        var scrollDestination = null

        // inputAllValid = checkMobileNumber(mobileNumberValue) && inputAllValid;
        inputAllValid = checkEmail(emailValue) && inputAllValid;
        inputAllValid = checkRecipientMobile(recipientMobileValue) && inputAllValid;
        inputAllValid = checkRecipientName(recipientNameValue) && inputAllValid;

        if (!inputAllValid && !scrollDestination) scrollDestination = contactSection;

        // inputAllValid = checkIdType(idTypeValue) && inputAllValid;
        // inputAllValid = checkIdNo(idNoValue) && inputAllValid;

        // if(!inputAllValid && !scrollDestination) scrollDestination = verificationSection;

        inputAllValid = checkAddressOne(addressOneValue) && inputAllValid;

        if (!inputAllValid && !scrollDestination) scrollDestination = deliverySection;

        inputAllValid = checkState(stateValue) && inputAllValid;
        inputAllValid = checkCity(cityValue) && inputAllValid;
        inputAllValid = checkPostcode(postcodeValue) && inputAllValid;

        if (!inputAllValid && !scrollDestination) scrollDestination = countrySection;

        inputAllValid = checkSelectedPaymentMethod(selectedPaymentMethod) && inputAllValid;

        if (!inputAllValid && !scrollDestination) scrollDestination = paymentSection;

        if (inputAllValid) {
            var payload = {};
            payload.order = {};
            payload.orderDetail = {};

            payload.order.Address = addressOneValue + ' ' + addressTwoValue;
            payload.order.City = cityValue;
            payload.order.PostCode = postcodeValue;
            payload.order.email = emailValue;
            payload.order.ContactNo = '0' + recipientMobileValue;
            payload.order.CustomerName = recipientNameValue;
            payload.order.StateCode = stateValue;
            payload.order.msisdn = selectedMobileNumber;
            payload.order.language = 'MLY';

            payload.orderDetail.idproduct = selectedPlanId;

            payload.paymentId = selectedPaymentMethod;

            payload.actionKey = 303;

            setWindowPopUp(5);
            props.apiManager.makeCall("payment/createOrderMobileWifi", payload, null, (res) => {
                if (res && res.message == 1051 && res.url && res.orderId) {
                    location.state.selectedPlanId = null;
                    location.state.selectedMobileNumber = null;
                    var orderInfo = {
                        orderId: res.orderId,
                        email: emailValue,
                        isNewReg: true
                    }

                    orderInfo.cancelOrder = res.cancelOrder ? res.cancelOrder : null;
                    var redirect = res.url + '?callback_url=' + window.location.origin + '/xoxwifi/order_confirmation/' + btoa(JSON.stringify(orderInfo));
                    window.location = redirect;
                    setWindowPopUp(0);
                } else {
                    location.state.selectedPlanId = null;
                    location.state.selectedMobileNumber = null;
                    setWindowPopUp(res.message == 1001 ? 3 : 4);
                }
            })
        } else {
            var destinationPosition = window.scrollY + scrollDestination.current.getBoundingClientRect().top - 60
            window.scrollTo({
                top: destinationPosition,
                behavior: "smooth"
            });
        }
    }

    const renderOutOfStock = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Alert,
            renderer: 'svg'
        }
        return (

            <div className='whiteBG fixedPadding_largeScreen' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', width: '370px', maxWidth: '90%', borderRadius: '8px' }}>
                <Lottie
                    options={defaultOptions}
                    style={{ width: '150px' }}
                />
                <div className='semiBoldText collosalText' style={{ fontSize: '24px', margin: '0 0 19px 0' }}>Oops!</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>Product out of stock</div>
                <div className='row' style={{ marginTop: '52px', gap: '5px' }}>
                    <button type='button' onClick={() => { window.location = '/xoxwifi' }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: '0 auto 10px auto', ...styles.btnShape, maxWidth: '0px' }}>Back to Home</button>
                </div>
            </div>

        )
    }
    const renderRetry = (message) => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Alert,
            renderer: 'svg'
        }
        return (

            <div className='whiteBG fixedPadding_largeScreen' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', width: '370px', maxWidth: '90%', borderRadius: '8px' }}>
                <Lottie
                    options={defaultOptions}
                    style={{ width: '150px' }}
                />
                <div className='semiBoldText collosalText' style={{ fontSize: '24px', margin: '0 0 19px 0' }}>Oops!</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>{message}</div>
                <div className='row' style={{ marginTop: '52px', gap: '5px' }}>
                    <button type='button' onClick={() => { window.location = '/xoxwifi/plan/' + btoa(selectedPlanId) }} className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50' style={{ margin: '0 auto 10px auto', ...styles.btnShape, maxWidth: '0px' }}>Retry</button>
                </div>
            </div>

        )
    }

    const handleValueChange = (event, type, setValue, checkError) => {
        const value = event.target.value;

        if (type == 'digit') {
            const digitRegex = /^\d+$/;

            if (value != '' && !digitRegex.test(value)) {
                return false;
            }
        }

        setValue(value);

        checkError(value);

    }

    const checkMobileNumber = (value) => {
        if (value == '') {
            setMobileNumberError('Mobile Number cannot be empty.');
            return false;
        }
        else if (value.length < 5 || value.length > 15) {
            setMobileNumberError('Mobile Number is invalid.');
            return false;
        }
        else if (value[0] == '0') {
            setMobileNumberError('Mobile Number should not start with 0.');
            return false;
        }
        else {
            setMobileNumberError('');
            return true;
        }
    }

    const checkEmail = (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (value == '') {
            setEmailError('Email cannot be empty.');
            validateAllInput(-1);
            return false;
        }
        else if (!emailRegex.test(value)) {
            setEmailError('Email format is invalid.');
            validateAllInput(-1);
            return false;
        }
        else {
            setEmailError('');
            validateAllInput(-1);
            return true;
        }
    }

    const checkIdNo = (value) => {
        if (value == '') {
            setIdNoError('ID Number cannot be empty.');
            return false;
        }
        else if (idTypeValue == 'NRIC' && value.length != 12) {
            setIdNoError('ID Number must be 12 digits.');
            return false;
        }
        else if (idTypeValue == 'Passport' && (value.length > 10 || value.length < 5)) {
            setIdNoError('Passport must be 5 - 10 digits.');
            return false;
        }
        else {
            setIdNoError('');
            validateAllInput(-1);
            return true;
        }
    }

    const checkRecipientMobile = (value) => {
        if (value == '') {
            setRecipientMobileError('Recipient Mobile Number cannot be empty.');
            validateAllInput(-1);
            return false;
        }
        else if (value.length < 9 || value.length > 10) {
            setRecipientMobileError('Recipient Mobile Number is invalid.');
            validateAllInput(-1);
            return false;
        }
        else if (value[0] == '0') {
            setRecipientMobileError('Recipient Mobile Number should not start with 0.');
            validateAllInput(-1);
            return false;
        }
        else {
            setRecipientMobileError('');
            validateAllInput(1);
            return true;
        }
    }

    const checkRecipientName = (value) => {
        const lettersRegex = /^[a-zA-Z /]*$/;

        if (value == '') {
            setRecipientNameError('Recipient Name cannot be empty.');
            validateAllInput(-1);
            return false;
        }
        else if (!lettersRegex.test(value)) {
            setRecipientNameError('Recipient cannot have special character or number.');
            validateAllInput(-1);
            return false;
        }
        else if (value.length < 6) {
            setRecipientNameError('Recipient Name cannot less than 6 characters.');
            validateAllInput(-1);
            return false;
        }
        else {
            setRecipientNameError("");
            validateAllInput(2);
            return true;
        }
    }

    const checkAddressOne = (value) => {
        if (value == '') {
            setAddressOneError('Address cannot be empty.');
            validateAllInput(-1);
            return false;
        }
        else if (value.length < 5) {
            setAddressOneError('Address cannot less than 5 characters.');
            validateAllInput(-1);
            return false;
        }
        else {
            setAddressOneError('');
            validateAllInput(3);
            return true;
        }
    }

    const checkAddressTwo = (value) => {
        return true;
    }

    const checkCity = (value) => {
        const lettersRegex = /^[a-zA-Z ]+$/;

        if (value == '') {
            setCityError('City cannot be empty.');
            validateAllInput(-1);
            return false;
        }
        else if (!lettersRegex.test(value)) {
            setCityError('City cannot have special character or number.');
            validateAllInput(-1);
            return false;
        }
        else if (value.length < 5) {
            setCityError('City cannot less than 5 characters.');
            validateAllInput(-1);
            return false;
        }
        else {
            setCityError("");
            validateAllInput(5);
            return true;
        }
    }

    const checkSelectedPaymentMethod = (value) => {
        if (value != '999' && value != '2') {
            setSelectedPaymentMethodError('Please select a payment method.');
            validateAllInput(-1);
            return false;
        }

        else {
            setSelectedPaymentMethodError('');
            validateAllInput(7);
            return true;
        }
    }

    const checkState = (value) => {
        if (value == '') {
            setStateError('Please select State.');
            validateAllInput(-1);
            return false;
        }
        else {
            setStateError('');
            validateAllInput(4);
            return true;
        }
    }

    const checkIdType = (value) => {
        if (value == '') {
            setIdTypeError('Please select ID Type.');
            return false;
        }
        else {
            setIdTypeError('');
            return true;
        }
    }

    const checkPostcode = (value) => {
        if (value == '') {
            setPostcodeError('Postcode cannot be empty.');
            validateAllInput(-1);
            return false;
        }
        else if (value.length != 5) {
            setPostcodeError('Postcode must be 5 characters.');
            validateAllInput(-1);
            return false;
        }
        else {
            setPostcodeError("");
            validateAllInput(6);
            return true;
        }
    }

    const renderMobileInput_disabledPrefix = (label, placeholder, value, error, maxLength, type, setValue, checkError, index, prefixValue, setPrefixValue, disabled = false) => {
        return (
            <div className='input' style={{ position: 'relative' }}>
                <div className='grayText smallText textSpacing'>{label}<span className='redText'> *</span></div>
                <div style={{ width: '110%', display: 'flex', justifyContent:'space-between',gap:'2%' }}>
                    <button className='blackText smallText checkoutDropDown clickable'
                        disabled={true}
                        style={{ padding:0, backgroundColor: '#EBEBEB', width: '20%', textAlign: 'center', position: 'relative', borderWidth: '0', maxWidth: '80px' }}>
                           +{prefixValue}
                    </button>
                    <input className={'smallText checkoutInputField ' + (error ? 'redBorderFocus' : '')}
                        style={{ flex: 1}}
                        onChange={(event) => {
                            handleValueChange(event, type, setValue, checkError);
                        }}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        value={value}
                        type='text' />
                </div>
                <div className='orangeText smallText' style={{ height: screenWidth> 600 ? '35px' : '20px', marginTop: screenWidth> 600 ? '10px' : '0px' }}>{error}</div>
            </div>
        )
    }

    const renderMobileInput_prefix = (label, placeholder, value, error, maxLength, type, setValue, checkError, index, prefixValue, setPrefixValue, disabled = false) => {
        return (
            <div className='input' style={{ position: 'relative' }}>
                <div className='grayText smallText textSpacing'>{label}<span className='redText'> *</span></div>
                <div 
                className={error ? 'redBorderFocus' : ''}
                style={{ border: '1px solid rgba(110, 109, 112, 0.2)', borderColor: error ? 'red' : 'rgba(110, 109, 112, 0.2)', borderRadius: '10px', width: '110%', display: 'flex' }}>
                    <button className='grayText smallText checkoutDropDown clickable'
                        disabled={disabled}
                        onClick={(event) => {
                            event.stopPropagation();
                            setDropDownIndex(dropDownIndex == index ? 0 : index);
                        }}
                        style={{ backgroundColor: disabled ? '#EBEBEB' : '', width: '30%', textAlign: 'left', position: 'relative', borderWidth: '0', maxWidth: '80px', borderRightWidth: disabled ? '0px' : '1px', padding: disabled ? '15px 0% 15px 3%' : '8px 0% 8px 3%', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
                        <div className='row' style={{ color: 'black', height: '100%', position: 'relative' }}>
                            +{prefixValue}
                            <GoTriangleDown size={25} style={{ color: '#A2A2A2', position: 'absolute', right: '-10px', top: '50%', transform: 'translate(-50%,-50%)' }} />
                        </div>
                    </button>
                    <input className='smallText checkoutInputField'
                        style={{ flex: 1, borderWidth: '0', borderRadius: '0 10px 10px 0', border: '1px solid transparent' }}
                        onChange={(event) => {
                            handleValueChange(event, type, setValue, checkError);
                        }}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        value={value}
                        type='text' />
                </div>
                <div className='orangeText smallText' style={{ height: screenWidth> 600 ? '35px' : '20px', marginTop: screenWidth> 600 ? '10px' : '0px' }}>{error}</div>

                <div className='checkoutDropDownItemList' style={dropDownIndex == index ? { zIndex: '2' } : { display: 'none' }}>
                    {countryPhoneCode.map((option) => {
                        return (
                            <div className='smallText checkoutDropDownItem clickable'
                                onClick={() => {
                                    setPrefixValue(option.value);
                                    setDropDownIndex(0);
                                }}>
                                <div className='row' style={{ width: '100%' }}>
                                    <div className='grayText' style={{ width: '20%' }}>+{option.value}</div>
                                    <div style={{ width: '70%', padding: '0 3%' }}>{option.name}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const renderInput = (label, placeholder, value, error, maxLength, type, setValue, checkError) => {
        return (
            <div className='input'>
                <div className='grayText smallText textSpacing'>{label}<span className='redText'> *</span></div>
                <input className={'smallText checkoutInputField ' + (error ? 'redBorderFocus' : '')}
                    onChange={(event) => {
                        handleValueChange(event, type, setValue, checkError);
                    }}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    value={value}
                    type='text' />
                <div className='orangeText smallText' style={{ height: screenWidth> 600 ? '35px' : '20px', marginTop: screenWidth> 600 ? '10px' : '0px' }}>{error}</div>
            </div>
        )
    }

    const renderDropDown = (label, optionArray, placeholder, currentName, setName, setValue, index, error, checkValue) => {
        return (
            <div className='input' style={{ position: 'relative', marginBottom: screenWidth > 600 ? '0px' : '-10px' }}>
                <div className='grayText smallText textSpacing'>{label}<span className='redText'> *</span></div>
                <button className='grayText smallText checkoutDropDown clickable'
                    onClick={(event) => {
                        event.stopPropagation();
                        setDropDownIndex(dropDownIndex == index ? 0 : index);
                    }}
                    style={{ textAlign: 'left', position: 'relative', borderColor: error ? 'red' : 'rgba(110, 109, 112, 0.2)' }}>
                    {currentName == '' ?
                        <div style={{ height: '100%', position: 'relative' }}>
                            {placeholder}
                            <GoTriangleDown size={25} style={{ color: '#A2A2A2', position: 'absolute', right: '-10px', top: '50%', transform: 'translate(-50%,-50%)' }} />
                        </div>
                        :
                        <div style={{ color: 'black', height: '100%', position: 'relative' }}>
                            {currentName}
                            <GoTriangleDown size={25} style={{ color: '#A2A2A2', position: 'absolute', right: '-10px', top: '50%', transform: 'translate(-50%,-50%)' }} />
                        </div>
                    }

                </button>
                <div className='orangeText smallText' style={{ height: screenWidth> 600 ? '35px' : '20px', marginTop: screenWidth> 600 ? '10px' : '0px' }}>{error}</div>
                <div className='checkoutDropDownItemList' style={dropDownIndex == index ? null : { display: 'none' }}>
                    {optionArray.map((option) => {
                        return (
                            <div className='smallText checkoutDropDownItem clickable'
                                onClick={() => {
                                    setName(option.name);
                                    setValue(option.StateCode ? option.StateCode : option.value);
                                    checkValue(option.value)
                                    setDropDownIndex(0);
                                }}>{option.name}</div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        !loading ?
            <div className={'montserrat innerContent noPaddingTop'} onClick={() => { setDropDownIndex(0) }} style={{ paddingBottom: '0', backgroundColor: '#F8F8F8' }}>
                <div className={windowPopUp == 0 ? 'noneDisplay' : 'popupWindowBG'}>
                    {windowPopUp == 4 ? renderRetry('Failed to create order. Please try again.') : null}
                    {windowPopUp == 3 ? renderOutOfStock() : null}
                    {windowPopUp == 5 ? <Loader type="TailSpin" width={50} height={50} /> : null}
                </div>
                <div className='boxPadding navigationRow'>
                    <BackButton path={'xoxwifi/plan/' + btoa(selectedPlanId)} />
                    <div className='collosalText32 boldText' style={{ marginBottom: '30px', textAlign: 'center' }}>Checkout</div>
                    <div className='row' style={{ alignItems: 'flex-start' }}>
                        <div className='mobileResize100' style={{ width: '60%', marginBottom: '20px' }}>
                            <div ref={contactSection} className='boldText collosalText' style={{ paddingBottom: '20px' }}>Contact Information</div>
                            <div className='inputRow row'>
                                {renderInput('Recipient Name', 'Recipient Name', recipientNameValue, recipientNameError, 100, '', setRecipientNameValue, checkRecipientName)}
                                {renderMobileInput_disabledPrefix('Recipient Mobile Number', 'Recipient Mobile Number', recipientMobileValue, recipientMobileError, 20, 'digit', setRecipientMobileValue, checkRecipientMobile, 4, recipientMobilePrefixValue, setRecipientMobilePrefixValue)}
                            </div>
                            <div className='inputRow row'>
                                {/* {renderMobileInput('Mobile Number', 'Mobile Number', mobileNumberValue, mobileNumberError, 20, 'digit', setMobileNumberValue, checkMobileNumber, 3, mobilePrefixValue, setMobilePrefixValue)} */}
                                {renderInput('Email Address', 'Email Address', emailValue, emailError, 100, '', setEmailValue, checkEmail)}
                            </div>

                            {/* <div ref={verificationSection} className='boldText collosalText' style={{ padding: '20px 0 20px 0' }}>Verification</div>
                            <div className='inputRow row'>
                                {renderDropDown('ID Type', idTypeOption, 'Select ID Type', idTypeName, setIdTypeName, setIdTypeValue, 1, idTypeError, checkIdType)}
                                {renderInput('ID Number', 'ID Number', idNoValue, idNoError, 20, 'digit', setIdNoValue, checkIdNo)}
                            </div> */}

                            <div ref={deliverySection} className='boldText collosalText' style={{ padding: '20px 0 10px 0' }}>Delivery Address</div>
                            <div className='smallText grayText' style={{ marginBottom: '30px' }}>Please note that delivery is only available within Malaysia.</div>
                            <div className='inputRow row' style={{ marginTop: '10px' }}>
                                <div className='input' style={{ width: '97.5%' }}>
                                    <div className='grayText smallText textSpacing'>Address<span className='redText'> *</span></div>
                                    <input className={'smallText checkoutInputFieldLong textSpacing ' + (addressOneError ? 'redBorderFocus' : '')}
                                        onChange={(event) => { handleValueChange(event, '', setAddressOneValue, checkAddressOne); }}
                                        maxLength={200}
                                        value={addressOneValue}
                                        placeholder='Address 1'
                                        type='text' />
                                    <input className='smallText checkoutInputFieldLong'
                                        onChange={(event) => { handleValueChange(event, '', setAddressTwoValue, checkAddressTwo); }}
                                        maxLength={200}
                                        value={addressTwoValue}
                                        placeholder='Address 2'
                                        type='text' />
                                    <div className='orangeText smallText' style={{ height: screenWidth> 600 ? '35px' : '20px', marginTop: screenWidth> 600 ? '10px' : '0px' }}>{addressOneError}</div>
                                </div>
                            </div>
                            <div className='inputRow row' ref={countrySection} style={{ marginTop: '10px' }}>
                                <div className='input'>
                                    <div className='grayText smallText textSpacing'>Country<span className='redText'> *</span></div>
                                    <input className='smallText checkoutInputField' style={{ backgroundColor: '#EBEBEB',border:0 }} placeholder='Country' disabled value={countryValue} type='text' />
                                    <div className='redText orangeText' style={{ height: screenWidth> 600 ? '35px' : '20px', marginTop: screenWidth> 600 ? '10px' : '0px' }}></div>
                                </div>
                                {renderDropDown('State', stateOption, 'Select State', stateName, setStateName, setStateValue, 2, stateError, checkState)}
                            </div>
                            <div className='inputRow row' style={{ marginTop: '10px' }}>
                                {renderInput('City', 'City', cityValue, cityError, 100, '', setCityValue, checkCity)}
                                {renderInput('Postcode', 'Postcode', postcodeValue, postcodeError, 5, 'digit', setPostcodeValue, checkPostcode)}
                            </div>
                        </div>
                        <div className='mobileResize100 lightpurpleBG horizontalMinPadding20_500px verticalMinPadding20_500px' style={{ flex: '1', borderRadius: '10px', padding: '3%', marginLeft: '5%', marginBottom: '50px', minHeight: '310px', position: 'relative' }}>
                            <div className='row' style={{ alignItems: 'flex-start', justifyContent: 'space-around' }}>
                                <img style={{ width: '20%', maxWidth: '83px', height: '20%', maxHeight: '83px' }} src={planDetailsList[selectedPlanId].image}></img>
                                <div className='showTextInBigScreen' style={{ width: '50%', padding: '0 10px' }}>
                                    <div className='hugeText boldText textSpacing breakLongWord'>{planDetailsList[selectedPlanId].productName}</div>
                                    <div className='smallText grayText textSpacing'><span>{selectedMobileNumber}</span></div>
                                    <div className='smallText grayText textSpacing'>SIM Type: <span>{planDetailsList[selectedPlanId].SIM}</span></div>
                                    <div className='smallText grayText textSpacing'>{planDetailsList[selectedPlanId].internetAmount}/month ; {planDetailsList[selectedPlanId].planType}</div>
                                    <div className='showTextInBigScreen smallText boldText' style={{ display: 'none' }}>RM{planDetailsList[selectedPlanId].price}</div>
                                </div>
                                <div style={{ width: '20%', textAlign: 'right' }} className='hideTextInBigScreen hugeText boldText'>RM{planDetailsList[selectedPlanId].price}</div>
                            </div>

                            <hr style={{ opacity: 0.2, margin: '20px 0' }} />

                            {/* <div className='mediumText boldText textSpacing'>Promo Code</div>
                            <div className='row' style={{ justifyContent: 'space-between' }}>
                                <input disabled type='text' style={{ borderRadius: '10px', border: '0', width: '50%', padding: '15px', outline: 'none' }} placeholder='Promo Code'></input>
                                <button type='button' className='clickable mediumText semiBoldText whiteText blueBG' style={{ width: '30%', padding: '12px', maxWidth: '125px', borderRadius: '24px', border: 0 }} >Apply</button>
                            </div> */}

                            {/* <hr style={{ opacity: 0.2, margin: '20px 0' }} /> */}

                            <div className='row textSpacing' style={{ justifyContent: 'space-between' }}>
                                <div className='smallText grayText'>Subtotal</div>
                                <div className='smallText semiBoldText'>RM{planDetailsList[selectedPlanId].price.toFixed(2)}</div>
                            </div>
                            {
                                planDetailsList[selectedPlanId].price2 > 0 ?
                                    <div className='row textSpacing' style={{ justifyContent: 'space-between' }}>
                                        <div className='smallText grayText'>{planDetailsList[selectedPlanId].price2Info}</div>
                                        <div className='smallText semiBoldText'>RM{planDetailsList[selectedPlanId].price2.toFixed(2)}</div>
                                    </div>
                                    :
                                    null
                            }
                            <div className='row textSpacing' style={{ justifyContent: 'space-between' }}>
                                <div className='smallText grayText'>Shipping</div>
                                <div className='smallText semiBoldText'>RM0.00</div>
                            </div>

                            <div className='row textSpacing' style={{ justifyContent: 'space-between' }}>
                                <div className='hugeText boldText'><br /></div>
                                <div className='collosalText boldText'><br /></div>
                            </div>
                            <div className='row textSpacing' style={{ justifyContent: 'space-between' }}>
                                <div className='hugeText boldText'><br /></div>
                                <div className='collosalText boldText'><br /></div>
                            </div>
                            <div className='row textSpacing' style={{ justifyContent: 'space-between' }}>
                                <div className='hugeText boldText'>Total</div>
                                <div className='collosalText boldText'>RM{(planDetailsList[selectedPlanId].price + planDetailsList[selectedPlanId].price2 + 0).toFixed(2)}</div>
                            </div>
                        </div>
                        <div ref={paymentSection} className='mobileResize100' style={{ width: '60%' }}>
                            <div className='boldText collosalText textSpacing' style={{ padding: '0px 0 10px 0' }}>Payment</div>
                            <div className='smallText grayText'>All transactions are secure and encrypted.</div>
                            <div className='orangeText smallText' style={{ height: screenWidth> 600 ? '35px' : '20px', marginTop: screenWidth> 600 ? '10px' : '0px' }}>{selectedPaymentMethodError}</div>

                            <div className={ screenWidth > 600 ? 'row paymentMethodDiv' : 'row' } style={{ marginBottom: '50px' }}>
                                <div style={{ maxWidth: screenWidth > 600 ? '220px' : '120px', marginLeft: screenWidth > 600 ? '5px' : '0', marginRight: '5px', ...styles.paymentMethodOption }} className={(selectedPaymentMethod == 999 ? 'selectedtoggleSelectionDiv' : 'blueHoverBorder blueHoverText') + ' clickable toggleSelectionDiv'} onClick={() => { validateAllInput(7); setSelectedPaymentMethodError(''); setSelectedPaymentMethod(999) }}>
                                    <span className='smallText'>Online Banking</span>
                                </div>
                                <div style={{ maxWidth: screenWidth > 600 ? '220px' : '120px', marginLeft: screenWidth > 600 ? '5px' : '10px', marginRight: screenWidth > 600 ? '5px' : '0', ...styles.paymentMethodOption }} className={(selectedPaymentMethod == 2 ? 'selectedtoggleSelectionDiv' : 'blueHoverBorder blueHoverText') + ' clickable toggleSelectionDiv'} onClick={() => { validateAllInput(7); setSelectedPaymentMethodError(''); setSelectedPaymentMethod(2) }}>
                                    <span className='smallText'>Credit / Debit Card</span>
                                </div>
                            </div>

                            <div className='smallText13 grayText'>By proceeding, I acknowledge that I have read, understood and agreed to the website’s <a href={`${process.env.PUBLIC_URL}/assets/T&C/[T&C] XOX WiFi.pdf`} className='extraBoldText' target='_blank'>Terms and Conditions</a> & <a href='https://xox.com.my/privacystatement/details/' className='extraBoldText' target='_blank'>Privacy Policy</a>. XOX Media may store your personal info for internal analysis use or related activities.</div>
                            <button className={(allowPay ? 'clickable blueBG blueHoverBG' : 'disabledGrayBtn grayBG') + ' mobileHeight50 whiteText semiBoldText largeText'}
                                type="button"
                                onClick={() => {
                                    if(!allowPay) return false;
                                    payNow()
                                }}
                                style={styles.payNowBtn}>Pay Now</button>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className={'montserrat innerContent noPaddingTop'}>
                <div className={windowPopUp == 0 ? 'noneDisplay' : 'popupWindowBG'}>
                    {windowPopUp == 4 ? renderRetry('Failed to create order. Please try again.') : null}
                    {windowPopUp == 3 ? renderOutOfStock() : null}
                    {windowPopUp == 5 ? <Loader type="TailSpin" width={50} height={50} /> : null}
                </div>
                <div className='boxPadding navigationRow' style={{ marginTop: '20px', textAlign: 'center' }}>
                    <Loader type="TailSpin" />
                </div>
            </div>
    )
}

const styles = {
    payNowBtn: {
        border: '0',
        borderRadius: '30px',
        height: '60px',
        width: '100%',
        margin: 'auto',
        marginTop: '30px'
    },
    btnShape: {
        alignSelf: 'center',
        borderRadius: '30px',
        height: '60px',
        width: '50%',
        minWidth: '200px',
        border: 0,
    },
    paymentMethodOption: {
        padding: '0 10px',
        minWidth: '115px',
    }

};
export default RouteWithParam(SuperHOC(Checkout))
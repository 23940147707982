import React from 'react';
import { IoIosArrowBack  } from "react-icons/io";
import _styles from '../../_styles.css';
import { useNavigate } from 'react-router-dom';

function BackButton(props) {
    const navigate = useNavigate();

    if(props.defaultMobileNumber)
        return <a
                  onClick={()=>{
                        navigate(('/'+props.path), { state: { mobileNumber: props.defaultMobileNumber} });
                  }}
                  style={{width:'fit-content'}} className='clickable blackText montserrat backBtn blueHoverText row poppins smallText'><IoIosArrowBack style={{paddingBottom:'1px'}}/>Back</a>;
    
    else
        return <a href={`${process.env.PUBLIC_URL}/`+props.path} style={{width:'fit-content'}} className='blackText montserrat backBtn blueHoverText row poppins smallText'><IoIosArrowBack style={{paddingBottom:'1px'}}/>Back</a>;
}

export default BackButton;
import { Stack } from '@mui/material';
import NotFound from './pages/NotFound';
import MoWiMainPage from './pages/mobileWifi/MainPage';
import Plan from './pages/mobileWifi/Plan';
import Checkout from './pages/mobileWifi/Checkout';
import CheckBalance from './pages/mobileWifi/CheckBalance';
import ChangePlan from './pages/mobileWifi/ChangePlan';
import AddOn from './pages/mobileWifi/AddOn';
import ManageSubscription from './pages/mobileWifi/ManageSubscription';
import FAQ from './pages/mobileWifi/FAQ';
import OrderConfirmation from './pages/mobileWifi/OrderConfirmation';
import TelMainPage from './pages/telecommunication/TelMainPage';
import React, { Component } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Header from './component/header';
import Footer from './component/footer';
import "@fontsource/montserrat";
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/500.css';
import "@fontsource/poppins";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/500.css";

const App = () => {

  return (
    <Stack>
      <Header />
      <Routes >
        <Route path='/' element={<TelMainPage />}  />

        <Route path='/xoxwifi' element={<MoWiMainPage />} />
        <Route path='/xoxwifi/plan/:id' element={<Plan />} />
        <Route path='/xoxwifi/checkout' element={<Checkout />} />
        <Route path='/xoxwifi/checkbalance' element={<CheckBalance />} />
        <Route path='/xoxwifi/manage_subscription' element={<ManageSubscription />} />
        <Route path='/xoxwifi/FAQ' element={<FAQ />} />
        <Route path='/xoxwifi/order_confirmation/:param' element={<OrderConfirmation />} />
        {/* <Route path='/xoxwifi/change_plan' element={<ChangePlan />} /> */}
        <Route path='/xoxwifi/addon' element={<AddOn />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </Stack>
  );
};

export default App;
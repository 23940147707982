import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import _styles from '../_styles.css';
import LogoImage from './common/logo';
import { AiOutlineShoppingCart, AiOutlineFileSearch } from 'react-icons/ai';
import { FaBars } from 'react-icons/fa';
import { MdKeyboardArrowRight } from "react-icons/md";

// Higher-order component to inject location
const withLocation = (Component) => {
    return (props) => {
        const location = useLocation();
        return <Component {...props} location={location} />;
    };
};

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            screenWidth: window.innerWidth,
            isMenuOpen: false,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.handleResize);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ isMenuOpen: false });
        }
    }

    handleResize = () => {
        this.setState({
            screenWidth: window.innerWidth,
        });
    };

    render() {
        const { screenWidth } = this.state;
        const { location } = this.props; // Access location from props

        // Check if the current path is under xoxwifi
        const isMobileWifiPath = location.pathname.startsWith('/xoxwifi');

        return (
            <div ref={this.setWrapperRef} className='headerBar' style={{ zIndex: '', padding: '18px 0 18px 0', justifyContent: 'center' }}>
                {screenWidth > 1000 ?
                    <div className='row' style={{ padding: '0 5%', justifyContent: 'space-between' }}>
                        <div>
                            <LogoImage logo={`${process.env.PUBLIC_URL}/assets/images/tele_icon/xox5gComHeader.png`} style={{ width: 'auto', height: '40px', padding: '0 1%', justifyContent: 'space-between' }} />
                        </div>

                        <a href={`https://onexox.my/onexox-black`} className='row blackText blueHoverText boldText clickable' style={{ textDecoration: 'none', padding: '0 1% 0 6%', justifyContent: 'space-between' }} >
                            <div className='smallText boldText'>Postpaid</div>
                        </a>

                        <a href={`https://onexox.my/onexox-prepaid`} className='row blackText blueHoverText boldText clickable' style={{ textDecoration: 'none', padding: '0 1%', justifyContent: 'space-between' }} >
                            <div className='smallText boldText'>Prepaid</div>
                        </a>

                        <a href={`https://onexox.my/Xox-5g-Phone-Bundling`} className='row blackText blueHoverText boldText clickable' style={{ textDecoration: 'none', padding: '0 1%', justifyContent: 'space-between' }} >
                            <div className='smallText boldText'>Devices</div>
                        </a>

                        <a href={`${process.env.PUBLIC_URL}/xoxwifi`} className='row blackText blueHoverText boldText clickable' style={{ textDecoration: 'none', padding: '0 1%', justifyContent: 'space-between' }} >
                            <div className='smallText boldText'> {isMobileWifiPath ? 'XOX WiFi' :  'XOX WiFi' }</div>
                        </a>

                        {isMobileWifiPath
                            ? <span style={{ textDecoration: 'none', padding: '0 3%', justifyContent: 'space-between' }} >
                                <div className='mediumText boldText'></div>
                            </span>
                            : <span style={{ textDecoration: 'none', padding: '0 6%', justifyContent: 'space-between' }} >
                                <div className='mediumText boldText'></div>
                            </span>
                        }

                        {isMobileWifiPath
                            ? <a href={`${process.env.PUBLIC_URL}/xoxwifi/checkbalance`} className='row blackText blueHoverText boldText clickable' style={{ textDecoration: 'none', padding: '0 3% 0 0', justifyContent: 'space-between' }} >
                                <img style={{ }} size={20} color='#6E6D7A' src={`${process.env.PUBLIC_URL}/assets/images/images_icons/icon/checkbalance.svg`} alt="Check Balance" />
                                <div className='smallText boldText'>Check Balance</div>
                            </a>
                            : <div></div>
                        }

                        <a href={`https://xox.com.my/`} className='row blueHoverText boldText clickable' style={{ textDecoration: 'none', padding: '0 0 0 0', justifyContent: 'space-between', color: '#372EA8' }} >
                            <div className='smallText boldText'>Back to Main XOX Website</div>
                        </a>
                    </div>
                    :
                    <div className='row' style={{ padding: '0 5%', justifyContent: 'space-between' }}>
                        <div>
                            <LogoImage logo={`${process.env.PUBLIC_URL}/assets/images/tele_icon/xox5gComHeader.png`} style={{ width: 'auto', height: '30px', padding: '0 1%', justifyContent: 'space-between' }} />
                        </div>
                        <div className='row clickable' style={{}} >
                            <FaBars onClick={() => { this.setState({ isMenuOpen: true }) }} className='icon-margin' />
                        </div>
                        <div style={{ ...styles.navMenu, right: this.state.isMenuOpen ? "0" : "-100%", borderRightWidth: 0 }}>
                            <div className='row' style={{ justifyContent: 'flex-end', borderBottom: '1px solid lightgray', padding: '10px 10px 10px 10px' }} >
                                <div onClick={() => { this.setState({ isMenuOpen: false }) }} className='row clickable boldText collosalText'>CLOSE <MdKeyboardArrowRight className='icon-margin' /></div>
                            </div>
                            <a href={`${process.env.PUBLIC_URL}/`} className='largeText semiBoldText' style={{ ...styles.navItem }}>HOME</a>
                            <a href={`https://onexox.my/onexox-black`} className='largeText semiBoldText' style={{ ...styles.navItem }}>Postpaid</a>
                            <a href={`https://onexox.my/onexox-prepaid`} className='largeText semiBoldText' style={{ ...styles.navItem }}>Prepaid</a>
                            <a href={`https://onexox.my/Xox-5g-Phone-Bundling`} className='largeText semiBoldText' style={{ ...styles.navItem }}>Devices</a>
                            <a href={`${process.env.PUBLIC_URL}/xoxwifi`} className='largeText semiBoldText' style={{ ...styles.navItem }}>{isMobileWifiPath ? 'XOX WiFi' :  'XOX WiFi' }</a>
                            {isMobileWifiPath
                                ? <div>

                                    <a href={`${process.env.PUBLIC_URL}/xoxwifi/checkbalance`} className='largeText semiBoldText' style={{ ...styles.navItem }}>Check Balance</a>
                                    <a href={`https://xox.com.my/`} className='largeText semiBoldText' style={{ ...styles.navItem }}>Back to Main XOX Website</a>
                                </div>
                                : <a href={`https://xox.com.my/`} className='largeText semiBoldText' style={{ ...styles.navItem }}>Back to Main XOX Website</a>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const styles = {
    selected: {
        color: '#A346FF',
        borderBottom: '3px solid #A346FF',
    },
    navItem: {
        padding: '10px',
        textDecoration: 'none',
        color: 'black',
        display: 'block',
    },
    navMenu: {
        height: '100%',
        width: '300px',
        maxWidth: '100%',
        position: 'fixed',
        backgroundColor: 'white',
        border: '1px solid lightgray',
        top: 0,
        transition: "right 0.3s ease",
    },
    fiveGPic: {
        width: 'auto',
        height: '70px',
        position: 'absolute',
        top: '5%',
        transform: 'translateX(60px)',
    },
    fiveGPicMobile: {
        width: 'auto',
        height: '50px',
        position: 'absolute',
        top: '8%',
        transform: 'translateX(42px)',
    }
};

export default withLocation(Header);

import React, { Component } from 'react';
import _styles from '../_styles.css';

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount=()=>{
        // window.location.href = '/MyCart';
    }

    render() {
        return (
            <div className='montserrat innerContent' style={{backgroundColor:'#F8F8F8',...styles.bg}}>
                <div className='boxPadding' style={{textAlign:'center'}}>
                    <div className='collosalText40 boldText' style={{margin:'10% 0'}}>Page Not Found</div>
                    <button onClick={() => { window.location.href = '/'; }} className='whiteText clickable largeText semiBoldText mobileHeight40 blueBG blueHoverBG' style={{ borderRadius: '30px', border: '0px', maxWidth: '60%', width: '220px', height: '50px', marginBottom:'30px' }}>Back to Home</button>
                            
                </div>
            </div>
        )
      }
}

const backgroundImageURL = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Order%20confirmation.png`;
const styles ={
    bg: {
        backgroundImage: `url(${backgroundImageURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%'
    }

};
export default NotFound
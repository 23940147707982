
import { RouteWithParam, SuperHOC } from "../../HOC";
import React, { useState, useEffect, useRef } from 'react';
import _styles from '../../_styles.css';
import BackButton from '../../component/common/backBtn';
import Loader from 'react-loader-spinner'
import { useLocation } from 'react-router-dom';
import Alert from '../../animation/alert.json'
import Lottie from 'react-lottie';

function AddOn(props) {
    const location = useLocation();
    const scrollToContinueBtnRef = useRef(null);
    const scrollToPayBillRef = useRef(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);
    const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState("");
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [addOnList, setAddOnList] = useState([]);
    const [selectedAddOn, setSelectedAddOn] = useState(null);
    const [mobileNumber, setMobileNumber] = useState('');
    const [formattedMobileNumber, setFormattedMobileNumber] = useState('');
    const [windowPopUp, setWindowPopUp] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        props.apiManager.makeCall('dataPurchase/addOnList', {}, null, (res) => {
            if (Array.isArray(res)) {
                setAddOnList(res);
            }
        });

        if (!(location.state && location.state.addOnMobileNumber)) {
            window.location = '/xoxwifi/checkbalance'
        } else {
            setMobileNumber(location.state.addOnMobileNumber);
            setFormattedMobileNumber('+6' + location.state.addOnMobileNumber.slice(0, 3) + '-' + location.state.addOnMobileNumber.slice(3, 6) + ' ' + location.state.addOnMobileNumber.slice(6));
            // location.state.addOnMobileNumber = null;
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const handleEmailChange = (event) => {
        var value = event.target.value;

        setEmail(value);
        checkEmail(value);

    }


    const checkEmail = (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (value == '') {
            setEmailError('Email cannot be empty.');
            return false;
        }
        else if (!emailRegex.test(value)) {
            setEmailError('Email format is invalid.');
            return false;
        }
        else {
            setEmailError('');
            return true;
        }
    }

    const scrollToElement = (scrollToRef) => {
        scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleResize = () => {
        setScreenWidth(window.innerWidth)
    };

    const renderAddOnCard = () => {
        return (
            addOnList.map((item, index) => {
                return (
                    <div style={{ position: 'relative', margin: '50px 15px 50px 15px', backgroundColor: 'white', width: '350px', maxWidth: '90%', borderRadius: '15px' }}>
                        <div className='verticalText collosalText38 boldText' style={{ fontSize: '38px', opacity: 0.08, color: '#FC9E1F' }}>Data Add-On</div>
                        <div className='row' style={{ padding: '10px', justifyContent: 'center', backgroundColor: '#FC9E1F', borderRadius: '10px 10px 0px 0px' }}>
                            <div className='whiteText largeText boldText'>Data Add-On Passes</div>
                        </div>
                        <div className='collosalText boldText' style={{ marginTop: '40px', marginBottom: '10px' }}>{item.dataGB} GB</div>
                        <div className='smallText'>Valid for {item.validity} days only</div>
                        <div className='collosalText32 boldText blueText' style={{ marginBottom: '40px', marginTop: '30px' }}><span className='collosalText boldText'>RM</span>{(typeof item.price == 'string' && item.price.slice(-3) == ".00") ? item.price.slice(0, -3) : item.price}<span className='mediumText boldText'> / mth</span></div>
                        <button onClick={() => {
                            setSelectedAddOn(item);
                            setWindowPopUp(1);
                        }} className='whiteText clickable largeText semiBoldText orangeHoverBG mobileHeight40' style={{ borderRadius: '30px', border: '0px', backgroundColor: '#FC9E1F', maxWidth: '60%', width: '220px', height: '50px', marginBottom: '48px' }}>Buy Add-On</button>


                    </div>
                )
            }
            )
        )
    }

    const renderAddOnOrder = () => {
        return (
            <div className='whiteBG horizontalMinPadding20_800px verticalMinPadding20_800px popupWindowMaxHeight' style={{ margin: '0 5px', overflowY: 'auto', position: 'relative', textAlign: 'center', padding: '1.5%', maxWidth: '530px', borderRadius: '8px' }}>
                <div onClick={() => { setWindowPopUp(0) }} className='closeBtn lightgrayText collosalText clickable'>
                        <img style={{ paddingTop: '5px' }} size={25} color='#6E6D7A' src={`${process.env.PUBLIC_URL}/assets/images/images_icons/icon/close.svg`}></img>
                    </div>
                <div className='semiBoldText collosalText' style={{ marginTop: '12px', fontSize: '22px' }}>Buy Add-On</div>
                <div className='lightpurpleBG' style={{ marginTop: '20px', borderRadius: '10px', textAlign: 'left', padding: '5% 5% 0 5%' }}>
                    <div className='row' style={{ justifyContent: 'space-between' }}>
                        <div ref={scrollToPayBillRef} className='mediumText boldText blueText'>Data Add-On</div>
                        <div className='mediumText boldText blueText'>{formattedMobileNumber}</div>
                    </div>
                    <div className='row' style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                        <div ref={scrollToPayBillRef} className='mediumText grayText' style={{maxWidth:'70%'}}>{selectedAddOn.dataGB}GB; Valid for {selectedAddOn.validity} days only</div>
                        <div className='mediumText grayText'>RM{selectedAddOn.price}</div>
                    </div>

                    <hr style={{ opacity: '0.3', margin: '15px 0' }} />

                    <div className='row'>
                        <div className='grayText smallText textSpacing' style={{ marginTop: '0px' }}>Payer Email Address</div>
                        <input className={'smallText whiteBG inputField ' + (emailError ? 'redBorderFocus' : '')} value={email} onChange={(event) => { handleEmailChange(event); }} style={{ border: emailError ? '1px solid red' : '1px solid transparent', width: '95%', padding: '12px 0 12px 5%' }} placeholder='Email Address' type='text' />
                    </div>
                    <div className='orangeText smallText' style={{ height: '30px', marginTop: '10px' }}>{emailError}</div>

                    <div className='grayText smallText textSpacing' style={{ marginTop: '0px' }}>Payment Method</div>
                    <div className='row' style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                        <div style={{ width: '49%' }}>
                            <div
                                onClick={() => { setSelectedPaymentMethod(999); setSelectedPaymentMethodError('') }}
                                className={'clickable whiteBG smallText inputField row ' + (selectedPaymentMethod != 999 ? 'blueHoverBorder blueHoverText' : 'selectedtoggleSelectionDiv')}
                                style={{ width: '98%', height: '45px', justifyContent: 'center', border: '1px solid transparent' }} >
                                Online Banking
                            </div>
                        </div>
                        <div style={{ width: '49%' }}>
                            <div className={'clickable whiteBG smallText inputField row ' + (selectedPaymentMethod != 2 ? 'blueHoverBorder blueHoverText' : 'selectedtoggleSelectionDiv')}
                                onClick={() => { setSelectedPaymentMethod(2); setSelectedPaymentMethodError('') }}
                                style={{ width: '98%', height: '45px', justifyContent: 'center', border: '1px solid transparent' }} >
                                Credit / Debit Card
                            </div>
                        </div>
                    </div>
                    <div className='orangeText smallText' style={{ height: '30px', marginTop: '10px' }}>{selectedPaymentMethodError}</div>
                </div>
                <div className='smallText13 lightgrayText' style={{ textAlign: 'left', marginTop: '15px', maxWidth: '430px' }}>By proceeding, I acknowledge that I have read, understood and agreed to the website’s <a href={`${process.env.PUBLIC_URL}/assets/T&C/[T&C] XOX WiFi.pdf`} target='_blank' className='boldText' style={{ ...styles.anchorBtn }}>Terms and Conditions</a> & <a href='https://xox.com.my/privacystatement/details/' target='_blank' className='boldText' style={{ ...styles.anchorBtn }}>Privacy Policy</a>. XOX Media may store your personal info for internal analysis use or related activities.</div>
                <button
                    ref={scrollToContinueBtnRef}
                    onClick={() => { addOnOrder() }}
                    type='button'
                    className='clickable whiteText blueBG blueHoverBG semiBoldText largeText mobileHeight50'
                    style={{ margin: 'auto', marginTop: '10px', ...styles.btnShape, height:'50px' }}>Continue</button>
            </div>
        )
    }

    const checkSelectedPaymentMethod = () => {
        if (selectedPaymentMethod != 2 && selectedPaymentMethod != 999) {
            setSelectedPaymentMethodError('Please select payment method.');
            return false;
        } else {
            setSelectedPaymentMethodError('');
            return true;
        }
    }

    const addOnOrder = () => {
        var noError = true;
        noError = checkEmail(email) && noError;
        noError = checkSelectedPaymentMethod() && noError;
        if (noError) {
            var payload = {
                actionKey: 301,
                email: email,
                package: selectedAddOn.package,
                paymentId: selectedPaymentMethod,
                msisdn: mobileNumber
            }

            setWindowPopUp(5);
            props.apiManager.makeCall('payment/createOrderMobileWifi', payload, null, (res) => {
                if (res && res.message == 1051 && res.url && res.orderId) {
                    location.state.addOnMobileNumber = null;
                    var orderInfo = {
                        orderId: res.orderId,
                        email: email
                    }
                    setWindowPopUp(1);
                    var redirect = res.url + '?callback_url=' + window.location.origin + '/xoxwifi/order_confirmation/' + btoa(JSON.stringify(orderInfo));
                    window.location = redirect;
                } else {
                    setWindowPopUp(4);
                }
            });
        } else {
            scrollToElement(scrollToPayBillRef);
        }
    }

    const renderRefresh = (message) => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Alert,
            renderer: 'svg'
        }
        return (

            <div className='whiteBG fixedPadding_largeScreen' style={{ margin: '0 5px', position: 'relative', textAlign: 'center', padding: '50px 10px', width: '470px', maxWidth: '90%', borderRadius: '8px' }}>
                <Lottie
                    options={defaultOptions}
                    style={{ width: '150px' }}
                />
                <div className='semiBoldText collosalText' style={{ fontSize: '24px', margin: '0 0 19px 0' }}>Oops!</div>
                <div className='lightgrayText smallText' style={{ fontSize: '14px' }}>{message}</div>
                <div className='row' style={{ marginTop: '52px', gap: '5px' }}>
                    <button type='button' onClick={() => { setWindowPopUp(0) }} className='clickable whiteText lightgrayBG semiBoldText largeText whiteText mobileHeight50' style={{ margin: '0 auto 10px auto', ...styles.btnShape, maxWidth: '0px' }}>Cancel</button>
                    <button type='button' onClick={() => { window.location = '/xoxwifi/addon' }} className='clickable whiteText blueBG semiBoldText largeText mobileHeight50' style={{ margin: '0 auto 10px auto', ...styles.btnShape, maxWidth: '0px' }}>Try Again</button>
                </div>
            </div>

        )
    }

    return (
        <div className={'montserrat innerContent noPaddingTop'} style={{...styles.box}}>
            <div className={windowPopUp == 0 ? 'noneDisplay' : 'popupWindowBG'}>
                {windowPopUp == 1 ? renderAddOnOrder() : null}
                {windowPopUp == 4 ? renderRefresh('Failed to add on. Please try again.') : null}
                {windowPopUp == 5 ? <Loader type="TailSpin" width={50} height={50} /> : null}
            </div>
            <div className='boxPadding navigationRow' style={screenWidth > 700 ? {...styles.chooseAddOnDiv} : {...styles.chooseAddOnDiv_mobile}}>
                <BackButton path={'xoxwifi/checkbalance'} defaultMobileNumber={mobileNumber[0] == '0' ? mobileNumber.slice(1) : mobileNumber} />
                <div style={{ width: '100%', justifyContent: 'center', textAlign: 'center' }}>

                    <div>
                        <div className='collosalText32 boldText'>Data Add-On Passes</div>
                        <br />
                        <div className='smallText grayText'>Select your Data Add-On Passes</div>
                        <div className='row' style={{ justifyContent: 'center', marginBottom: '110px' }}>
                            {renderAddOnCard()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const addOnBgImage = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Data%20add-on.png`;
const addOnBgImage_mobile = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Data%20add-on_mobile.png`;

const styles = {
    box: {
        backgroundColor: '#F8F8F8',
        width: '100%',
        paddingBottom : 0,
    },
    chooseAddOnDiv: {
        backgroundImage: `url(${addOnBgImage})`,
        backgroundSize: '100% auto',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    chooseAddOnDiv_mobile: {
        backgroundImage: `url(${addOnBgImage_mobile})`,
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
    },
    btnShape: {
        alignSelf: 'center',
        borderRadius: '30px',
        height: '60px',
        width: '50%',
        minWidth: '200px',
        border: 0,
    }
};
export default RouteWithParam(SuperHOC(AddOn))
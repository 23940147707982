
import { SuperHOC } from "../../HOC";
import React, { useState, useEffect, useRef } from 'react';
import _styles from '../../_styles.css';
import { IoSearchOutline, IoTriangleSharp } from "react-icons/io5";
import { MdKeyboardArrowRight, MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

import BannerSlider from '../../component/common/bannerSlider';

function Home(props) {    
    useEffect(() => {
        document.title = 'XOX WiFi';
    }, []);

    const scrollToRef = useRef(null);
    const navigate = useNavigate();

    const [mobileNumber, setMobileNumber] = useState([]);
    const [mobileErrorMessage, setMobileErrorMessage] = useState('');
    const [bannerImageList, setBannerImageList] = useState([
        `${process.env.PUBLIC_URL}/assets/images/mobileWifi_banner/XOXWiFiBanner1.jpg`,
        `${process.env.PUBLIC_URL}/assets/images/mobileWifi_banner/XOXWiFiBanner2.jpg`,
        `${process.env.PUBLIC_URL}/assets/images/mobileWifi_banner/XOXWiFiBanner3.jpg`
    ]);
    const [planDetailsList, setPlanDetailsList] = useState({
        "685": {
            productName: "WiFi PLUS LITE",
            sideProductName: "PLUS LITE",
            SIM: "SIM ONLY",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim only.svg`,
            price: "",
            additionalPriceInfo: "",
            infoList: [
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_internet.svg`, infoText: "250GB Internet" },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_no contract.svg`, infoText: "No Contract", bold: true },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_data add-ons.svg`, infoText: "Eligible for data add-ons" },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_plugnplay.svg`, infoText: "Plug & Play" },
            ]
        },
        "687": {
            productName: "WiFi PLUS FREEDOM",
            sideProductName: "PLUS FREEDOM",
            SIM: "SIM + 4G MiFi",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim 4G mifi.svg`,
            price: "",
            additionalPriceInfo: "+ RM110 4G MiFi device (RRP: RM189)",
            infoList: [
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_internet.svg`, infoText: "250GB Internet" },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_no contract.svg`, infoText: "No Contract", bold: true },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_data add-ons.svg`, infoText: "Eligible for data add-ons" },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_plugnplay.svg`, infoText: "Plug & Play" },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_portable.svg`, infoText: "Portable & pocket-friendly" },
            ]
        },
        "686": {
            productName: "WiFi PREMIUM LITE",
            sideProductName: "PREMIUM LITE",
            SIM: "SIM ONLY",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim only.svg`,
            price: "",
            additionalPriceInfo: "",
            infoList: [
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_internet.svg`, infoText: "Unlimited* Internet" },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_no contract.svg`, infoText: "No Contract", bold: true },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_data add-ons.svg`, infoText: "Eligible for data add-ons" },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_plugnplay.svg`, infoText: "Plug & Play" },
            ]
        },
        "688": {
            productName: "WiFi PREMIUM FREEDOM",
            sideProductName: "PREMIUM FREEDOM",
            SIM: "SIM + 4G ROUTER",
            image: `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/sim 4G router.svg`,
            price: "",
            additionalPriceInfo: "+ RM130 4G router (RRP: RM220)",
            infoList: [
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_internet.svg`, infoText: "Unlimited* Internet" },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_no contract.svg`, infoText: "No Contract", bold: true },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_data add-ons.svg`, infoText: "Eligible for data add-ons" },
                { infoIcon: `${process.env.PUBLIC_URL}/assets/images/plan icons/icon_plugnplay.svg`, infoText: "Plug & Play" },
            ]
        },

    });
    const [planList, setPlanList] = useState([]);
    const [selectedPlanType, setSelectedPlanType] = useState('PLUS');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [FAQList, setFAQList] = useState([

        {
            "question": "What is XOX WiFi about?",
            "answer": ["XOX WiFi is XOX’s latest plans for Subscribers to enjoy high usage data and high-speed internet with an affordable price.",
                "The details of the plan are as stated below:"],
            table: <img className='FaqTableImg' src={`${process.env.PUBLIC_URL}/assets/images/FAQ/FaqTable1.png`}/>
        },
        {
            "question": "What terms and conditions apply?",
            "answer": [],
            Url: <div>Kindly click <a className='boldText blackText noVisitedColor' href={`${process.env.PUBLIC_URL}/assets/T&C/[T&C] XOX WiFi.pdf`} style={{textDecoration:'none', color:'#372EA8' }} target='_blank'>here</a> for the full list of terms and conditions.<br/><br/></div>
        },
        {
            "question": "Who is eligible to subscribe to the XOX WiFi Plans?",
            "answer": ["To register for the XOX WiFi Plans, the Subscriber must be eighteen (18) years of age and above with a valid NRIC or passport. Foreigners must possess a valid passport with a remaining validity period of no less than six (6) months."],
        },
        {
            "question": "How much are the XOX WiFi Plans?",
            "answer": ["The monthly commitment fee of the XOX WiFi Plans are as stated below:"],
            table: <img className='FaqTableImg' src={`${process.env.PUBLIC_URL}/assets/images/FAQ/FaqTable2.png`}/>
        },
        {
            "question": "How can I purchase the XOX WiFi Plans?",
            "answer": [],                    
            Url: <div>
                    You may purchase the XOX WiFi Plans via the following platforms: <br/>
                    a.XOX and ONEXOX Official Dealers<br/>
                    b.XOX Online Portal, <a className='boldText blackText noVisitedColor' href='/xoxwifi' style={{textDecoration:'none', color:'#372EA8' }} target='_blank'>https://onlinestore.xox.com.my/xoxwifi</a>
                </div>
        },
        {
            "question": "Where can I sign up for the XOX WIFI Plans with contract?",
            "answer": [],
            table: <div>
                        You may sign up for the XOX WIFI Plans with contract at participating XOX Authorized Physical Outlets as stated below:
                        <table style={{margin: "0px 10px"}}>
                            <tr><td>a.</td><td>SPACE Sunway Pyramid</td></tr>
                            <tr><td>b.</td><td>SPACE Sunway Velocity</td></tr>
                            <tr><td>c.</td><td>XCC Queensbay</td></tr>
                            <tr><td>d.</td><td>XCC ICT Komtar</td></tr>
                            <tr><td>e.</td><td>XCC Kuching</td></tr>
                            <tr><td>f.</td><td>XPP IOI Putrajaya</td></tr>    
                            <tr><td>g.</td><td>XPP Ipoh Parade</td></tr>
                            <tr><td>h.</td><td>XPP Paradigm Mall, Kelana Jaya</td></tr>
                            <tr><td>i.</td><td>XPP Aeon Bandaraya Melaka</td></tr>
                            <tr><td>j.</td><td>XPP Giant Kuantan</td></tr>
                            <tr><td>k.</td><td>XPP Aeon Kota Bharu</td></tr>
                            <tr><td>l.</td><td>XPP AU2</td></tr>
                        </table>
                    </div>,
            note: <div style={{margin: '10px 0px',fontStyle: 'italic'}}>
                    Note: Additional outlets will be updated from time to time. The devices are subject to the availability at each outlet.
                </div>,
        },
        {
            "question": "If I need more data, can I purchase any data add-on?",
            "answer": [],
            Url: <div style={{marginBottom: '10px'}}>
                    YES! You may purchase the following data add-ons for the XOX WiFi Plans via the XOX Online Portal, <a className='boldText blackText noVisitedColor' href='/xoxwifi' style={{textDecoration:'none', color:'#372EA8' }} target='_blank'>https://onlinestore.xox.com.my/xoxwifi</a>
                </div>,
            table: <img styles={{width:'70%'}} className='FaqTableImg' src={`${process.env.PUBLIC_URL}/assets/images/FAQ/FaqTable3.png`}/>
        },
    ]);
    const [selectedFAQ, setSelectedFAQ] = useState(-1);

    const handleResize = () => {
        setScreenWidth(window.innerWidth)
    };

    useEffect(() => {
        props.apiManager.makeCall('mobileWifi/getProductPlanList', { platform: 'OL' }, null, (res) => {
            if (res.success) {
                if (Array.isArray(res.data.non_devices) && Array.isArray(res.data.devices)) {
                    res.data.devices = res.data.devices.filter(item => item.stock > 0);
                    let combineList = res.data.non_devices.concat(res.data.devices);
                    setPlanList(combineList);
                }
            }
        });

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const scrollToElement = () => {
        scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    };


    const handleMobileNumberChange = (event) => {
        const value = event.target.value;

        const digitRegex = /^\d+$/;

        if (value != '' && !digitRegex.test(value)) {
            return false;
        }

        setMobileNumber(value);
        // checkMobileNumber(value);

    }

    const checkMobileNumber = (value) => {
        if (value == '') {
            setMobileErrorMessage('');
            return false;
        }
        else if (value.length < 9 || value.length > 10) {
            alert('Mobile Number is invalid.');
            return false;
        }
        else if (value[0] == '0') {
            alert('Mobile Number should not start with 0.');
            return false;
        }
        else {
            setMobileErrorMessage('');
            return true;
        }
    }

    const renderFAQ = () => {
        return (
            FAQList.map((item, index) => {
                return (
                    <div className={selectedFAQ == index ? 'lightpurpleBG3 row clickable' : 'row clickable'} style={{ borderRadius: '10px', padding: '20px 30px' }} onClick={selectedFAQ == index ? () => { setSelectedFAQ(-1) } : () => { setSelectedFAQ(index) }}>
                        <div style={{ width: '80%' }}>
                            <div className='poppins semiBoldText largeText'>{item.question}</div>
                            <div className={'smallText lightgrayText ' + (selectedFAQ === index ? '' : 'hiddenFaq')}>
                                <br />
                                {item.answer.map((answerItem) => {
                                    return (
                                        <div>{answerItem}<br /><br /></div>
                                    )
                                })}

                                {item.Url}

                                {item.table}

                                {item.note}

                            </div>
                        </div>
                        <div style={{ alignSelf: 'flex-start', width: '20%', textAlign: 'right' }}>
                            {selectedFAQ == index
                                ?
                                <MdKeyboardArrowUp size={25} />
                                :
                                <MdKeyboardArrowDown size={25} />
                            }
                        </div>
                    </div>
                )
            })
        )
    }

    const renderPlanCard = () => {
        if (planList.length <= 0) return (
            <div className='grayText collosalText boldText' style={{ marginTop: '20px' }}>
                No Plan Found
            </div>
        )
        return (
            planList.map((item, index) => {
                let itemDetails = planDetailsList[item.idproduct];

                if (itemDetails.productName.includes(selectedPlanType))
                    return (
                        <div style={{ position: 'relative', margin: screenWidth > 760 ? '50px 15px' : '30px 15px', backgroundColor: 'white', height: screenWidth > 600 ? '650px' : '550px', width: '350px', maxWidth: '90%', borderRadius: '15px' }}>
                            <div className='verticalText collosalText38 boldText' style={{ fontSize: '38px', opacity: 0.08, color: selectedPlanType == 'PLUS' ? '#2A285F' : '#b30000' }}>{itemDetails.sideProductName}</div>
                            <div className='row' style={{ padding: '10px', justifyContent: 'center', backgroundColor: selectedPlanType == 'PLUS' ? '#2A285F' : '#b30000', borderRadius: '10px 10px 0px 0px' }}>
                                <img style={{ width: '22px', height: '22px', backgroundColor: 'white', borderRadius: '20px', padding: '3px', marginRight: '10px' }} src={selectedPlanType == 'PLUS' ? `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/wifi plus_star.svg` : `${process.env.PUBLIC_URL}/assets/images/images_icons/icon/wifi premium_purple diamond.svg`}></img>
                                <div className='whiteText largeText boldText'>{itemDetails.productName}</div>
                            </div>
                            <div className='collosalText boldText' style={{ marginTop: '40px' }}>{itemDetails.SIM}</div>
                            <img style={{ width: '50px', height: '50px', margin: '20px 0' }} src={itemDetails.image}></img>
                            <div className='collosalText32 boldText blueText'><span className='collosalText boldText'>RM</span>{(typeof item.amount == 'string' && item.amount.slice(-3) == ".00") ? item.amount.slice(0, -3) : item.amount}<span className='mediumText boldText'> / mth</span></div>
                            <div className='smallText' style={{ margin: '10px 10px 30px 10px' }}>{itemDetails.additionalPriceInfo ? itemDetails.additionalPriceInfo : <br />}</div>
                            <button onClick={() => { window.location.href = '/xoxwifi/plan/' + btoa(item.idproduct); }} className={('whiteText clickable largeText semiBoldText mobileHeight40 ') + (selectedPlanType == 'PLUS' ? 'lightBlueHoverBG' : 'lightRedHoverBG')} style={{ borderRadius: '30px', border: '0px', backgroundColor: selectedPlanType == 'PLUS' ? '#2A285F' : '#b30000', maxWidth: '60%', width: '220px', height: '50px', marginBottom: '30px' }}>Subscribe</button>
                            {
                                itemDetails.infoList.map((info) => {
                                    return (
                                        <div className='row' style={{ ...styles.planSubInfoRow }}>
                                            <img style={{ ...styles.subInfoIcon }} src={info.infoIcon}></img>
                                            <div className={('smallText ') + (info.bold ? 'extraBoldText' : '')}>{info.infoText}</div>
                                        </div>
                                    )
                                })
                            }


                        </div>
                    )
            }
            )
        )
    }

    return (
        <div className='montserrat innerContent' style={{ paddingBottom: '0px' }}>
            <div style={{ backgroundColor: '#FFFFFF', width: '100%', justifyContent: 'center', textAlign: 'center' }}>
                <BannerSlider images={bannerImageList} />
                {/* {screenWidth > 850 ?
                    <div style={{ ...styles.connectDiv, width: '100%', height: 'calc(100vw * 0.289)' }}>
                        <div style={{ textAlign: 'left', width: '45%', padding: '5% 5% 5% 5%' }}>
                            <div className='boldText' style={{fontSize:'calc(100vw *  0.025)'}}>Connecting You to the World<br /><span className='redText'>XOX MOWIFI</span></div>
                            <div className='mediumText' style={{ marginTop: '20px' }}>Unleash seamless connectivity anywhere, anytime! Introducing XOX MOWIFI – Stay connected on the go. No limits, No boundaries!</div>
                            <button type='button' onClick={() => scrollToElement()} className='clickable blueHoverBG whiteText blueBG semiBoldText largeText mobileHeight40' style={{ margin: 'auto', marginTop: screenWidth > 1200 ? '50px' : '25px', height: screenWidth > 1200 ? '60px' : '45px', ...styles.btnShape }}>Explore Plans</button>
                        </div>
                    </div>
                    :
                    <div className='lightpurpleBG' style={{position:'relative'}}>
                        <img style={{ height: 'auto', width: '100%' }} src={`${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Connecting XOX MOWIFI_Mobile.png`}></img>
                        <div style={{ position:'absolute', top: 0,textAlign: 'center', padding: '5% 5%' }}>
                            <div className='hugeText boldText' style={{ fontSize:'5.5vw',textAlign: 'center' }}>Connecting You to the World<br /><span className='redText'>XOX MOWIFI</span></div>
                            <div className='smallText12' style={{ fontSize:'3.3vw',marginTop: '20px', textAlign: 'center' }}>Unleash seamless connectivity anywhere, anytime! Introducing XOX MOWIFI – Stay connected on the go. No limits, No boundaries!</div>
                            <button type='button' onClick={() => scrollToElement()} className='clickable whiteText blueBG semiBoldText smallText' style={{ fontSize:'3.85vw',margin: 'auto', marginTop: '5%', height: '10vw', textAlign: 'center', ...styles.btnShape, width:'51.28%', borderRadius:'50px' }}>Explore Plans</button>
                        </div>
                    </div>
                } */}

                <div style={{
                    padding:
                        screenWidth > 1000 ? '80px 0px' : '0px', ...styles.mainBgDiv
                }}>
                    {
                        screenWidth > 1000

                            ?
                            <div className='row' style={{ padding: '50px 5%', alignItems: 'normal', justifyContent: 'space-evenly' }}>
                                <div style={{ ...styles.flavorInfo }}>
                                    <img src="/assets/images/images_icons/icon/ICON%201.svg" width={'23%'} height={'auto'} />
                                    <div className='collosalText poppins boldText' style={{ margin: '15px 0' }}>LIGHTNING-FAST<br />CONNECTIVITY</div>
                                    <div className='lightgrayText mediumText'>Uncapped speed <br />4G/5G data</div>
                                </div>
                                <div style={{ ...styles.flavorInfo }}>
                                    <img src="/assets/images/images_icons/icon/ICON%202.svg" width={'35%'} height={'auto'} />
                                    <div className='collosalText poppins boldText' style={{ margin: '15px 0' }}>EFFORTLESS<br />ACTIVATION</div>
                                    <div className='lightgrayText mediumText'>Portable and <br />Easy-to-Use</div>
                                </div>
                                <div style={{ ...styles.flavorInfo }}>
                                    <img src="/assets/images/images_icons/icon/ICON%203.svg" width={'45%'} height={'auto'} />
                                    <div className='collosalText poppins boldText' style={{ margin: '15px 0' }}>RELIABLE<br />INTERNET</div>
                                    <div className='lightgrayText mediumText'>Shareable Connection <br />with Multiple Devices</div>
                                </div>
                            </div>

                            :
                            <div className='row' style={{ padding: '10px 2%', alignItems: 'normal', justifyContent: 'space-evenly' }}>
                                <div style={{ ...styles.flavorInfoMobile }}>
                                    <img src="/assets/images/images_icons/icon/ICON%201.svg" width={'30vw'} height={'30vw'} />
                                    <div className=' poppins boldText' style={{ margin: '15px 0 5px', fontSize: '14px', lineHeight: '15px' }}>LIGHTNING-FAST<br />CONNECTIVITY</div>
                                    <div className='lightgrayText' style={{
                                        fontFamily: 'Montserrat, sansSerif',
                                        fontSize: '10px',
                                        fontWeight: '500'
                                    }}>Uncapped speed <br />4G/5G data</div>
                                </div>
                                <div style={{ ...styles.flavorInfoMobile }}>
                                    <img src="/assets/images/images_icons/icon/ICON%202.svg" width={'30vw'} height={'30vw'} />
                                    <div className=' poppins boldText' style={{ margin: '15px 0 5px', fontSize: '14px', lineHeight: '15px' }}>EFFORTLESS<br />ACTIVATION</div>
                                    <div className='lightgrayText' style={{
                                        fontFamily: 'Montserrat, sansSerif',
                                        fontSize: '10px',
                                        fontWeight: '500'
                                    }}>Portable and <br />Easy-to-Use</div>
                                </div>
                                <div style={{ ...styles.flavorInfoMobile }}>
                                    <img src="/assets/images/images_icons/icon/ICON%203.svg" width={'30vw'} height={'30vw'} />
                                    <div className=' poppins boldText' style={{ margin: '15px 0 5px', fontSize: '14px', lineHeight: '15px' }}>RELIABLE<br />INTERNET</div>
                                    <div className='lightgrayText' style={{
                                        fontFamily: 'Montserrat, sansSerif',
                                        fontSize: '10px',
                                        fontWeight: '500'
                                    }}>Shareable Connection <br />with Multiple Devices</div>
                                </div>
                            </div>
                    }

                    <div style={screenWidth>800 ? styles.choosePlanDiv : styles.choosePlanMobileDiv} ref={scrollToRef}>
                        <div className='largeText redText boldText'>PRICING</div>
                        <br />
                        <div className='collosalText40 boldText'>Choose Your <span className='redText'>Plan</span></div>
                        {screenWidth>800 ? <br /> : ''}
                        <div className='smallText grayText' style={{ width: '90%', margin: 'auto' }}>Explore our range of plans designed to meet your unique needs.{screenWidth > 435 ? <br /> : " " }Stay connected without breaking the bank.</div>
                        <br /><br />
                        <div className='row' style={{ borderRadius: '24px', justifyContent: 'center', backgroundColor: 'white', width: 'fit-content', margin: 'auto' }}>
                            <button className={selectedPlanType == "PLUS" ? 'largeText' : 'largeText blueHoverText'} style={selectedPlanType == "PLUS" ? (screenWidth >500 ? styles.activeBtn : styles.activeBtnPhone) : (screenWidth >500 ? styles.inactiveBtn : styles.inactiveBtnPhone)} onClick={() => { setSelectedPlanType("PLUS") }}>WIFI PLUS</button>
                            <button className={selectedPlanType == "PREMIUM" ? 'largeText' : 'largeText blueHoverText'} style={selectedPlanType == "PREMIUM" ? (screenWidth >500 ? styles.activeBtn : styles.activeBtnPhone) : (screenWidth >500 ? styles.inactiveBtn : styles.inactiveBtnPhone)} onClick={() => { setSelectedPlanType("PREMIUM") }}>WIFI PREMIUM</button>
                        </div>
                        <div className='row' style={{ justifyContent: 'center', marginBottom: screenWidth > 800 ? '120px' : '50px' }}>
                            {renderPlanCard()}
                        </div>
                        {
                            screenWidth > 1000 ?
                                <div className='row' style={{ justifyContent: 'left', ...styles.activateDiv }}>
                                    <div style={{ textAlign: 'left', paddingTop: '100px', paddingBottom: '50px', paddingLeft: '45%', paddingRight: '5px', position: 'relative' }}>
                                        <div className={screenWidth > 1240 ? 'collosalText40 boldText' : 'collosalText32 boldText'} style={{ marginTop: '10px', width: '100%' }}>
                                            <span className='whiteText'>Activate your SIM<br />is as simple as ABC </span>
                                        </div>
                                        <div className={screenWidth > 1240 ? 'lightgrayText smallText' : 'lightgrayText smallText12'} style={{ marginBottom: '50px', marginTop: '10px', color: 'white', width: '100%' }}>
                                            Follow our simple step-by-step guide to unleash the power of XOX WiFi.
                                        </div>
                                        <div className='row' style={{ width: '100%' }}>
                                            <div className={screenWidth > 1240 ? 'collosalText60 boldText' : 'collosalText44 boldText'} style={{ opacity: 0.6, color: 'white', marginRight: '10px' }}>01</div>
                                            <div className={screenWidth > 1240 ? 'smallText' : 'smallText12'} style={{ color: 'white', ...styles.stepText, marginRight: screenWidth > 1240 ? '20px' : '10px' }}>Insert<br />SIM Card</div>
                                            <IoTriangleSharp size={screenWidth > 1240 ? 20 : 15} color='white' style={{ margin: '0 5px', transform: 'rotate(90deg)', marginRight: '1vw' }} />
                                            <div className={screenWidth > 1240 ? 'collosalText60 boldText' : 'collosalText44 boldText'} style={{ opacity: 0.6, color: 'white', marginRight: '10px' }}>02</div>
                                            <div className={screenWidth > 1240 ? 'smallText' : 'smallText12'} style={{ color: 'white', ...styles.stepText, marginRight: screenWidth > 1240 ? '20px' : '10px' }}>Use<br />Mobile Data</div>
                                            <IoTriangleSharp size={screenWidth > 1240 ? 20 : 15} color='white' style={{ margin: '0 5px', transform: 'rotate(90deg)', marginRight: '1vw' }} />
                                            <div className={screenWidth > 1240 ? 'collosalText60 boldText' : 'collosalText44 boldText'} style={{ opacity: 0.6, color: 'white', marginRight: '10px' }}>03</div>
                                            <div className={screenWidth > 1240 ? 'smallText' : 'smallText12'} style={{ color: 'white', ...styles.stepText }}>Activation<br />Completed</div>
                                        </div>
                                    </div>
                                </div> :
                                <div className='row column' style={{background: 'linear-gradient(to right, #3B3A83, #CF3759)', borderRadius: '50px', width: '95%', margin: 'auto', paddingTop: '30px', paddingBottom: '30px', justifyContent: 'center', }}>
                                    <div style={{ textAlign: 'center', width: '100%' }}>
                                        <div className='collosalText40 boldText' style={{ textAlign: 'center' }}><span className='whiteText'>Activate your SIM</span></div>
                                        <div className='collosalText40 boldText'><span className='whiteText'>is as simple as ABC</span></div>
                                        <div className='whiteText smallText' style={{ margin: '20px 7% 40px 7%' }}>Follow our simple step-by-step guide to unleash the power of XOX WiFi.</div>
                                    </div>
                                    <div style={{ width: '90%' }}>
                                        <div className='row' style={{ width: '100%' }}>
                                            <div className='collosalText48 boldText' style={{ color: 'white',fontSize: '50px', width: '35%', opacity: 0.6, marginLeft: '15%' }}>01&nbsp;&nbsp;</div>
                                            <pre className='smallText' style={{ color: 'white',fontSize: '14px', width: '50%', textAlign: 'left' }} size={30}>Insert<br />SIM Card</pre>
                                        </div>
                                        <div className='row' style={{ width: '100%' }}>
                                            <div style={{ width: '65%' }}>
                                                <IoTriangleSharp size={15} color='white' style={{ transform: 'rotate(180deg)' }}/>&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div>
                                            <div style={{ width: '35%' }}></div>
                                        </div>
                                        <div className='row' style={{ width: '100%' }}>
                                            <div className='collosalText48 boldText' style={{ color: 'white',fontSize: '50px', width: '35%', opacity: 0.6, marginLeft: '15%' }}>02&nbsp;&nbsp;</div>
                                            <pre className='smallText' style={{ color: 'white',fontSize: '14px', width: '50%', textAlign: 'left' }} size={30}>Use<br />Mobile Data</pre>
                                        </div>
                                        <div className='row' style={{ width: '100%' }}>
                                            <div style={{ width: '65%' }}>
                                                <IoTriangleSharp size={15} color='white' style={{transform: 'rotate(180deg)'}} />&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div>
                                            <div style={{ width: '35%' }}></div>
                                        </div>
                                        <div className='row' style={{ width: '100%' }}>
                                            <div className='collosalText48 boldText' style={{ color: 'white',fontSize: '50px', width: '35%', opacity: 0.6, marginLeft: '15%' }}>03&nbsp;&nbsp;</div>
                                            <pre className='smallText' style={{ color: 'white',fontSize: '14px', width: '50%', textAlign: 'left' }} size={30}>Activation<br />Completed</pre>
                                        </div>
                                    </div>
                                    <div>
                                        <img style={{ width: '65%', height: 'auto', marginTop: '20px' }} src="/assets/images/images_icons/Images/activateSimMobile2.png" />
                                    </div>
                                </div>
                        }
                        <div className='verticalMaxPadding70' style={{...styles.checkYourBalDiv, marginTop: screenWidth > 800 ? '130px' : '20px'}}>
                            <div className='collosalText38 boldText' style={{ width: '90%', margin: 'auto' }}>Check Your<br />Data Balance in Seconds</div>
                            <br />
                            <div className='smallText' style={{ width: '90%', margin: 'auto auto 5vw auto' }}>Enter your mobile number to check balance</div>
                            <div className='row mobileWidth70' style={{ width: '60%', borderRadius: '50px', padding: '20px 30px', margin: 'auto', border: mobileErrorMessage ? '2px solid red' : '2px solid transparent', backgroundColor: 'white' }}>
                                <div className='largeText' style={{ marginRight: '5px' }}>+60</div>
                                <input
                                    type='text'
                                    maxLength={10}
                                    onChange={(event) => { handleMobileNumberChange(event); }}
                                    value={mobileNumber}
                                    className='largeText mobileSmallPaddingLeft mobileNoMargin'
                                    style={{ flex: '1', maxWidth: screenWidth > 400 ? '80%' : '60%', outline: 'none', border: '1px solid lightgray', margin: '0 15px', padding: '0 0 0 15px', borderWidth: '0 0 0 1px', }}
                                    placeholder='Mobile Number' />

                                {screenWidth > 800 ?
                                    <button
                                        onClick={() => {
                                            if (checkMobileNumber(mobileNumber))
                                                navigate('/xoxwifi/checkbalance', { state: { mobileNumber: mobileNumber } });
                                        }}
                                        className='blueHoverBG clickable hideInMobile noBorder whiteText largeText row blueBG'
                                        style={{ justifyContent: 'center', width: '26%', borderRadius: '30px', padding: '12px 0' }}>
                                        <IoSearchOutline size={30} />&nbsp;Search
                                    </button>
                                    :
                                    <IoSearchOutline
                                        onClick={() => {
                                            if (checkMobileNumber(mobileNumber))
                                                navigate('/xoxwifi/checkbalance', { state: { mobileNumber: mobileNumber } });
                                        }}
                                        size={screenWidth > 400 ? 30 : (screenWidth / 13.3)} />
                                }
                            </div>

                            {/* <div className='blackText smallText' style={{ height: '29px', marginTop: '0px' }}>{mobileErrorMessage}</div> */}
                        </div>

                    </div>
                </div>

                <div className='row' style={screenWidth > 1000 ? { ...styles.faqSection } : { ...styles.faqSection_mobile }}>
                    <div style={{ textAlign: 'left', marginBottom: '20px', width: screenWidth > 1000 ? '38.89%' : '90%' }}>
                        <div className='redText boldText poppins' style={{ marginBottom: '19px', paddingTop: '20px' }}>FAQ's</div>
                        <div className='collosalText40 boldText'>Frequently Asked<br /><span className='blueText'>Questions</span></div>
                        <img style={{ width: '524px', height: 'auto', maxWidth: '90%' }} src={`${process.env.PUBLIC_URL}/assets/images/images_icons/Images/FAQs.png`} />
                    </div>
                    <div style={{ textAlign: 'left', width: screenWidth > 1000 ? '43.29%' : '90%' }}>
                        {renderFAQ()}
                        <div onClick={() => { window.location = '/xoxwifi/FAQ' }} className='row clickable mediumText blueText blueHoverText' style={{ padding: '30px' }}>More FAQs <MdKeyboardArrowRight className='icon-margin' /></div>
                    </div>
                </div>

            </div>
        </div>
    )
}

const choosePlanBgImage = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Choose%20your%20plan.png`;
const connectYouBgImage = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Connecting%20XOX%20MOWIFI.png`;
const checkBalImage = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Check%20balance.png`;
const mainBgImage = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/Main%20BG1.png`;
const activateBgImage = `${process.env.PUBLIC_URL}/assets/images/images_icons/Images/activateSim.png`;

const styles = {
    planSubInfoRow: {
        padding: '10px 0 10px 10%'
    },
    subInfoIcon: {
        width: '15px',
        height: '15px',
        marginRight: '10px'
    },
    activeBtn: {
        borderRadius: '24px', 
        width: '186px',
        height: '48px',
        backgroundColor: '#372EA8',
        color: 'white',
        borderWidth: '0px',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        boxShadow: '0px 6px 6px #372EA833',
    },
    activeBtnPhone: {
        borderRadius: '10vw',
        width: '40vw',
        height: '10vw',
        backgroundColor: '#372EA8',
        color: 'white',
        borderWidth: '0px',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        boxShadow: '0px 6px 6px #372EA833',
    },
    inactiveBtn: {
        cursor: 'pointer',
        borderRadius: '24px', 
        width: '186px',
        height: '48px',
        backgroundColor: 'white',
        color: '#707070',
        borderWidth: '0px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
    },
    inactiveBtnPhone: {
        cursor: 'pointer',
        borderRadius: '10vw',
        width: '40vw',
        height: '10vw',
        backgroundColor: 'white',
        color: '#707070',
        borderWidth: '0px',
        fontFamily: 'Montserrat',
        fontWeight: '600',
    },
    choosePlanDiv: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        padding: '80px 0px'
    },
    choosePlanMobileDiv: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        padding: '30px 0px'
    },
    mainBgDiv: {
        backgroundImage: `url(${mainBgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
    },
    activateDiv: {
        backgroundImage: `url(${activateBgImage})`,
        backgroundSize: 'contain', // or 'contain', depending on your preference
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', // to prevent repeating the background image
        width: '95%',
        padding: '4% 10px 4% 10px',
        margin: 'auto',
    },
    checkYourBalDiv: {
        // backgroundImage: `url(${checkBalImage})`,
        // background: 'transparent linear-gradient(299deg, rgba(85, 77, 216,0.7) 0%, rgba(8, 0 ,185 ,0.7) 100%) 0% 0% no-repeat padding-box',
        width: '90%',
        maxWidth: '1220px',
        margin: 'auto',
        padding: '10% 0',
        borderRadius: '50px',
    },
    connectDiv: {
        backgroundImage: `url(${connectYouBgImage})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    btnShape: {
        alignSelf: 'center',
        borderRadius: '30px',
        width: '250px',
        maxWidth: '75%',
        border: 0,
    },
    flavorInfo: {
        width: '267px',
        marginBottom: '20px',
        marginTop: '20px'
    },
    flavorInfoMobile: {
        marginBottom: '20px',
        marginTop: '20px'
    },
    stepText: {
        textAlign: 'left',
        marginLeft: '10px'
    },
    faqSection: {
        width: '90%',
        margin: 'auto auto 5vw auto',
        alignItems: 'flex-start',
        marginTop: '120px',
        justifyContent: 'center'
    },
    faqSection_mobile: {
        width: '90%',
        margin: 'auto auto 5vw auto',
        flexDirection: 'column',
        marginTop: '50px',
        justifyContent: 'center'
    }
};
export default SuperHOC(Home)
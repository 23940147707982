import React from 'react';
import { ApiManager, ErrMsg } from '../ApiManager';
const WithAuthenticate = OriginalComponent => {
  return class WithAuthenticate extends React.Component {
    constructor(props) {
      super(props);
      this.apiManager = new ApiManager(this);
      this.state = {
        user: null,
        loaded: false,
        retrying: false,
        showModal: true
      }
    }
    
    render() {
      return (
        <OriginalComponent
          {...this.props}
          apiManager={this.apiManager}

        />


      )
    }
  }
}

export default WithAuthenticate;
